@font-face {
    font-family: 'Reservation Wide';
    src: url('ReservationWide-Oblique.eot');
    src: local('ReservationWide-Oblique'),
        url('ReservationWide-Oblique.eot?#iefix') format('embedded-opentype'),
        url('ReservationWide-Oblique.woff2') format('woff2'),
        url('ReservationWide-Oblique.woff') format('woff'),
        url('ReservationWide-Oblique.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Reservation Wide';
    src: url('ReservationWide-Regular.eot');
    src: local('ReservationWide-Regular'),
        url('ReservationWide-Regular.eot?#iefix') format('embedded-opentype'),
        url('ReservationWide-Regular.woff2') format('woff2'),
        url('ReservationWide-Regular.woff') format('woff'),
        url('ReservationWide-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Reservation Wide';
    src: url('ReservationWide-Bold.eot');
    src: local('ReservationWide-Bold'),
        url('ReservationWide-Bold.eot?#iefix') format('embedded-opentype'),
        url('ReservationWide-Bold.woff2') format('woff2'),
        url('ReservationWide-Bold.woff') format('woff'),
        url('ReservationWide-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Reservation Wide';
    src: url('ReservationWide-Black.eot');
    src: local('ReservationWide-Black'),
        url('ReservationWide-Black.eot?#iefix') format('embedded-opentype'),
        url('ReservationWide-Black.woff2') format('woff2'),
        url('ReservationWide-Black.woff') format('woff'),
        url('ReservationWide-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Reservation Wide';
    src: url('ReservationWide-BlackOblique.eot');
    src: local('ReservationWide-BlackOblique'),
        url('ReservationWide-BlackOblique.eot?#iefix') format('embedded-opentype'),
        url('ReservationWide-BlackOblique.woff2') format('woff2'),
        url('ReservationWide-BlackOblique.woff') format('woff'),
        url('ReservationWide-BlackOblique.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Reservation Wide Bd Obq';
    src: url('ReservationWide-BoldOblique.eot');
    src: local('ReservationWide-BoldOblique'),
        url('ReservationWide-BoldOblique.eot?#iefix') format('embedded-opentype'),
        url('ReservationWide-BoldOblique.woff2') format('woff2'),
        url('ReservationWide-BoldOblique.woff') format('woff'),
        url('ReservationWide-BoldOblique.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

