@import "standard";
@import "all.min.css";
.ai-search-main {
  .ai-search-wrapper {
    text-align: center;
    padding: 190px 0 30px;
    @media screen and (max-width: $screen-xs) {
      padding: 40px 0 30px;
    }
    &.no-padding {
      padding: 0;
    }
    .ai-search-card {
      max-width: 862px;
      margin: auto;
      &.no-margin{
        margin: 0;
      }
      h3.title {
        margin-bottom: 32px;
        font-size: 24px;
        line-height: 32px;
        font-weight: 600;
        color: #373843;
        strong {
          font-size: 24px;
          line-height: 32px;
          font-weight: 600;
          color: $primary01;
        }
      }
      .filter-form {
        display: grid;
        grid-template-columns: 701px 25px 100px;
        align-items: center;
        justify-content: center;
        column-gap: 16px;
        position: relative;
        @media screen and (max-width: 991px) and (min-width: 768px) {
          grid-template-columns: 510px 20px 100px;
          column-gap: 10px;
        }
        @media screen and (max-width: $screen-xs) {
          display: block;
        }
        .filter-item {
          &.filter-icon {
            @media screen and (max-width: $screen-xs) {
              position: absolute;
              bottom: 15px;
            }
          }
          &.search-btn {
            @media screen and (max-width: $screen-xs) {
              text-align: right;
              margin: 15px 0 0;
            }
          }
          .ai-search-form {
          }
          .filter-icon {
            img {
            }
          }
          .filter-btn {
            .btn-style {
              background: $primary01;
              height: 40px;
              border-radius: 9px;
              font-weight: 600;
              min-width: auto;
              color: $white;
              padding: 10px 15px 10px;
              span {
                font-size: 14px;
                font-weight: 600;
                color: $white;
              }
            }
          }
        }
      }
      ul.searched-tags {
        text-align: left;
        margin: 10px 0 0;
        position: relative;
        left: 2px;
        //z-index: -1;
        li {
          display: inline-block;
          &:not(:last-of-type) {
            margin: 0 10px 10px 0;
          }
          a {
            font-size: 12px;
            line-height: 18px;
            color: $primary01;
            display: flex;
            align-items: center;
            gap: 4px;
            background: $orange-50-mode-1;
            border: 2px solid #FFE2D6;
            border-radius: 6px;
            padding: 7px 12px 7px;
            cursor: default;
            .cross-icon {
              img {
                cursor: pointer;
                max-width: 12px;
              }
            }
          }
        }
      }
    }
    .filter-cloud {
      background: $white;
      border-radius: 8px;
      padding: 24px;
      box-shadow: 1px 0 4px 2px rgba(85, 85, 85, .20);
      width: 100%;
      max-width: 100%;
      position: absolute;
      left: 50%;
      top: 52px;
      transform: translateX(-50%);
      z-index: 3;
      .filter-cloud-wrapper {
        .form {
          width: 100%;
          max-width: 440px;
          margin: 0 0 12px;
          .form-group {
            position: relative;
            margin-bottom: 0;
            .form-control {
              height: 44px;
              border: none;
              background: #F7F7F8;
              border-radius: 8px;
              padding: 5px 10px 5px 40px;
              color: #8C8FA4;
              &::placeholder {
                color: #8C8FA4;
              }
            }
            .icon {
              position: absolute;
              left: 12px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
        .cloud-card {
          &:not(:last-of-type) {
            border-bottom: 1px solid #D7D8E0;
            padding-bottom: 15px;
            margin-bottom: 15px;
          }
          > p.title {
            font-size: 12px;
            line-height: 18px;
            font-weight: 600;
            color: $text-primary09;
            padding-left: 12px;
            margin-bottom: 8px;
            text-align: left;
          }
          .ant-checkbox-group {
            display: inline-block;
            width: 100%;
            text-align: left;
          }
          .ant-checkbox-wrapper {
            padding: 7px 12px;
            border-radius: 8px;
            display: inline-flex;
            margin: 0 5px 5px 0;
            &:hover {
              span {
                &.ant-checkbox {
                  .ant-checkbox-inner {
                    border-color: $primary01;
                  }
                }
              }
            }
            &.ant-checkbox-wrapper-checked {
              background-color: $orange-50-mode-1;
              &:hover {
                span {
                  &.ant-checkbox {
                    &:after {
                      border-color: $primary01;
                    }
                  }
                }
              }
              span {
                &.ant-checkbox {
                  .ant-checkbox-inner {
                    background-color: $primary01;
                    border-color: $primary01;
                  }
                }
                &:not(.ant-checkbox) {
                  color: $primary01;
                }
              }
            }
            span {
              &.ant-checkbox {
                &:after {
                  transition: unset;
                }
                input {
                  width: 14px;
                  height: 14px;
                }
                .ant-checkbox-inner {
                  background: #F7F7F8;
                }
              }
              &:not(.ant-checkbox) {
                color: #48495C;
              }
            }
          }
        }
        .btn-wrapper {
          display: flex;
          align-items: center;
          justify-content: right;
          gap: 24px;
          //padding: 0 45px 0 0;
          .btn-style {
            height: 40px;
            padding: 7px 12px 5px;
            min-width: auto;
          }
        }
      }
    }
  }
}
.dashboard-ai-search {
  margin-bottom: 32px;
  .activate-ai{
    text-align: right;
    a{
      font-weight: 700;
      color: $primary01!important;
      font-family: $neue;
    }
  }
  .ai-search-main {
    .ai-search-wrapper {
      display: grid;
      grid-template-columns: 556px auto;
      align-items: center;
      gap: 22px;
      .filter-form {
        grid-template-columns: 556px 100px;
      }
      .ai-search-form {
        max-width: 556px;
        .filter-cloud {
          z-index: 5;
        }
        .form {
          padding: 0 0 0 5px;
        }
      }
      .search-btn {
        color: $primary01 !important;
        font-size: 16px;
        line-height: 25px;
        font-weight: 700;
        display: flex;
        align-items: center;
        gap: 6px;
        border: 0 !important;
        background: none !important;
        box-shadow: none !important;
        > div {
          display: none !important;
        }
        span {
          color: $primary01 !important;
          font-size: 16px;
          line-height: 25px;
          font-weight: 700;
        }
        img {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}
.ai-search-form {
  max-width: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  background: #F7F7F8;
  border-radius: 9px;
  position: relative;
  @media screen and (max-width: $screen-xs) {
    display: grid;
    grid-template-columns: 130px auto;
  }
  &.search-result-form {
    max-width: 700px;
  }
  .files-wrapper {
    position: relative;
    max-width: 143px;
    width: 100%;
    @media screen and (max-width: $screen-xs) {
      grid-column: 1;
      grid-row: 1;
    }
    &:before {
      content: "";
      background: #D7D8E0;
      width: 1px;
      height: 20px;
      position: absolute;
      right: -3px;
      top: 50%;
      transform: translateY(-50%);
    }
    .file-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      padding: 0 12px;
      height: 44px;
      color: #48495C;
      p {
        color: #48495C;
        margin-bottom: 0;
      }
      ul.selected-files {
        li {
          display: inline-block;
          vertical-align: middle;
          &:not(:last-of-type) {
            margin: 0 5px 0 0;
          }
          a {
            img {
              max-width: 17px;
            }
            span.more {
              background: $orange-50-mode-1;
              border-radius: 50%;
              height: 18px;
              min-width: 18px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 10px;
              line-height: 13px;
              color: $primary01;
            }
          }
        }
      }
    }
    .ant-popover {
      padding: 0;
      min-width: 165px;
      left: 0 !important;
      .ant-popover-content {
        .ant-popover-arrow {
          display: none;
        }
        .ant-popover-inner {
          max-height: 400px;
          overflow: auto;
          box-shadow: 0 3px 18px 2px rgba(0, 0, 0, .25);
          border-radius: 9px;
          padding: 6px 0;
          &::-webkit-scrollbar {
            background-color: #fff;
            width: 12px;
          }
          &::-webkit-scrollbar-track {
            background-color: #fff;
          }
          &::-webkit-scrollbar-thumb {
            background-color: $primary01;
            border-radius: 16px;
            border: 3px solid #fff;
          }
          &::-webkit-scrollbar-button {
            display: none;
          }
          .ant-popover-inner-content {
            .files-popup {
              .files {
                position: relative;
                &:not(:last-of-type) {
                  margin-bottom: 20px;
                  //border-bottom: 1px solid #D7D8E0;
                  &:before {
                    content: "";
                    background-color: #D7D8E0;
                    width: calc(100% - 12px);
                    height: 1px;
                    position: absolute;
                    left: 50%;
                    bottom: -10px;
                    transform: translateX(-50%);
                  }
                }
                > p {
                  margin-bottom: 10px;
                  font-size: 12px;
                  line-height: 18px;
                  font-weight: 600;
                  color: $text-primary09;
                  padding-left: 12px;
                }
                .ant-checkbox-group {
                  display: grid;
                  grid-template-columns: auto;
                  row-gap: 2px;
                }
                .ant-checkbox-wrapper {
                  width: 100%;
                  padding: 7px 12px;
                  margin: 0;
                  border-radius: 8px;
                  &:hover {
                    span {
                      &.ant-checkbox {
                        .ant-checkbox-inner {
                          border-color: $primary01;
                        }
                      }
                    }
                  }
                  &.ant-checkbox-wrapper-checked {
                    background-color: $orange-50-mode-1;
                    &:hover {
                      span {
                        &.ant-checkbox {
                          &:after {
                            border-color: $primary01;
                          }
                        }
                      }
                    }
                    span {
                      &.ant-checkbox {
                        .ant-checkbox-inner {
                          background-color: $primary01;
                          border-color: $primary01;
                        }
                      }
                      &:not(.ant-checkbox) {
                        color: $primary01;
                      }
                    }
                  }
                  span {
                    &.ant-checkbox {
                      &:after {
                        transition: unset;
                      }
                      input {
                        width: 14px;
                        height: 14px;
                      }
                      .ant-checkbox-inner {
                        background: #F7F7F8;
                      }
                    }
                    &:not(.ant-checkbox) {
                      color: #48495C;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .form {
    width: 100%;
    @media screen and (max-width: $screen-xs) {
      grid-column: 1 / span 2;
      grid-row: 2;
    }
    .form-group {
      position: relative;
      margin-bottom: 0;
      .form-control {
        height: 44px;
        border: none;
        background: transparent;
        border-radius: 0;
        padding: 5px 10px 5px 36px;
        color: #8C8FA4;
        &::placeholder {
          color: #8C8FA4;
        }
      }
      .icon {
        position: absolute;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .calendar-wrapper {
    padding: 0 12px 0 0;
    @media screen and (max-width: $screen-xs) {
      grid-column: 2;
      grid-row: 1;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: right;
      gap: 7px;
      p {
        margin-bottom: 0;
        font-size: 12px;
        line-height: 18px;
        color: #48495C;
        padding: 1px 0 0;
        min-width: 140px;
      }
    }
  }
  .meta-search {
    background: $white;
    border-radius: 12px;
    padding: 12px;
    box-shadow: 0 3px 18px 2px rgba(31, 34, 39, .21);
    width: 100%;
    max-width: 557px;
    position: absolute;
    right: 0;
    top: 52px;
    z-index: 4;
    .meta-search-wrapper {
      > p.title {
        margin-bottom: 8px;
        text-align: left;
        color: #6E7189;
        font-size: 12px;
        line-height: 18px;
        a span {
          color: #6E7189 !important;
          float: right;
          font-size: 12px;
          line-height: 18px;
        }
      }
      ul.searched-queries {
        height: 250px;
        overflow: auto;
        margin: 0 0 12px;
        &::-webkit-scrollbar {
          background-color: #fff;
          width: 12px;
        }
        &::-webkit-scrollbar-track {
          background-color: #fff;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $primary01;
          border-radius: 16px;
          border: 3px solid #fff;
        }
        &::-webkit-scrollbar-button {
          display: none;
        }
        li {
          display: block;
          &:not(:last-of-type) {
            //margin-bottom: 11px;
          }
          &.selected {
            a {
              background: $orange-50-mode-1;
            }
          }
          a {
            color: #373843;
            padding: 8px 10px 8px 40px;
            border-radius: 4px;
            text-align: left;
            position: relative;
            display: block;
            img {
              width: 20px;
              position: absolute;
              left: 10px;
            }
          }
        }
      }
      .guide-options-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        background: #F7F7F8;
        padding: 11.5px 6px;
        border-radius: 0 0 10px 10px;
        &.space-between{
          justify-content: space-between;
        }
        .guide-options{
          padding: 0;
        }
        .universal-search{
          text-align: right;
          a.search-link{
            font-size: 12px;
            line-height: 18px;
            font-weight: 700;
            display: flex;
            align-items: center;
            gap: 4px;
            color: $primary01;
          }
        }
      }
      ul.guide-options {
        text-align: center;
        background: #F7F7F8;
        padding: 8px 20px;
        border-radius: 0 0 10px 10px;
        li {
          vertical-align: middle;
          display: inline-block;
          &:not(:last-of-type) {
            margin: 0 4px 0 0;
          }
          a {
            img {
              max-width: 40px;
            }
            span {
              display: inline-block;
              font-size: 10px;
              line-height: 16px;
              color: #8C8FA4;
              margin: 0 4px;
            }
          }
        }
      }
    }
  }
  .select-date {
    background: $white;
    border-radius: 8px;
    padding: 24px 0;
    box-shadow: 0 3px 18px 2px rgba(31, 34, 39, .21);
    width: 100%;
    max-width: 390px;
    position: absolute;
    right: 0;
    top: 52px;
    z-index: 4;
    @media screen and (max-width: $screen-xs) {
      overflow: auto;
    }
    .select-date-wrapper {
      .rdrCalendarWrapper {
        .rdrDateDisplayWrapper {
          background: transparent;
          .rdrDateDisplay {
            gap: 29px;
            .rdrDateInput {
              background: #F7F7F8;
              border-radius: 8px;
              padding: 10px 12px;
              border: none;
              box-shadow: none;
              input {
                font-size: 14px;
                line-height: 18px;
                height: auto;
                color: #373843;
                &::placeholder {
                  color: #373843;
                }
              }
            }
          }
        }
        .rdrMonthAndYearWrapper {
          justify-content: center;
          margin-bottom: 20px;
          .rdrMonthAndYearPickers {
            flex: unset;
            span {
              select {
                color: #373843;
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;
                padding: 0;
                background: none !important;
              }
              &.rdrMonthPicker {
                margin: 0;
              }
              &.rdrYearPicker {
                margin: 0;
                select {
                  width: 50px;
                }
              }
            }
          }
          button {
            width: 25px;
            height: 25px;
            background: transparent;
            border: 1px solid #585971;
            border-radius: 4px;
            position: relative;
            i {
              background: url("../images/ai-search/calendar-arrow-icon.svg") no-repeat;
              background-size: cover;
              background-position: center;
              width: 5px;
              height: 7px;
              border: none;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translateX(-50%) translateY(-50%);
            }
            &.rdrPprevButton {
            }
            &.rdrNextButton {
              i {
                transform: translateX(-50%) translateY(-50%) rotate(180deg);
                left: calc(50% - 7px);
              }
            }
          }
        }
        .rdrMonths {
          .rdrMonth {
            .rdrWeekDays {
              span {
                color: #B5B6C4;
                font-size: 12px;
                line-height: 18px;
              }
            }
            .rdrDays {
              .rdrDayToday .rdrDayNumber span:after {
                display: none;
              }
              .rdrDay {
                font-size: 14px;
                line-height: 22px;
                .rdrDayStartPreview {
                  border-radius: 13px 0 0 13px;
                }
                .rdrDayEndPreview {
                  border-radius: 0 13px 13px 0;
                }
                .rdrDayStartPreview.rdrDayEndPreview {
                  border-radius: 13px;
                  z-index: 99;
                }
                &.rdrDayHovered {
                  span {
                    border-color: $primary01;
                    &.rdrDayNumber {
                      span {
                        //color: #373843;
                      }
                    }
                  }
                }
                &.rdrDayEndOfWeek {
                  .rdrInRange, .rdrDayInPreview {
                    border-radius: 0 13px 13px 0;
                  }
                  .rdrDayStartPreview {
                    border-radius: 13px;
                  }
                  .rdrStartEdge {
                    &:after, &:before {
                      display: none;
                    }
                  }
                }
                &.rdrDayStartOfWeek {
                  .rdrInRange, .rdrDayInPreview {
                    border-radius: 13px 0 0 13px;
                  }
                  .rdrDayEndPreview {
                    border-radius: 13px;
                  }
                  .rdrEndEdge {
                    &:after, &:before {
                      display: none;
                    }
                  }
                }
                span {
                  border-color: $primary01;
                  &.rdrInRange + .rdrDayNumber span {
                    color: #373843;
                  }
                  &.rdrInRange {
                    background: $orange-50-mode-1;
                  }
                  &.rdrStartEdge, &.rdrEndEdge {
                    background: $primary01;
                    border-radius: 13px;
                  }
                  &.rdrEndEdge:not(.rdrStartEdge) {
                    &:before {
                      content: "";
                      background: $orange-50-mode-1;
                      height: 100%;
                      width: 50%;
                      position: absolute;
                      left: -12px;
                      z-index: 1;
                    }
                    &:after {
                      content: "";
                      height: 100%;
                      width: 100%;
                      position: absolute;
                      left: 0;
                      z-index: 2;
                      background: $primary01;
                      border-radius: 13px;
                    }
                  }
                  &.rdrStartEdge:not(.rdrEndEdge) {
                    &:before {
                      content: "";
                      background: $orange-50-mode-1;
                      height: 100%;
                      width: 50%;
                      position: absolute;
                      right: -12px;
                      z-index: 1;
                    }
                    &:after {
                      content: "";
                      height: 100%;
                      width: 100%;
                      position: absolute;
                      right: 0;
                      z-index: 2;
                      background: $primary01;
                      border-radius: 13px;
                    }
                  }
                  &.rdrEndEdge ~ .rdrDayNumber span {
                    color: $white !important;
                  }
                  &.rdrStartEdge ~ .rdrDayNumber span {
                    color: $white !important;
                  }
                  .rdrDay.rdrStartEdge .rdrDayNumber span,
                  .rdrDay.rdrEndEdge .rdrDayNumber span {
                    color: white !important;
                  }
                  &.rdrDayNumber {
                    z-index: 10;
                    span {
                      color: #373843 !important;
                      &:after {
                        background: $primary01;
                      }
                    }
                  }
                }
                &.rdrDayPassive {
                  .rdrDayNumber {
                    span {
                      color: #8C8FA4;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: right;
        gap: 12px;
        padding: 0 45px 0 0;
        .btn-style {
          height: 40px;
          padding: 7px 12px 5px;
          min-width: auto;
        }
      }
    }
  }
}
.search-result-main {
  .search-result-wrapper {
    .search-result-header {
      margin: 24px 0;
      h3.title {
        margin-bottom: 0;
        color: #373843;
      }
      ul.options {
        text-align: right;
        li {
          &:not(:last-of-type) {
            margin: 0 16px 0 0;
          }
          .select-card {
            text-align: left;
            > p {
              margin-bottom: 8px;
            }
            .select-wrapper {
              position: relative;
              .icon {
                position: absolute;
                left: 12px;
                top: 50%;
                transform: translateY(-50%);
                z-index: 2;
              }
              .ant-select {
                width: 208px;
                &.icon-select {
                  .ant-select-selector {
                    padding: 0 12px 0 40px;
                  }
                }
                &.ant-select-focused {
                  .ant-select-selector {
                    box-shadow: none !important;
                  }
                }
                .ant-select-selector {
                  background: #F7F7F8;
                  border: none;
                  box-shadow: none;
                  outline: none;
                  border-radius: 8px;
                  padding: 0 12px;
                  span {
                    &.ant-select-selection-item {
                      color: #373843;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .search-result-body {
      .result-body-card {
        &:not(:last-of-type) {
          margin-bottom: 32px;
        }
        h3.title {
          margin-bottom: 16px;
          color: $text-primary09;
          font-size: 20px;
          line-height: 28px;
        }
        .result-card-wrapper {
          .result-card-main {
            display: inline-block;
            width: 128px;
            margin: 0 18px 18px 0;
            vertical-align: top;
            &:not(:last-of-type) {
            }
            input[type=checkbox] {
              display: none;
            }
            input[type=checkbox]:checked + label.result-card {
              border-color: $primary01;
              &:before {
                display: block;
              }
              .img-wrapper {
                background: $orange-50-mode-1;
              }
              .content-wrapper {
                background: #FFE8D4;
              }
            }
            .result-card {
              background: #F7F7F8;
              border-radius: 11px;
              display: block;
              position: relative;
              border: 1px solid transparent;
              &.video-card {
                .img-wrapper {
                  &:before {
                    content: "";
                    background: url("../images/ai-search/play-white-icon.svg") no-repeat center;
                    background-size: cover;
                    width: 15px;
                    height: 18px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateY(-50%) translateX(-50%);
                  }
                }
              }
              &:before {
                content: "";
                background: url("../images/ai-search/checkbox-orange-box-icon.svg") no-repeat center;
                background-size: cover;
                width: 20px;
                height: 20px;
                position: absolute;
                top: 22px;
                left: 22px;
                z-index: 2;
                display: none;
              }
              .result-options {
                position: absolute;
                right: 8px;
                top: 9px;
                z-index: 2;
              }
              .img-wrapper {
                padding: 18px 18px;
                border-radius: 11px 11px 0 0;
                position: relative;
                img {
                  width: 100%;
                  height: 95px;
                  object-fit: cover;
                  border-radius: 12px;
                }
              }
              .content-wrapper {
                background: #EDEDF1;
                padding: 9px 13px 15px;
                border-radius: 0 0 11px 11px;
                p {
                  margin-bottom: 0;
                  color: #373843;
                  word-break: break-all;
                }
              }
            }
          }
        }
      }
      .result-table-main {
        &.sidebar-opened{
          .table-wrapper{
            .grid-4 {
              grid-template-columns: 400px 162px 150px auto;
            }
          }
        }
        &:not(:last-of-type) {
          margin-bottom: 32px;
        }
        > h3.title {
          font-size: 20px;
          line-height: 28px;
          font-weight: 500;
          color: $text-primary09;
          margin-bottom: 16px;
        }
        .table-wrapper {
          margin-bottom: 15px;
          overflow-x: auto;
          @include scrollbarSmall;
          .grid-3 {
            display: grid;
            align-items: center;
            column-gap: 10px;
            grid-template-columns: 200px 300px auto;
          }
          .grid-4 {
            display: grid;
            align-items: center;
            justify-content: space-between;
            column-gap: 10px;
            grid-template-columns: 640px 200px 150px auto;
          }
          .table-header {
            //background: #383838;
            background: #fff;
            padding: 10px 22px 10px;
            @media screen and (max-width: $screen-xs) {
              width: 600px;
            }
            p {
              margin-bottom: 0;
              font-size: 16px;
              line-height: 24px;
              font-weight: 500;
              display: inline-flex;
              align-items: center;
              gap: 8px;
              color: #8C8FA4;
              &:first-of-type {
                //padding-left: 21px;
              }
            }
          }
          .table-body {
            @media screen and (max-width: $screen-xs) {
              width: 600px;
            }
            p {
              margin-bottom: 0;
              font-size: 14px;
              line-height: 22px;
              color: #373843;
            }
            .table-row {
              padding: 8px 22px;
              border-bottom: 1px solid #F7F7F8;
              transition: all .3s ease-in-out;
              &:hover {
                background: #F6F6F6;
                //background: #474545;
              }
              .table-item {
                .name-wrapper {
                  display: flex;
                  align-items: center;
                  gap: 12px;
                  img {
                    width: 24px;
                    height: 24px;
                    border-radius: 4px;
                    object-fit: cover;
                  }
                  p {
                    margin-bottom: 0;
                  }
                }
                .copy-wrapper {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  img {
                    filter: brightness(0) saturate(100%) invert(0%) sepia(96%) saturate(0%) hue-rotate(103deg) brightness(100%) contrast(103%);
                  }
                  p {
                  }
                }
                ul.options {
                  li {
                    display: inline-block;
                    &:not(:last-of-type) {
                      margin: 0 8px 0 0;
                    }
                    a {
                      img {
                        max-width: 15px;
                        filter: brightness(0) saturate(100%) invert(0%) sepia(96%) saturate(0%) hue-rotate(103deg) brightness(100%) contrast(103%);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.file-name-sidebar {
  background: #F7F7F8;
  box-shadow: 0 0 4px 0 rgba(32, 32, 39, .19);
  backdrop-filter: blur(35px);
  position: fixed;
  right: -100%;
  top: 0;
  height: 100vh;
  z-index: 999;
  transition: all 0.3s ease-in-out;
  width: 373px;
  &.active {
    right: 0;
  }
  .file-name-wrapper {
    p {
      margin-bottom: 0;
      font-weight: 600;
      &.title {
        color: #48495C;
      }
      &.small {
        font-size: 12px;
        line-height: 18px;
        color: $text-primary09;
      }
      &.semi {
        font-weight: 600;
      }
      &.orange {
        color: $primary01;
      }
    }
    .title-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 24px 0;
      h3 {
        margin-bottom: 0;
        font-weight: 600;
        color: #373843;
      }
    }
    .file-name-body {
      height: calc(100vh - 82px);
      overflow: auto;
      padding: 24px;
      @include scrollbar;
    }
    ul.btn-list {
      text-align: center;
      margin-bottom: 20px;
      li {
        display: inline-block;
        &:not(:last-of-type) {
          margin: 0 7px 0 0;
        }
        .btn-style {
          height: 28px;
          border-radius: 4px;
          &.text {
            color: #48495C;
          }
          &:not(.text) {
            background: #202027;
            border-color: #202027;
            box-shadow: 0 0 0 2px rgba(255, 226, 214, 1);
            color: $white;
            font-weight: 500;
            padding: 5px 9px;
            span {
              color: $white;
            }
          }
        }
      }
    }
    .access-wrapper {
      margin-bottom: 16px;
      .access-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 13px;
        p {
          margin-bottom: 0;
        }
      }
      ul.users {
        margin: 0 0 10px;
        li {
          &:first-of-type {
            position: relative;
            &:before {
              content: "";
              background: #B5B6C4;
              height: 30px;
              width: 1px;
              position: absolute;
              right: -11px;
              top: 0;
            }
            &:not(:last-of-type) {
              margin: 0 22px 0 0;
            }
          }
          &:not(:last-of-type) {
            margin: 0 11px 0 0;
          }
          a {
            span.count {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 12px;
              line-height: 18px;
              color: #373843;
              background: #FFE8D4;
              width: 30px;
              height: 30px;
              border-radius: 50%;
            }
            img {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              object-fit: cover;
            }
          }
        }
      }
    }
    .information-wrapper {
      margin-bottom: 16px;
      p.title {
        margin-bottom: 10px;
      }
      ul.info {
        li {
          display: flex;
          align-items: center;
          gap: 3px;
          &:not(:last-of-type) {
            margin-bottom: 4px;
          }
          p {
            &:not(.semi) {
              font-weight: 400;
            }
          }
        }
      }
    }
    .tags-wrapper {
      margin-bottom: 16px;
      p.title {
        margin-bottom: 14px;
      }
      ul.tags {
        li {
          &:not(:last-of-type) {
            margin: 0 5px 5px 0;
          }
          a {
            font-size: 12px;
            line-height: 18px;
            color: #6E7189;
            border-radius: 5px;
            background: #E2E3E9;
            height: 28px;
            padding: 5px 8px;
            display: block;
          }
        }
      }
      .form {
        .form-group {
          .form-control {
            border: none;
            background: transparent;
            height: 40px;
            padding: 10px 12px;
            box-shadow: none;
            color: #8C8FA4;
            &::placeholder {
              color: #8C8FA4;
            }
          }
        }
      }
    }
  }
}
.ai-tooltip-v1 {
  .ant-tooltip {
    .ant-tooltip-content {
      .ant-tooltip-inner {
        background: $white;
        box-shadow: 0 3px 18px 2px rgba(31, 34, 39, .21);
        padding: 0;
        border: none;
        .popup-options {
          > p.title {
            padding: 9px 12px 2px;
            color: $text-primary09;
            font-size: 12px;
            line-height: 18px;
            font-weight: 600;
            margin-bottom: 0;
          }
          ul.view-list {
            li {
              display: block !important;
              &:not(:last-of-type) {
                border-bottom: 1px solid #D7D8E0;
              }
              &.disable {
                a {
                  color: #B5B6C4;
                }
              }
              a {
                display: grid;
                grid-template-columns: 16px auto;
                align-items: center;
                gap: 12px;
                width: 100%;
                padding: 10px 12px;
                color: #373843;
                img {
                  filter: brightness(0) saturate(100%) invert(61%) sepia(6%) saturate(842%) hue-rotate(195deg) brightness(93%) contrast(87%);
                  &.orange {
                    filter: brightness(0) saturate(100%) invert(53%) sepia(86%) saturate(6503%) hue-rotate(7deg) brightness(98%) contrast(98%);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.ant-drawer {
  .ant-drawer-mask {
  }
  .ant-drawer-content-wrapper {
    height: calc(100vh - 0px) !important;
    .ant-drawer-content {
      .ant-drawer-wrapper-body {
        .ant-drawer-header {
          display: none;
        }
        .ant-drawer-body {
          padding: 0;
          .ai-drawer-main {
            //display: grid;
            //grid-template-columns: 412px auto;
            //align-items: self-start;
            //column-gap: 32px;
            height: 100vh;
            .ai-left-sidebar {
              height: 100%;
              width: 100%;
              max-width: 412px;
              box-shadow: 0 0 4px 0 rgba(32, 32, 39, .2);
              position: absolute;
              left: -390px;
              top: 0;
              z-index: 3;
              background: $white;
              transition: all .3s ease-in-out;
              &.active {
                left: 0;
                .open-drawer {
                  display: none;
                }
              }
              .open-drawer {
                position: absolute;
                right: -13px;
                top: 50%;
                transform: translateY(-59%);
                z-index: 2;
                display: block;
              }
              .ai-sidebar-wrapper {
                overflow-y: auto;
                height: 100%;
                padding: 25px 24px;
                &::-webkit-scrollbar {
                  background-color: $white;
                  width: 12px;
                }
                &::-webkit-scrollbar-track {
                  background-color: $white;
                }
                &::-webkit-scrollbar-thumb {
                  background-color: $primary01;
                  border-radius: 16px;
                  border: 3px solid $white;
                }
                &::-webkit-scrollbar-button {
                  display: none;
                }
                p {
                  &.title {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 600;
                    color: #48495C;
                    margin-bottom: 14px;
                  }
                }
                .title-wrapper {
                  display: flex;
                  align-items: center;
                  gap: 4px;
                  margin-bottom: 24px;
                  h3 {
                    font-size: 18px;
                    line-height: 27px;
                    font-weight: 600;
                    margin-bottom: 0;
                    color: #373843;
                    font-family: $neue;
                  }
                  .back-icon {
                    img {
                      max-width: 26px;
                    }
                  }
                }
                .ai-search {
                  width: 100%;
                  margin-bottom: 48px;
                  .form-group {
                    position: relative;
                    margin-bottom: 0;
                    .form-control {
                      height: 40px;
                      border: none;
                      background: #F2F2F4;
                      border-radius: 8px;
                      padding: 5px 10px 5px 36px;
                      color: #8C8FA4;
                      &::placeholder {
                        color: #8C8FA4;
                      }
                    }
                    .icon {
                      position: absolute;
                      left: 8px;
                      top: 50%;
                      transform: translateY(-50%);
                    }
                  }
                }
                .ai-tags-wrapper {
                  margin-bottom: 43px;
                  p.title {
                  }
                  ul.ai-tags {
                    li {
                      &:not(:last-of-type) {
                        margin: 0 5px 5px 0;
                      }
                      a {
                        font-size: 12px;
                        line-height: 18px;
                        color: #6E7189;
                        display: inline-block;
                        padding: 5px 8px;
                        border-radius: 6px;
                        background: #E2E3E9;
                      }
                    }
                  }
                }
                .ai-copy-wrapper {
                  margin-bottom: 38px;
                  .copy-title {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 20px;
                    margin-bottom: 14px;
                    p.title {
                      margin-bottom: 0;
                    }
                    a.copy-icon {
                      img {
                        max-width: 18px;
                      }
                    }
                  }
                  .copy-body {
                    p {
                      color: #585971;
                      text-align: justify;
                      margin-bottom: 10px;
                    }
                  }
                }
              }
            }
            .ai-right-sidebar {
              height: 100%;
              width: 100%;
              max-width: 373px;
              box-shadow: 0 0 4px 0 rgba(32, 32, 39, .2);
              position: absolute;
              right: -100%;
              top: 0;
              z-index: 3;
              background: $white;
              transition: all .3s ease-in-out;
              &.active {
                right: 0;
                .open-drawer {
                  display: block;
                }
              }
              .ai-sidebar-wrapper {
                overflow-y: auto;
                height: 100%;
                padding: 24px 24px;
                &::-webkit-scrollbar {
                  background-color: $white;
                  width: 12px;
                }
                &::-webkit-scrollbar-track {
                  background-color: $white;
                }
                &::-webkit-scrollbar-thumb {
                  background-color: $primary01;
                  border-radius: 16px;
                  border: 3px solid $white;
                }
                &::-webkit-scrollbar-button {
                  display: none;
                }
                p {
                  &.title {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 600;
                    color: #48495C;
                    margin-bottom: 14px;
                  }
                }
                h3.title {
                  margin-bottom: 0;
                  font-size: 22px;
                  line-height: 31px;
                  font-weight: 600;
                  color: #373843;
                }
                .title-header {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  gap: 21px;
                }
                .no-clip {
                  text-align: center;
                  height: calc(100vh - 80px);
                  display: flex;
                  align-items: center;
                  .no-clip-wrapper {
                    h3 {
                      font-size: 18px;
                      line-height: 26px;
                      font-weight: 600;
                      color: #373843;
                      strong {
                        font-size: 18px;
                        line-height: 26px;
                        font-weight: 600;
                        color: $primary01;
                      }
                    }
                    p {
                      margin: 7px 0 24px;
                      color: #6E7189;
                    }
                    .btn-style {
                      background: $primary01;
                      color: $white;
                      height: 40px;
                    }
                  }
                }
                .cutted-clipped {
                  margin: 33px 0 0;
                  .create-clip {
                    text-align: right;
                    margin-bottom: 32px;
                    a {
                      display: inline-flex;
                      gap: 7px;
                      color: $primary01;
                      font-size: 12px;
                      line-height: 18px;
                      font-weight: 700;
                      img {
                        filter: brightness(0) saturate(100%) invert(44%) sepia(53%) saturate(7046%) hue-rotate(2deg) brightness(94%) contrast(98%);
                      }
                    }
                  }
                  .cutted-clipped-wrapper {
                    .clipped-card {
                      display: grid;
                      grid-template-columns: auto 55px;
                      align-items: self-start;
                      column-gap: 10px;
                      &:not(:last-of-type) {
                        margin-bottom: 48px;
                      }
                      .clip-detail {
                        display: grid;
                        grid-template-columns: 57px auto;
                        align-items: center;
                        column-gap: 15px;
                        .img-wrapper {
                          width: 57px;
                          height: 57px;
                          border-radius: 8px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          background: #E7F2FF;
                          img {
                          }
                        }
                        .content {
                          p {
                            color: $text-primary09;
                            font-weight: 700;
                            margin-bottom: 5px;
                          }
                          span.count {
                            font-size: 12px;
                            line-height: 18px;
                            color: $white;
                            display: inline-block;
                            padding: 5px 8px;
                            height: 28px;
                            border-radius: 5px;
                            background: #3E3E4E;
                          }
                        }
                      }
                      .clip-options {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                      }
                    }
                  }
                }
                .file-name-wrapper {
                  p {
                    margin-bottom: 0;
                    font-weight: 600;
                    &.title {
                      color: #48495C;
                    }
                    &.small {
                      font-size: 12px;
                      line-height: 18px;
                      color: $text-primary09;
                    }
                    &.semi {
                      font-weight: 600;
                    }
                    &.orange {
                      color: $primary01;
                    }
                  }
                  .title-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0;
                    margin-bottom: 24px;
                    h3 {
                      margin-bottom: 0;
                      font-weight: 600;
                      color: #373843;
                    }
                  }
                  .file-name-body {
                    //height: calc(100vh - 82px);
                    //overflow: auto;
                    //padding: 24px;
                    //@include scrollbar;
                  }
                  ul.btn-list {
                    text-align: center;
                    margin-bottom: 20px;
                    li {
                      display: inline-block;
                      &:not(:last-of-type) {
                        margin: 0 7px 0 0;
                      }
                      .btn-style {
                        height: 28px;
                        border-radius: 5px;
                        &.text {
                          color: #B5B6C4;
                          background: #EDEDF1;
                          img {
                            filter: brightness(0) saturate(100%) invert(85%) sepia(23%) saturate(115%) hue-rotate(198deg) brightness(83%) contrast(89%);
                          }
                        }
                        &:not(.text) {
                          background: #202027;
                          border-color: #202027;
                          box-shadow: 0 0 0 2px rgba(255, 226, 214, 1);
                          color: $white;
                          font-weight: 500;
                          padding: 5px 9px;
                          span {
                            color: $white;
                          }
                        }
                      }
                    }
                  }
                  .information-wrapper {
                    margin-bottom: 16px;
                    p.title {
                      margin-bottom: 10px;
                    }
                    ul.info {
                      li {
                        display: flex;
                        align-items: center;
                        gap: 3px;
                        &:not(:last-of-type) {
                          margin-bottom: 4px;
                        }
                        p {
                          color: #373843;
                          &:not(.semi) {
                            font-weight: 400;
                          }
                        }
                      }
                    }
                  }
                  .tags-wrapper {
                    margin-bottom: 16px;
                    p.title {
                      margin-bottom: 14px;
                    }
                    ul.tags {
                      li {
                        &:not(:last-of-type) {
                          margin: 0 5px 5px 0;
                        }
                        a {
                          font-size: 12px;
                          line-height: 18px;
                          color: #6E7189;
                          border-radius: 5px;
                          background: #E2E3E9;
                          height: 28px;
                          padding: 5px 8px;
                          display: block;
                        }
                      }
                    }
                    .form {
                      .form-group {
                        .form-control {
                          border: none;
                          background: transparent;
                          height: 40px;
                          padding: 10px 12px;
                          box-shadow: none;
                          color: #8C8FA4;
                          &::placeholder {
                            color: #8C8FA4;
                          }
                        }
                      }
                    }
                    .select-wrapper {
                      margin-bottom: 24px;
                      .ant-select {
                        width: 100%;
                        &.icon-select {
                          .ant-select-selector {
                            padding: 0 12px 0 40px;
                          }
                        }
                        &.ant-select-focused {
                          .ant-select-selector {
                            box-shadow: none !important;
                          }
                        }
                        .ant-select-selector {
                          background: #F7F7F8;
                          border: none;
                          box-shadow: none;
                          outline: none;
                          border-radius: 8px;
                          padding: 0 12px;
                          span {
                            &.ant-select-selection-item {
                              color: #373843;
                            }
                          }
                        }
                      }
                    }
                    .comment-wrapper {
                      .comment-card {
                        display: grid;
                        grid-template-columns: 50px auto;
                        align-items: center;
                        column-gap: 15px;
                        &:not(:last-of-type) {
                          margin-bottom: 36px;
                        }
                        .img-wrapper {
                          img {
                            width: 50px;
                            height: 50px;
                            border-radius: 50%;
                            object-fit: cover;
                          }
                        }
                        .content {
                          p {
                            color: $text-primary09;
                            font-weight: 400;
                            margin-bottom: 5px;
                            strong {
                              color: $text-primary09;
                              font-weight: 700;
                              font-size: 14px;
                              line-height: 23px;
                            }
                          }
                          ul.option {
                            li {
                              vertical-align: middle;
                              display: inline-block;
                              &:not(:last-of-type) {
                                margin-right: 5px;
                              }
                              span.count {
                                font-size: 12px;
                                line-height: 18px;
                                color: $white;
                                display: inline-block;
                                padding: 5px 8px;
                                height: 28px;
                                border-radius: 5px;
                                background: #3E3E4E;
                              }
                              p {
                                margin-bottom: 0;
                                color: $text-primary09;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .ai-main-content {
              height: 100%;
              overflow-y: auto;
              padding: 26px 32px 250px 32px;
              margin-left: 20px;
              transition: all .3s ease-in-out;
              &.padding-bottom-50 {
                padding-bottom: 50px;
              }
              &.left-active {
                margin-left: 412px;
              }
              &.right-active {
                margin-right: 373px;
              }
              &::-webkit-scrollbar {
                background-color: $white;
                width: 12px;
              }
              &::-webkit-scrollbar-track {
                background-color: $white;
              }
              &::-webkit-scrollbar-thumb {
                background-color: $primary01;
                border-radius: 16px;
                border: 3px solid $white;
              }
              &::-webkit-scrollbar-button {
                display: none;
              }
              p {
                &.title {
                  font-size: 16px;
                  line-height: 24px;
                  font-weight: 600;
                  color: #48495C;
                  margin-bottom: 14px;
                }
              }
              ul.tool-menu {
                text-align: right;
                margin-bottom: 26px;
                li {
                  &:not(:last-of-type) {
                    margin: 0 16px 0 0;
                  }
                  a {
                    color: #373843;
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    font-weight: 600;
                    padding: 9px 9px 8px;
                    border-radius: 6px;
                    background: transparent;
                    transition: unset;
                    &.active {
                      background: $primary01;
                      color: $white;
                      img {
                        filter: brightness(0) saturate(100%) invert(96%) sepia(100%) saturate(1%) hue-rotate(203deg) brightness(105%) contrast(101%);
                      }
                    }
                    img {
                      max-width: 18px;
                    }
                  }
                }
              }
              .photo-preview-page{
                position: relative;
                border-radius: 11px;
                margin-bottom: 32px;
                overflow: hidden;
                height: 600px;
                width: 100%;
              }
              .thumbnail-preview {
                position: relative;
                border-radius: 11px;
                overflow: hidden;
                margin-bottom: 32px;
                &:before {
                /*  content: "";
                  background: rgba(53, 53, 53, .2);
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  left: 0;
                  top: 0;
                  z-index: 1;*/
                }
                img {
                  width: 100%;
                  height: 570px;
                  object-fit: cover;
                  border-radius: 11px;
                }
                .play-icon {
                  width: 114px;
                  height: 114px;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background: $white;
                  cursor: pointer;
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translateX(-50%) translateY(-50%);
                  z-index: 2;
                  img {
                    width: 30px;
                    height: 36px;
                  }
                }
              }
              .transcript-wrapper {
                //border: 1px solid #B5B6C4;
                //border-bottom: none;
                box-shadow: 0 0 4px 0 rgba(32, 32, 39, 0.2);
                border-radius: 12px;
                padding: 24px 24px;
                .transcript-header {
                  margin-bottom: 20px;
                  .content {
                    p {
                      margin-bottom: 0;
                      &.title {
                        color: $text-primary06;
                      }
                    }
                    span {
                      color: #8C8FA4;
                      font-size: 14px;
                      line-height: 20px;
                    }
                  }
                  a {
                    &.translate {
                      display: flex;
                      align-items: center;
                      justify-content: right;
                      gap: 6px;
                      font-weight: 600;
                      color: #D7D8E0;
                      img {
                        max-width: 15px;
                      }
                    }
                  }
                }
                .transcript-body {
                  ul.transcript {
                    li {
                      display: flex;
                      align-items: self-start;
                      gap: 13px;
                      &:not(:last-of-type) {
                        margin-bottom: 20px;
                      }
                      p {
                        margin-bottom: 0;
                        color: #373843;
                        &.time-tag {
                          font-weight: 600;
                          padding: 0 8px;
                          color: $primary01;
                        }
                      }
                    }
                  }
                  ul.identified {
                    li {
                      position: relative;
                      &:not(:last-of-type) {
                        margin: 0 20px 20px 0;
                      }
                      .identified-card {
                        position: relative;
                        //overflow: hidden;
                        border-radius: 6px;
                        &:before {
                          content: "";
                          background: rgb(0, 0, 0);
                          background: linear-gradient(0deg, rgba(0, 0, 0, .2) 0%, rgba(1, 2, 5, 0) 34%);
                          width: 100%;
                          height: 100%;
                          position: absolute;
                          left: 0;
                          top: 0;
                          z-index: 1;
                        }
                        .cross-icon {
                          position: absolute;
                          right: -9px;
                          top: -9px;
                          img {
                            max-width: 18px;
                          }
                        }
                        .img-wrapper {
                          img {
                            height: 90px;
                            width: auto;
                            object-fit: cover;
                            border-radius: 6px;
                          }
                        }
                        .content {
                          padding: 0 0 5px 5px;
                          width: 100%;
                          height: 100%;
                          position: absolute;
                          bottom: 0;
                          left: 0;
                          z-index: 2;
                          display: flex;
                          align-items: self-end;
                          p {
                            margin-bottom: 0;
                            font-size: 12px;
                            line-height: 18px;
                            font-weight: 500;
                            color: $white;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.edit-clip-modal {
  width: 792px !important;
  .ant-modal-content {
    padding: 32px;
    border-radius: 17px;
    .ant-modal-close, .ant-modal-header, .ant-modal-footer {
      display: none;
    }
    .ant-modal-body {
      .edit-clip-wrapper {
        .clip-header {
          margin-bottom: 43px;
          h3 {
            font-size: 24px;
            line-height: 33px;
            font-weight: 600;
            color: #202027;
            margin-bottom: 0;
          }
        }
        .clip-body {
          .video-wrapper {
            border-radius: 12px;
            overflow: hidden;
            video {
              border-radius: 12px;
              width: 100%;
              height: 570px;
              object-fit: cover;
            }
          }
        }
        .clip-footer {
          margin: 30px 0 0;
          .clip-time {
            display: flex;
            align-items: center;
            gap: 33px;
            > p {
              font-weight: 600;
            }
            p {
              margin-bottom: 0;
              color: #373843;
              &.small {
                font-size: 12px;
                line-height: 18px;
              }
            }
            ul.time-count {
              li {
                display: inline-flex;
                align-items: center;
                gap: 7px;
                &:not(:last-of-type) {
                  margin: 0 13px 0 0;
                }
                .count {
                  height: 40px;
                  color: #8C8FA4;
                  padding: 10px 10px;
                  border-radius: 8px;
                  background: #F7F7F8;
                }
              }
            }
          }
          .btn-wrapper {
            text-align: right;
            .btn-style {
              background: $primary01;
              color: $white;
              height: 40px;
              padding: 12px 14px;
              border-radius: 12px;
              min-width: auto;
            }
          }
        }
      }
    }
  }
}
.small-video {
  position: fixed;
  bottom: 30px;
  width: 322px;
  overflow: hidden;
  height: 188px;
  border-radius: 12px;
  right: 45px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.right-active {
  .small-video {
    right: 420px;
  }
}
.ai-search-scrolled-section {
  height: 0;
  width: 100%;
  overflow: hidden;
  // background: red;
  transition: 1s linear;
  margin-bottom: 20px;
  &.active {
    height: calc(100vh - 50px);
  }
}
.activate-ai-modal{
  width: 655px!important;
  .ant-modal-content{
    border-radius: 17.49px;
    background: $white;
    box-shadow: 1px 0 4px 2px rgba(85, 85, 85, 0.20);
    .ant-modal-header, .ant-modal-footer{
      display: none;
    }
    .ant-modal-close{
      top: 10px;
      right: 8px;
      &:hover{
        background: transparent;
      }
      .ant-modal-close-x{
        .anticon{
          svg{
            width: 20px;
            height: 20px;
            path{
              fill: #3E3E4E;
            }
          }
        }
      }
    }
    .ant-modal-body{
      .ant-popover{
        .ant-popover-content{
          .ant-popover-arrow{}
          .ant-popover-inner{
            border-radius: 17.49px;
            background: $white;
            box-shadow: 4px 4px 25px 0 rgba(31, 34, 39, 0.21);
            padding: 20px;
            .ant-popover-inner-content{
              max-width:300px;
              .ai-popover{
                h3{
                  margin-bottom: 10px;
                  color: #202027;
                }
                p{
                  margin-bottom: 20px;
                  color: #48495C;
                }
                .popover-footer{
                  display: flex;
                  align-items: center;
                  justify-content: right;
                  gap: 10px;
                  .btn-style{
                    //height: 48px;
                    min-width: 116px;
                    border-radius: 12.42px;
                    &.bg-red{
                      background: #EF3225;
                      border-color: #EF3225;
                    }
                    &.transparent{
                      &:hover, &:focus, &:active{
                        background: transparent;
                      }
                      &.black{
                        border-color: #585971;
                        color: #585971;
                        &:hover{
                          background: transparent;
                          border-color: #585971!important;
                          span{
                            color: #585971!important;
                          }
                        }
                        span{
                          color: #585971;
                        }
                      }
                      &.orange{
                        border-color: $primary01;
                        color: $primary01;
                        &:hover{
                          background: transparent;
                          border-color: $primary01!important;
                          span{
                            color: $primary01!important;
                          }
                        }
                        span{
                          color: $primary01;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .activate-ai-wrapper{
        min-height: 630px;
        >h3{
          margin-bottom: 24px;
        }
        .form {
          width: 100%;
          max-width: 364px;
          .form-group {
            position: relative;
            margin-bottom: 24px;
            .form-control {
              height: 44px;
              border: none;
              border-radius: 8.28px;
              background: #F2F2F4;
              padding: 5px 12px 5px 36px;
              color: #8C8FA4;
              &::placeholder {
                color: #8C8FA4;
              }
            }
            .icon {
              position: absolute;
              left: 8px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
        .activate-cloud-main{
          min-height: 470px;
          .activate-cloud-wrapper{
            .cloud-item-body-wrapper{
              height: 425px;
              overflow: auto;
              &::-webkit-scrollbar {
                background-color: $white;
                width: 16px;
              }
              &::-webkit-scrollbar-track {
                background-color: $white;
              }
              &::-webkit-scrollbar-thumb {
                background-color: $primary01;
                border-radius: 16px;
                border: 4px solid $white;
              }
              &::-webkit-scrollbar-button {
                display: none;
              }
            }
            .cloud-item{
              display: grid;
              grid-template-columns: 157px 315px 55px;
              align-items: center;
              justify-content: space-between;
              column-gap: 24px;
              padding: 8px 15px;
              background: $white;
              border-bottom: 1px solid #EDEDF1;
              &.header{
                border-bottom: none;
                padding: 12px 15px;
                background: #F7F7F8;
                p{
                  color: #6E7189;
                  margin-bottom: 0;
                }
              }
              p{
                color: #48495C;
                font-weight: 500;
                margin-bottom: 0;
              }
              .cloud-name{
                display: grid;
                grid-template-columns: 27px auto;
                align-items: center;
                column-gap: 8px;
                img {
                  max-width: 16px!important;
                  left: 10px !important;
                  &.larger{
                    max-width: 20px!important;
                  }
                }
              }
              .switch-wrapper{
                min-width: 55px;
                text-align: center;
                .ant-switch {
                  height: 20px;
                  max-width: 32px;
                  box-shadow: none;
                  outline: none;
                  border: 1px solid rgba(0, 0, 0, 0.20);
                  background: rgba(0, 0, 0, 0.07);
                  &.ant-switch-checked:hover:not(.ant-switch-disabled) {
                    //background: rgba(0, 0, 0, 0.07) !important;
                    background: $primary01!important;
                  }
                  &.ant-switch-checked {
                    background: $primary01;
                    .ant-switch-handle {
                      height: 20px;
                      width: 20px;
                      left: unset;
                      right: 7px;
                      top: 2px;
                      &:before {
                        height: 20px;
                        width: 20px;
                        left: unset;
                        right: -8px;
                        top: -3px !important;
                      }
                    }
                  }
                  .ant-switch-handle {
                    height: 20px;
                    width: 20px;
                    left: -7px;
                    top: -1px;
                    &:before {
                      height: 20px;
                      width: 20px;
                      left: unset;
                      right: -6px;
                      top: 0 !important;
                      border: 1px solid rgba(0, 0, 0, 0.20);
                    }
                  }
                }
              }
            }
          }
        }
        .ai-footer{
          margin: 24px 0 0;
          .ai-pagination{
            li{
              vertical-align: middle;
              &:not(:last-of-type){
                margin: 0 8px 0 0;
              }
              &:nth-of-type(1){
                margin: 0 4px 0 0;
              }
              &:nth-last-child(2) {
                margin: 0 4px 0 0;
              }
              p{
                color: $text-primary06;
                font-weight: 500;
                margin-bottom: 0;
              }
              .count{
                min-width: 74px;
                height: 40px;
                border-radius: 8.28px;
                background: #F7F7F8;
                display: flex;
                align-items: center;
                padding: 10px 4px 10px 18px;
                p{
                }
              }
            }
          }
          .btn-wrapper{
            text-align: right;
          }
        }
      }
    }
  }
}
.height-auto{
  height: auto !important;
}
