@font-face {
    font-family: 'FK Grotesk Neue Trial Bold It';
    src: url('FKGroteskNeueTrial-BoldItalic.eot');
    src: url('FKGroteskNeueTrial-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('FKGroteskNeueTrial-BoldItalic.woff2') format('woff2'),
        url('FKGroteskNeueTrial-BoldItalic.woff') format('woff'),
        url('FKGroteskNeueTrial-BoldItalic.ttf') format('truetype'),
        url('FKGroteskNeueTrial-BoldItalic.svg#FKGroteskNeueTrial-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'FK Grotesk Neue Trial Light It';
    src: url('FKGroteskNeueTrial-LightItalic.eot');
    src: url('FKGroteskNeueTrial-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('FKGroteskNeueTrial-LightItalic.woff2') format('woff2'),
        url('FKGroteskNeueTrial-LightItalic.woff') format('woff'),
        url('FKGroteskNeueTrial-LightItalic.ttf') format('truetype'),
        url('FKGroteskNeueTrial-LightItalic.svg#FKGroteskNeueTrial-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'FK Grotesk Neue Trial Black It';
    src: url('FKGroteskNeueTrial-BlackItalic.eot');
    src: url('FKGroteskNeueTrial-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('FKGroteskNeueTrial-BlackItalic.woff2') format('woff2'),
        url('FKGroteskNeueTrial-BlackItalic.woff') format('woff'),
        url('FKGroteskNeueTrial-BlackItalic.ttf') format('truetype'),
        url('FKGroteskNeueTrial-BlackItalic.svg#FKGroteskNeueTrial-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'FK Grotesk Neue Trial';
    src: url('FKGroteskNeueTrial-Black.eot');
    src: url('FKGroteskNeueTrial-Black.eot?#iefix') format('embedded-opentype'),
        url('FKGroteskNeueTrial-Black.woff2') format('woff2'),
        url('FKGroteskNeueTrial-Black.woff') format('woff'),
        url('FKGroteskNeueTrial-Black.ttf') format('truetype'),
        url('FKGroteskNeueTrial-Black.svg#FKGroteskNeueTrial-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FK Grotesk Neue Trial';
    src: url('FKGroteskNeueTrial-Light.eot');
    src: url('FKGroteskNeueTrial-Light.eot?#iefix') format('embedded-opentype'),
        url('FKGroteskNeueTrial-Light.woff2') format('woff2'),
        url('FKGroteskNeueTrial-Light.woff') format('woff'),
        url('FKGroteskNeueTrial-Light.ttf') format('truetype'),
        url('FKGroteskNeueTrial-Light.svg#FKGroteskNeueTrial-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FK Grotesk Neue Trial';
    src: url('FKGroteskNeueTrial-Italic.eot');
    src: url('FKGroteskNeueTrial-Italic.eot?#iefix') format('embedded-opentype'),
        url('FKGroteskNeueTrial-Italic.woff2') format('woff2'),
        url('FKGroteskNeueTrial-Italic.woff') format('woff'),
        url('FKGroteskNeueTrial-Italic.ttf') format('truetype'),
        url('FKGroteskNeueTrial-Italic.svg#FKGroteskNeueTrial-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'FK Grotesk Neue Trial Medium It';
    src: url('FKGroteskNeueTrial-MediumItalic.eot');
    src: url('FKGroteskNeueTrial-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('FKGroteskNeueTrial-MediumItalic.woff2') format('woff2'),
        url('FKGroteskNeueTrial-MediumItalic.woff') format('woff'),
        url('FKGroteskNeueTrial-MediumItalic.ttf') format('truetype'),
        url('FKGroteskNeueTrial-MediumItalic.svg#FKGroteskNeueTrial-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'FK Grotesk Neue Trial';
    src: url('FKGroteskNeueTrial-Bold.eot');
    src: url('FKGroteskNeueTrial-Bold.eot?#iefix') format('embedded-opentype'),
        url('FKGroteskNeueTrial-Bold.woff2') format('woff2'),
        url('FKGroteskNeueTrial-Bold.woff') format('woff'),
        url('FKGroteskNeueTrial-Bold.ttf') format('truetype'),
        url('FKGroteskNeueTrial-Bold.svg#FKGroteskNeueTrial-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FK Grotesk Neue Trial';
    src: url('FKGroteskNeueTrial-Medium.eot');
    src: url('FKGroteskNeueTrial-Medium.eot?#iefix') format('embedded-opentype'),
        url('FKGroteskNeueTrial-Medium.woff2') format('woff2'),
        url('FKGroteskNeueTrial-Medium.woff') format('woff'),
        url('FKGroteskNeueTrial-Medium.ttf') format('truetype'),
        url('FKGroteskNeueTrial-Medium.svg#FKGroteskNeueTrial-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FK Grotesk Neue Trial Thin It';
    src: url('FKGroteskNeueTrial-ThinItalic.eot');
    src: url('FKGroteskNeueTrial-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('FKGroteskNeueTrial-ThinItalic.woff2') format('woff2'),
        url('FKGroteskNeueTrial-ThinItalic.woff') format('woff'),
        url('FKGroteskNeueTrial-ThinItalic.ttf') format('truetype'),
        url('FKGroteskNeueTrial-ThinItalic.svg#FKGroteskNeueTrial-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'FK Grotesk Neue Trial';
    src: url('FKGroteskNeueTrial-Regular.eot');
    src: url('FKGroteskNeueTrial-Regular.eot?#iefix') format('embedded-opentype'),
        url('FKGroteskNeueTrial-Regular.woff2') format('woff2'),
        url('FKGroteskNeueTrial-Regular.woff') format('woff'),
        url('FKGroteskNeueTrial-Regular.ttf') format('truetype'),
        url('FKGroteskNeueTrial-Regular.svg#FKGroteskNeueTrial-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FK Grotesk Neue Trial';
    src: url('FKGroteskNeueTrial-Thin.eot');
    src: url('FKGroteskNeueTrial-Thin.eot?#iefix') format('embedded-opentype'),
        url('FKGroteskNeueTrial-Thin.woff2') format('woff2'),
        url('FKGroteskNeueTrial-Thin.woff') format('woff'),
        url('FKGroteskNeueTrial-Thin.ttf') format('truetype'),
        url('FKGroteskNeueTrial-Thin.svg#FKGroteskNeueTrial-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

