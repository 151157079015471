@import "standard";
@import "all.min.css";

.App.onboarding{
  #left-sidebar{
    //display: none;
  }
  #dashboard-header{
    width: 100%;
  }
  #main-content{
    margin: 61px 0 0 0;
  }
}

.onboarding-screen-main{
  height: calc(100vh - 110px);
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: $screen-xs) {
    height: auto;
    padding: 10px 0;
  }

  .logo-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    &.no-flex{
      display: block;
    }
    strong{
      font-size: 36px;
      line-height: 45px;
      font-weight: 600;
      color: #EA4903 !important;
    }
  }
  .onboarding-step{
    text-align: center;
    width: 100%;
    &.onboarding-step-1, &.onboarding-step-2, &.onboarding-step-3{
      //display: none;
    }
    >h2{
      font-size: 36px;
      line-height: 45px;
      font-weight: 600;
      color: $text-primary06;
      @media screen and (max-width: $screen-xs) {
        font-size: 28px;
        line-height: 36px;
      }
      strong{
        font-size: 36px;
        line-height: 45px;
        font-weight: 600;
        color: $primary01;
        @media screen and (max-width: $screen-xs) {
          font-size: 28px;
          line-height: 36px;
        }
      }
    }
    h3{
      font-size: 24px;
      line-height: 33px;
      color: $text-primary06;
      margin-bottom: 0;
      @media screen and (max-width: $screen-xs) {
        font-size: 20px;
        line-height: 28px;
      }
      strong{
        font-size: 24px;
        line-height: 33px;
        color: $primary01;
        @media screen and (max-width: $screen-xs) {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
    p{
      font-size: 14px;
      line-height: 21px;
      color: $text-primary08;
      margin-bottom: 0;
      @media screen and (max-width: $screen-xs) {
        br{
          display: none;
        }
      }
      &.p{
        font-size: 16px;
        line-height: 23px;
        &.title{
          font-size: 20px;
          line-height: 28px;
        }
      }
      &.small{
        font-size: 12px;
        line-height: 18px;
      }
      &.bold{
        font-weight: 700;
      }
      &.gray{
        color: $text-primary07;
      }
    }
    .btn-footer{
      margin: 32px auto 0;
      text-align: center;
      max-width: 324px;
      &.grid-two{
        display: grid;
        grid-template-columns: 125px auto;
        column-gap: 15px;
      }
      .btn-style{
        width: 100%;
        max-width: 324px;
        height: 40px;
        background: $primary01;
        border-color: $primary01;
        color: $white;
        &.disabled{
          background: #EDEDF1;
          color: #B5B6C4;
          border-color: #EDEDF1;
          span{
            color: #B5B6C4;
          }
        }
        &.transparent{
          border-color: $primary01;
          color: $primary01;
          background: transparent;
          span{
            color: $primary01!important;
          }
        }
        &:hover{
          border-color: $primary01;
          color: $primary01;
          background: transparent;
          span{
            color: $primary01!important;
          }
        }
        span{
          color: $white;
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
    .pricing-wrapper{
      display: grid;
      grid-template-columns: repeat(1, calc(300px));
      justify-content: center;
      align-items: self-start;
      column-gap: 32px;
      .pricing-card{
        background: #F7F7F8;
        border-radius: 18px;
        padding: 24px 24px;
        text-align: left;
        p{
          margin-bottom: 0;
          &.discount{
            font-size: 10px;
            line-height: 18px;
          }
          &.price{
            margin: 8px 0 20px;
            color: $primary01;
          }
        }
        ul.feature{
          li{
            display: block;
            font-size: 12px;
            line-height: 20px;
            position: relative;
            color: $text-primary08;
            padding-left: 13px;
            &:not(:last-of-type){
              margin: 0 0 2px;
            }
            &:before{
              content: "";
              background: $text-primary08;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              position: absolute;
              left: 0;
              top: 7px;
            }
          }
        }
      }
    }
    .choose-cloud-wrapper{
      text-align: left;
      .ant-radio-group{
        display: grid;
        grid-template-columns: repeat(2, calc(290px));
        justify-content: center;
        align-items: self-start;
        column-gap: 24px;
        @media screen and (max-width: $screen-xs) {
          grid-template-columns: repeat(1, calc(290px));
          row-gap: 30px;
        }
        .ant-radio-wrapper{
          //height: 100%;
          margin: 0;
          &.ant-radio-wrapper-checked{
            .cloud-card{
              .title-wrapper{
                .circle{
                  background: $primary01;
                  border: 4px solid $white;
                  outline: 2px solid $primary01;
                  width: 18px;
                  height: 18px;
                }
              }
            }
          }
          .ant-radio{
            display: none;
          }
          span{
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0;
          }
          p.cloud-name{
            margin-bottom: 14px;
            @media screen and (max-width: $screen-xs) {
              text-align: center;
            }
          }
          .cloud-card{
            background: #F7F7F8;
            padding: 24px 24px;
            border-radius: 18px;
            height: 100%;
            p{
              margin-bottom: 0;
              &.title{
                margin-bottom: 10px;
              }
            }
            .title-wrapper{
              display: flex;
              align-items: self-start;
              justify-content: space-between;
              gap: 20px;
              .circle{
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background: #EDEDF1;
                border: 1px solid #D7D8E0;
                box-shadow: 0 1px 3px 0 rgba(0,0,0,.10);
                margin: 6px 0 0;
              }
            }
          }
        }
      }
    }
    .choose-region-wrapper{
      max-width: 440px;
      margin: auto;
      text-align: left;
      .region-card{
        >p{
          margin-bottom: 8px;
        }
        .ant-select{
          border-radius: 9px;
          .ant-select-selector{
            background: #F7F7F8;
            border: none;
            box-shadow: none;
            .ant-select-selection-search{
              input[type=search]{

              }
            }
          }
        }
      }
    }
  }
}
.provisioning-wrapper{
  max-width: 324px;
  margin: auto;
  text-align: center;
  .loader{
    margin-left: auto;
    margin-right: auto;
  }
}
