@import "standard";
@import "all.min.css";
$neue: 'Lato';
body {
  overflow-x: hidden;
  &.active {
    overflow: hidden;
  }
  &.side-menu-open {
    .hamburger-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      z-index: 2;
      cursor: pointer;
    }
    .dashboard-main {
      #main-content {
        width: calc(100% - 200px);
        overflow-x: hidden;
        transform: translate(135px, 0px);
      }
    }
  }
  &.sidebar-open {
    .dashboard-main {
      #dashboard-header {
        //width: calc(100% - 175px);
        width: calc(100% - 240px);
        ul.dashboard-left-menu {
          padding-left: 20px;
        }
      }
      #main-content {
        //width: calc(100% - 175px);
        //overflow-x: hidden;
        //transform: translate(135px, 0px);
        //margin: 0 0 0 180px;
        margin: 0 0 0 250px;
      }
      &.dashboard-page {
        #main-content {
          margin: 61px 0 0 250px;
          //margin: 61px 0 0 180px;
        }
      }
    }
  }
  &.file-sidebar-open {
    .dashboard-main {
      /*#dashboard-header {
        width: calc(100% - 450px);
        ul.dashboard-left-menu {
         // padding-left: 20px;
        }
      }*/
      #main-content {
        width: calc(100% - 450px);
        overflow-x: hidden;
      }
    }
  }
}
// *******************************************   Login Scss   *******************************************
.login {
  //max-width: 640px;
  height: 100vh;
  background: $white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  &::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $scroll-bar-color;
    border-radius: 16px;
    border: 4px solid #fff;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &.email-verification {
    .login-wrapper {
      .form {
        text-align: center;
        .email-icon {
          background: #fec1a7;
          //border: 10px solid #FEE0D3;
          border-radius: 28px;
          width: 56px;
          height: 56px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto 24px;
          position: relative;
          .heart-beat {
            position: absolute;
            top: -22px;
            left: -22px;
            height: 100px;
            width: 100px;
            z-index: 10;
            border: 10px solid #fee0d3;
            border-radius: 50%;
            animation: heartbit 1s ease-out;
            animation-iteration-count: infinite;
          }
          .fa {
            color: $primary01;
            font-size: 20px;
          }
        }
        .form-group {
          .code-input {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12px;
            margin-bottom: 30px;
            input {
              width: 100%;
              height: 80px;
              border: 2px solid $black;
              box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
              border-radius: 8px;
              font-weight: 500;
              font-size: 48px;
              line-height: 60px;
              text-align: center;
              letter-spacing: -0.02em;
              color: $black;
              &:focus {
                border: 2px solid $black;
                box-shadow: none;
                outline: none;
              }
            }
          }
        }
      }
    }
  }
  .email-verification-form-wrapper {
    a.logo {
      margin-bottom: 20px !important;
    }
    .email-verification-text {
      font-weight: 500 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      margin-bottom: 24px !important;
      max-width: 430px;
    }
    .form {
      h2 {
        margin-bottom: 22px !important;
      }
    }
    .bottom-p {
      margin-bottom: 37px !important;
    }
    .form-group.submit {
      margin-top: 30px;
      margin-bottom: 0;
    }
  }
  .login-wrapper {
    // border: 1px solid $border-primary01;
    border-radius: 12px;
    padding: 80px 72px;
    margin: auto;
    position: relative;
    @media screen and (max-width: $screen-xs) {
      padding: 0;
      //margin: 0 0 0 45px;
    }
    .btn-style {
      height: 48px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      &:active, &:hover, &:focus {
        background: #0074d4 !important;
        color: #fff !important;
        border-color: #0074d4 !important;
        span {
          color: #fff !important;
        }
      }
    }
    .bottom-text {
      margin-top: 28px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      @media screen and (max-width: $screen-xs) {
        margin-top: 15px;
      }
      a.bold {
        font-weight: 600;
        display: inline-block;
        margin-left: 5px;
      }
    }
    a.logo {
      margin-bottom: 50px;
      display: inline-block;
      img {
        margin: auto;
        @media screen and (max-width: $screen-xs) {
          max-width: 160px;
        }
      }
    }
    .main-form-wrapper {
      margin: 0 auto 0;
      position: relative;
      /*
            &:before {
              content: "";
              position: absolute;
              filter: blur(100px);
              transform: translateZ(0);
              z-index: -1;
              width: 290px;
              height: 290px;
              left: -20px;
              top: 0;
              background: #92bbeb;
              opacity: .5;
            }
            &:after {
              content: "";
              position: absolute;
              filter: blur(100px);
              transform: translateZ(0);
              z-index: -1;
              background: #dbe8b8;
              opacity: .8;
              width: 297px;
              height: 297px;
              top: 200px;
              right: -50px;
            }*/
      .google-wrapper {
        max-width: 320px;
        margin: auto;
        text-align: center;
        > p {
          margin: 13px 0 48px;
          font-weight: 500;
        }
        &.signin-page {
          max-width: 100%;
          margin: 15px 0 50px;
        }
      }
      .mail-footer {
        margin-top: 38px;
        p {
          margin-bottom: 15px;
          text-align: center;
          font-weight: 500;
        }
      }
      .signup-back {
        display: flex;
        align-items: center;
        gap: 12px;
        border-bottom: 1px solid $border-primary01;
        height: 40px;
        margin-bottom: 40px;
        p {
          margin-bottom: 0;
          padding: 0 0 6px;
        }
        i {
          font-size: 16px;
        }
      }
    }
    .form {
      width: 356px;
      position: relative;
      z-index: 12;
      background: $white;
      .name-wrapper-signup {
        .col-md-6 {
          &:first-of-type {
            padding-right: 7px;
          }
          &:last-of-type {
            padding-left: 7px;
          }
        }
      }
      .fields-wrapper {
        max-width: 320px;
        margin: auto;
      }
      &.new-form {
        width: auto;
        p {
          span {
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
      > h3 {
        text-align: center;
        margin-bottom: 30px;
        font-size: 24px;
        line-height: 34px;
        font-weight: 700;
        @media screen and (max-width: $screen-xs) {
          margin-bottom: 25px;
          font-size: 20px;
          line-height: 30px;
        }
      }
      > h2 {
        margin-bottom: 12px;
        @media screen and (max-width: $screen-xs) {
          font-size: 25px;
          line-height: 35px;
        }
      }
      .or-wrapper {
        text-align: center;
        position: relative;
        &:before {
          content: "";
          height: 1px;
          background: $border-primary01;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
        span {
          display: inline-block;
          padding: 0 20px;
          background: #fff;
          position: relative;
          z-index: 111;
        }
      }
      > p, > .email-verification-text {
        margin-bottom: 32px;
      }
      > .email-verification-text {
        font-weight: 500;
      }
      &.signup-setup-2 {
        padding: 0;
        > h3 {
          margin-bottom: 20px;
        }
        .signup-back {
          max-width: 330px;
          margin: 8px auto 20px;
          padding-left: 4px;
        }
      }
      .form-group {
        margin-bottom: 15px;
        position: relative;
        .padding-right {
          padding-right: 40px;
        }
        .fa {
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 43px;
          cursor: pointer;
          padding: 15px 18px;
        }
        .btn-style {
          &:active, &:hover, &:focus {
            background: #0074d4 !important;
            color: #fff !important;
            border-color: #0074d4 !important;
            span {
              color: #fff !important;
            }
          }
        }
        &.submit {
          margin: 4px 0 15px;
          .btn-style {
            min-width: 100%;
          }
          &.google-btn-new-wrapper > svg {
            position: absolute;
            left: 15px;
            height: 20px;
          }
          &.get-started {
            margin-top: 25px;
            > p {
              font-weight: 500;
              margin-bottom: 14px;
              font-size: 16px;
              line-height: 24px;
              a {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
              }
            }
          }
        }
        label {
          margin-bottom: 6px;
        }
        > span {
          margin-top: 6px;
          display: block;
          margin-bottom: 17px;
        }
        .btn-style {
          min-width: 100%;
        }
      }
      .remember {
        margin-bottom: 24px;
        .bottom-text {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          a.bold {
            font-weight: 600;
            display: inline-block;
            margin-left: 5px;
          }
        }
        .form-group {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-bottom: 0;
          label {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
.login-detail {
  padding: 93px 0 0 80px;
  height: 100vh;
  background: #f2f4f7;
  margin-left: 640px;
  h2 {
    margin: 0 0 84px;
  }
  .img-wrapper {
    height: calc(100vh - 291px);
    background-image: url("../images/login-detail-img.png");
    background-position: top left;
    background-size: cover;
  }
}
// *******************************************   Dashboard Scss   *******************************************
.dashboard-main {
  #left-sidebar {
    background: $white;
    //width: 72px;
    width: 64px;
    //width: 200px;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    min-height: 520px;
    top: 0;
    left: 0;
    z-index: 999;
    box-shadow: 1px 10px 20px rgba(0, 0, 0, 0.8%);
    transition: all 0.3s ease-in-out;
    border-right: 1px solid $white;
    @media screen and (max-height: 730px) {
      height: 100vh;
      overflow-y: auto;
      &::-webkit-scrollbar {
        background-color: #1A1A1F;
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background-color: #1A1A1F;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $primary01;
        border-radius: 16px;
        border: 3px solid #1A1A1F;
      }
      &::-webkit-scrollbar-button {
        display: none;
      }
      .left-sidebar-wrapper {
        .navbar-menu {
          .menu-wrapper-main {
            height: auto !important;
          }
          .menu.bottom-menu {
            position: relative !important;
            left: 0 !important;
            bottom: 0 !important;
            padding: 8px 0 0;
          }
        }
      }
    }
    &:hover {
      .left-sidebar-wrapper {
        .drawer-wrapper {
          .drawer {
            opacity: 1;
          }
          a.logo {
            opacity: 0;
          }
        }
      }
    }
    .left-sidebar-wrapper {
      min-height: 520px;
      padding: 32px 0 0;
      margin: 0 11px;
      //padding: 41px 0 0;
      //margin: 0 22px;
      .sidebar-clouds {
        border-top: 1px solid #48495C;
        border-bottom: 1px solid #48495C;
        padding: 10px 0 5px;
        .heading-wrapper {
          .add-icon {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        ul.menu {
          max-height: 220px;
          overflow-y: auto;
          overflow-x: hidden;
          &::-webkit-scrollbar {
            display: none;
          }
          &::-webkit-scrollbar-track {
            background-color: $white;
          }
          &::-webkit-scrollbar-thumb {
            background-color: $scroll-bar-color;
            border-radius: 16px;
            border: 4px solid $white;
          }
          &::-webkit-scrollbar-button {
            display: none;
          }
          li {
            margin-bottom: 4px !important;
            &:hover{
              a{
                img{
                  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(304deg) brightness(102%) contrast(101%) !important;
                }
              }
            }
            a {
              padding: 10px 10px 10px 30px!important;
              img {
                //max-width: 29px !important;
                //max-height: 100% !important;
                left: 5px !important;
                max-width: 16px!important;
                left: 10px !important;
                &.larger{
                  max-width: 20px!important;
                }
              }
            }
          }
        }
      }
      ul.bottom-menu {
        left: 8px !important;
      }
      .drawer-wrapper {
        position: relative;
        .drawer {
          position: absolute;
          left: 7px;
          top: 0;
          max-width: 24px;
          //transition: all 0.3s ease-in-out;
          opacity: 0;
          z-index: 3;
          cursor: pointer;
          transform-origin: center;
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
        }
        a.logo {
          display: block;
          width: 25px;
          overflow: hidden;
          margin-left: 10px;
          transition: all 0.3s ease-in-out;
          margin-bottom: 49px;
          position: relative;
          left: 1px;
          opacity: 1;
          img {
            width: 100px;
            height: 26px;
          }
        }
      }
      .navbar-menu {
        ul.menu {
          li {
            display: block;
            margin-bottom: 15px;
            position: relative;
            &:nth-of-type(4) {
              left: 2px;
            }
            &:nth-of-type(6) {
              left: 2px;
              a {
                img {
                  max-width: 23px;
                  left: 10px;
                }
              }
            }
            /* &:nth-of-type(6) {
               a {
                 img {
                   width: 15px;
                   height: 22px;
                 }
               }
             }*/
            &.left-menu-icon {
              svg {
                width: 15px;
                height: 22px;
              }
            }
            a {
              //display: grid;
              //grid-template-columns: 20px 100px;
              display: inline-flex;
              align-items: center;
              grid-gap: 10px;
              height: 40px;
              width: 40px;
              padding: 10px 10px 10px 30px;
              transition: all 0.4s ease-in-out;
              border-radius: 6px;
              position: relative;
              img {
                max-width: 18px;
                max-height: 23px;
                position: absolute;
                left: 12px;
                top: 50%;
                transform: translateY(-50%);
              }
              span {
                color: $text-primary02;
              }
              svg {
                margin: 0 15px 0 0;
                max-width: 15px;
              }
              span {
                width: 0;
                //overflow: hidden;
                white-space: nowrap;
                opacity: 0;
                display: inline-block;
                margin: 0 0 0 8px;
                font-size: 14px;
                transition: all 0.4s ease-in-out;
              }
              &.active {
                background: #f9fafb;
                color: $text-primary01;
              }
            }
            .fa {
              color: #fff;
            }
          }
        }
      }
    }
    a.active.search-icon {
      background: transparent !important;
      color: $white;
    }
    &.dark {
      //background: $primary02;
      background: $dark-primary01;
      border-right: 1px solid $dark-border01;
      .left-sidebar-wrapper {
        .navbar-menu {
          ul.menu {
            li {
              a.active {
                background: $primary01;
                color: $white;
                border-radius: 6px;
              }
              a {
                &.tooltip-open {
                  background: transparent;
                }
              }
              &:hover {
                a {
                  //background: #6a3219;
                  background: $primary01;
                  color: $white;
                  border-radius: 6px;
                }
              }
              a {
                span {
                  color: $white;
                }
                svg {
                  path {
                    stroke: $white;
                  }
                }
              }
            }
          }
          ul.bottom-menu {
            position: absolute;
            bottom: 36px;
            left: 24px;
            height: auto !important;
            width: calc(100% - 22px);
            @media screen and (max-width: $screen-xs) {
              bottom: 72px;
            }
            li {
              margin-bottom: 6px;
              &.theme-switch-main {
                a {
                  padding: 10px;
                }
                .ant-switch {
                  height: 20px;
                  min-width: 32px;
                  box-shadow: none;
                  outline: none;
                  border: 1px solid #585971;
                  background: #48495C;
                  &.ant-switch-checked:hover:not(.ant-switch-disabled) {
                    background: #48495C !important;
                  }
                  &.ant-switch-checked {
                    .ant-switch-handle {
                      height: 16px;
                      width: 16px;
                      left: unset;
                      right: 7px;
                      top: 2px;
                      &:before {
                        height: 16px;
                        width: 16px;
                        left: unset;
                        right: -6px;
                        background: url("../images/icons/theme-moon-icon.svg") no-repeat $white;
                        background-size: 10px;
                        background-position: 50%;
                        top: -1px !important;
                      }
                    }
                  }
                  .ant-switch-handle {
                    height: 16px;
                    width: 16px;
                    left: -5px;
                    top: 1px;
                    &:before {
                      height: 16px;
                      width: 16px;
                      left: unset;
                      right: -6px;
                      background: url("../images/icons/theme-sun-icon.svg") no-repeat $white;
                      background-size: 10px;
                      background-position: 50%;
                      top: 0 !important;
                    }
                  }
                }
              }
              a {
                text-align: left;
              }
            }
          }
        }
      }
    }
    &.active {
      //width: 175px;
      width: 240px;
      .left-sidebar-wrapper {
        margin: 0 22px;
        .drawer-wrapper {
          text-align: center;
          .drawer {
            //right: 0;
            //top: -2px;
            //left: unset;
            top: 30px;
            right: -24px;
            left: unset;
            transform-origin: center;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
          }
          a.logo {
            width: 130px;
            opacity: 1;
            margin: 0 auto 49px;
            img {
              width: 125px;
            }
          }
        }
        .navbar-menu {
          .menu-wrapper-main {
            height: calc(100vh - 127px);
            overflow-y: auto;
            overflow-x: hidden;
            &::-webkit-scrollbar {
              background-color: #531A01;
              width: 16px;
            }
            &::-webkit-scrollbar-track {
              background-color: #531A01;
            }
            &::-webkit-scrollbar-thumb {
              background-color: #fff;
              border-radius: 16px;
              border: 4px solid #531A01;
            }
            &::-webkit-scrollbar-button {
              display: none;
            }
            .sidebar-clouds {
              padding: 20px 0 9px;
              border-top: 1px solid #48495C;
              border-bottom: 1px solid #48495C;
              .small-wrapper {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .heading-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 15px;
                margin-bottom: 9px;
                p {
                  margin-bottom: 0;
                  font-size: 16px;
                  line-height: 24px;
                  font-weight: 600;
                  color: $white !important;
                }
                .add-icon {
                  transition: all .3s ease-in-out;
                  &:hover {
                    transform: scale(1.1);
                  }
                }
              }
              ul.menu {
                li {
                  margin-bottom: 0;
                  a {
                    padding: 10px 10px 10px 35px;
                    img {
                      max-width: 29px;
                      max-height: 100%;
                      left: 8px;
                    }
                  }
                }
              }
            }
          }
          ul.menu {
            li {
              /* &:nth-of-type(1) {
                 a {
                   width: 128.16px;
                 }
               }
               &:nth-of-type(2) {
                 a {
                   width: 118.05px;
                 }
               }
               &:nth-of-type(3) {
                 a {
                   width: 132.89px;
                 }
               }
               &:nth-of-type(4) {
                 a {
                   width: 105.11px;
                 }
               }
               &:nth-of-type(5) {
                 a {
                   width: 151.14px;
                 }
               }
               &:nth-of-type(6) {
                 a {
                   width: 97.78px;
                 }
               }
               &:nth-of-type(7) {
                 a {
                   width: 105.7px;
                 }
               }*/
              a {
                border-radius: 6px;
                padding: 10px 10px 10px 32px;
                //width: 151.14px;
                width: 100%;
              }
              span {
                opacity: 1;
                width: 100%;
              }
            }
          }
          ul.bottom-menu {
            @media screen and (max-width: $screen-xs) {
              bottom: 0;
            }
          }
        }
      }
    }
  }
  #dashboard-header {
    z-index: 3;
    width: calc(100%);
    position: fixed;
    top: 0;
    right: 0;
    padding: 0;
    border-bottom: 1px solid $border-primary02;
    background: $white;
    transition: all .3s ease-in-out;
    @media screen and (max-width: 991px) {
      width: calc(100% - 64px);
      &.active {
        z-index: 9999;
      }
    }
    .min-height {
      min-height: 61px;
    }
    .dashboard-mobile-options {
      display: none;
      @media screen and (max-width: 991px) {
        display: block;
      }
      .hamburger-menu {
        @media screen and (max-width: 991px) {
          text-align: left;
        }
        i {
          @media screen and (max-width: 991px) {
            font-size: 23px;
          }
        }
      }
      .menu-bg-layer {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        background: rgba(52, 64, 84, 0.7);
        -webkit-backdrop-filter: blur(8px);
        backdrop-filter: blur(8px);
        display: none;
        transition: all .3s ease-in-out;
        z-index: 1;
        &.active {
          display: block;
        }
      }
      .mobile-options-navbar {
        @media screen and (max-width: 991px) {
          position: fixed;
          top: 0;
          right: -100%;
          width: 50%;
          height: 100vh;
          background: $white;
          z-index: 9999;
          transition: all .3s ease-in-out;
          &.active {
            right: 0;
          }
        }
        ul.mobile-options-menu {
          @media screen and (max-width: 991px) {
            padding: 20px 20px;
          }
          li {
            display: block;
            &:not(:last-of-type) {
              margin-bottom: 10px;
            }
            a.btn-style {
              @media screen and (max-width: 991px) {
                width: 100%;
              }
              &.brown {
                @media screen and (max-width: 991px) {
                  background: #531A01 !important;
                  border-color: #531A01 !important;
                  color: #fff !important;
                }
              }
              &.yellow-brown {
                @media screen and (max-width: 991px) {
                  background: #dcfe46 !important;
                  border-color: #dcfe46 !important;
                  color: #000 !important;
                }
              }
              &.orange {
                @media screen and (max-width: 991px) {
                  border: 1px solid #EA4903 !important;
                  background: #EA4903 !important;
                  color: #fff !important;
                }
              }
            }
          }
        }
      }
    }
    .hamburger-wrapper {
      display: flex;
      align-items: center;
      justify-content: left;
      gap: 52px;
    }
    .letter-wrapper {
      height: 40px;
      width: 40px;
      text-align: center;
      background-color: $primary01;
      border-radius: 50%;
      color: #fff;
      display: block;
      font-size: 20px;
      line-height: 24px;
      padding-top: 9px;
      cursor: pointer;
    }
    .dashboard-options {
      li {
        &:not(:last-of-type) {
          @media screen and (max-width: 991px) {
            display: none;
          }
        }
        &.notification-wrapper {
          @media screen and (max-width: 991px) {
            display: inline-block;
            margin: 0 !important;
          }
        }
        .user-account-dropdown {
          li {
            display: block !important;
          }
          .user-account {
            align-items: self-start;
            > svg {
              position: relative;
              top: 5px;
            }
            .company-detail {
              .user-name {
                display: block;
                font-weight: 500;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                @media screen and (max-width: 1366px) and (min-width: 1200px) {
                  max-width: 230px;
                }
                @media screen and (max-width: 1199px) and (min-width: 992px) {
                  max-width: 125px;
                }
                @media screen and (max-width: $screen-xs) {
                  max-width: 155px;
                }
              }
              .company-name {
                display: block;
                font-size: 12px;
                color: #736c64;
              }
            }
          }
        }
      }
    }
  }
  &.dashboard-page {
    #main-content {
      margin-top: 61px;
    }
  }
  #main-content {
    margin: 0 0 0 72px;
    transition: 0.3s;
    position: relative;
    border-radius: 3px;
    padding: 24px 9px 25px;
    //remove it once you add tour-index class
    // z-index: 9999;
    @media screen and (max-width: $screen-xs) {
      margin: 61px 0 0 64px;
      padding: 24px 5px 25px;
    }
    &.tour-index {
      position: relative;
      z-index: 9999;
      &.step-5 {
        overflow: hidden;
        height: calc(100vh - 65px);
      }
    }
    .main-content-body {
      &.dashboard-home {
        .cloud-brands-wrapper {
          &.choose-cloud-wrapper {
            .left-content {
              margin-right: 0;
              .drive-brands {
                .choose-cloud-counter {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  @media screen and (max-width: $screen-xs) {
                    display: block;
                    margin: 40px 0 20px;
                  }
                  &.mobile-flex {
                    @media screen and (max-width: $screen-xs) {
                      display: flex;
                    }
                  }
                  .content-wrapper {
                    h3 {
                      margin-bottom: 0;
                      @media screen and (max-width: $screen-xs) {
                        margin-bottom: 5px;
                      }
                    }
                    p {
                      margin-bottom: 0;
                    }
                  }
                }
                .quick-bucket-wrapper {
                  display: flex;
                  align-items: center;
                  //justify-content: space-between;
                  position: relative;
                  gap: 20px;
                  @media screen and (max-width: 991px) {
                    display: block;
                  }
                  &:before {
                    content: "";
                    background: #E8E8E8;
                    height: 60px;
                    width: 1px;
                    position: absolute;
                    left: calc(50% - 90px);
                    transform: translateX(-50%);
                    top: 12px;
                    display: none;
                    @media screen and (max-width: 1199px) and (min-width: 992px) {
                      left: calc(50% - 75px);
                    }
                    @media screen and (max-width: 991px) {
                      display: none;
                    }
                  }
                  &.no-flex {
                    display: block;
                    &:before {
                      display: none;
                    }
                  }
                  .change-cloud-wrapper {
                    @media screen and (max-width: 991px) {
                      justify-content: left;
                    }
                  }
                  .enter-quick-bucket {
                    position: relative;
                    @media screen and (max-width: 991px) {
                      margin: 15px 0 0;
                    }
                    .form {
                      max-width: 440px;
                      display: grid;
                      grid-template-columns: 250px auto;
                      align-items: self-end;
                      column-gap: 15px;
                      @media screen and (max-width: 1199px) and (min-width: 992px) {
                        max-width: 340px;
                        grid-template-columns: 210px auto;
                      }
                      @media screen and (max-width: 1199px) and (min-width: 992px) {
                        grid-template-columns: 200px 100px;
                      }
                      @media screen and (max-width: $screen-xs) {
                        grid-template-columns: 200px 100px;
                      }
                      .form-group {
                        .btn-style {
                          min-width: 115px;
                          color: #fff !important;
                          background: $primary01 !important;
                          border-color: $primary01 !important;
                          padding-left: 10px;
                          padding-right: 10px;
                          @media screen and (max-width: 1199px) {
                            min-width: auto;
                          }
                        }
                      }
                    }
                  }
                }
                .choose-cloud-card-wrapper {
                  margin-top: 20px;
                  display: flex;
                  flex-wrap: wrap;
                  column-gap: 64px;
                  .form-group {
                    flex: 0 0 auto;
                    /* Fixed width */
                    //padding: 20px;
                    //background-color: #f0f0f0;
                    //border: 1px solid #ccc;
                    // margin: 5px;
                    // min-width: 200px;
                    /* Minimum width */
                    //max-width: 300px;
                    //display: inline-block;
                    width: 176px;
                    //margin-right: 64px;
                    label {
                      width: 100%;
                      display: block;
                    }
                    input {
                      display: none;
                    }
                  }
                  .choose-cloud-card {
                    background: $white;
                    box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1),
                    0 1px 2px rgba(16, 24, 40, 0.06);
                    border-radius: 10px;
                    text-align: center;
                    cursor: pointer;
                    overflow: hidden;
                    .img-wrapper {
                      padding: 15px 10px 15px;
                      height: 70px;
                      img {
                        width: 40px;
                        position: relative;
                        top: 50%;
                        transform: translateY(-50%);
                        &.wasabi-icons {
                          width: 35px;
                        }
                        &.storj-icon {
                          height: 38px;
                        }
                        &.backblaze-icon {
                          height: 38px;
                        }
                        &.idrive-icon {
                          height: 36px;
                        }
                      }
                    }
                    .content-wrapper {
                      background: #fcfcfd;
                      border-top: 1px solid #eaecf0;
                      padding: 8px 6px;
                      p {
                        margin-bottom: 0;
                        color: $text-primary02;
                      }
                    }
                  }
                }
              }
              .col-md-8 {
                .btn-style:not(.transparent) {
                  background: $primary01;
                  border-color: $primary01;
                  span {
                    color: $white;
                  }
                }
              }
            }
            .drive-brands {
              padding: 0 0 20px;
              &.step-2 {
                border-top: 1px solid #dbdfe5;
                padding: 20px 0 20px 0;
                @media screen and (max-width: $screen-xs) {
                  padding: 20px 0 0;
                }
              }
              .choose-cloud-counter {
                @media screen and (max-width: $screen-xs) {
                  margin: 0 0 20px;
                }
              }
            }
            .setup-drive-footer {
              .btn-style {
                @media screen and (max-width: $screen-xs) {
                  margin: 15px 0 0;
                }
              }
            }
            .change-cloud-wrapper {
              display: flex;
              align-items: center;
              justify-content: right;
              max-width: 550px;
              @media screen and (max-width: $screen-xs) {
                justify-content: left;
              }
              .choose-cloud-card {
                display: flex;
                align-items: center;
                gap: 18px;
                padding: 16px 16px;
                background: $white;
                border: 1px solid #d0d5dd;
                border-radius: 12px;
                max-width: 190px;
                &.files-page {
                  padding: 14px 16px;
                  max-width: 300px;
                  min-height: 70px;
                }
                .img-wrapper {
                  width: 36px;
                  height: 36px;
                  min-width: 36px;
                  border-radius: 50%;
                  border: 1px solid #f2f4f7;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  img {
                    max-width: 24px;
                    &.backblaze-icon {
                      height: 24px;
                      left: 0;
                    }
                    &.idrive-icon {
                      max-width: 18px;
                    }
                  }
                }
                .content-wrapper {
                  p {
                    margin-bottom: 0;
                  }
                }
              }
              &.selected-path {
                display: flex;
                align-items: center;
                justify-content: left;
                &.selected-path-top {
                  margin-top: 15px;
                }
                .choose-cloud-card {
                  max-width: 532px;
                  width: 100%;
                  position: relative;
                  margin-right: auto;
                  padding: 11px 16px;
                  border: 1px solid #32d583;
                  border-radius: 8px;
                  user-select: none;
                  @extend .help-circle;
                  .content-wrapper {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    p {
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    }
                  }
                }
              }
              a.btn-style {
                border-color: transparent;
                box-shadow: none;
                color: #b73903;
                min-width: 50px;
              }
            }
          }
          &.setup-cloud-wrapper {
            .left-content {
              .drive-brands {
                .choose-cloud-counter {
                  .counter {
                    &.active {
                      position: relative;
                      background: $green;
                      span {
                        display: none;
                      }
                      &:before {
                        content: "\f00c";
                        font-family: "Font Awesome 6 Free";
                        display: inline-block;
                        font-size: 15px;
                        font-weight: 900;
                        color: $white;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translateY(-50%) translateX(-50%);
                      }
                    }
                  }
                }
              }
            }
          }
          &.final-cloud-wrapper {
            .final-selected-cloud {
              display: grid;
              grid-template-columns: 240px 100px;
              align-items: center;
              .form-group {
                margin-bottom: 0 !important;
              }
              a.btn-style {
                border-color: transparent;
                box-shadow: none;
                color: #b73903;
              }
            }
          }
          .setup-cloud-drive {
            margin: 24px 0 0;
            &.nfs {
              margin-top: 0;
            }
            p {
              margin-bottom: 0;
            }
            .row {
              margin-bottom: 24px;
              &.has-error {
                margin-bottom: 40px;
              }
            }
            .form {
              margin-bottom: 0;
              &.log-form, &.token-form {
                @media screen and (max-width: $screen-xs) {
                  width: 280px;
                  margin: auto;
                }
                p.medium {
                  @media screen and (max-width: $screen-xs) {
                    margin-bottom: 8px;
                  }
                }
                .account-detail {
                  .form-group {
                    .ant-select, .form-control {
                      @media screen and (max-width: $screen-xs) {
                        width: 280px;
                      }
                    }
                  }
                }
              }
              .account-detail {
                max-width: 532px;
                position: relative;
                margin-right: auto;
                .red-error.cloud-name {
                  position: absolute;
                  top: 50px;
                }
                .form-group {
                  margin-bottom: 0;
                }
                &.radio {
                  .form-wrapper {
                    display: grid;
                    grid-template-columns: repeat(2, 48%);
                    align-items: center;
                    column-gap: 12px;
                    margin-bottom: 0;
                    max-height: 250px;
                    overflow: auto;
                    background: $white;
                    border: 1px solid #f9fafb;
                    box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1),
                    0 1px 2px rgba(16, 24, 40, 0.06);
                    border-radius: 10px;
                    padding: 8px 0 8px 8px;
                    &::-webkit-scrollbar {
                      background-color: #fff;
                      width: 16px;
                    }
                    &::-webkit-scrollbar-track {
                      background-color: #fff;
                    }
                    &::-webkit-scrollbar-thumb {
                      background-color: $scroll-bar-color;
                      border-radius: 16px;
                      border: 4px solid #fff;
                    }
                    &::-webkit-scrollbar-button {
                      display: none;
                    }
                  }
                  .form-group {
                    position: relative;
                    margin-bottom: 16px;
                    .account-card {
                      display: grid;
                      align-items: self-start;
                      grid-template-columns: 32px auto;
                      gap: 15px;
                      background: $white;
                      border: 1px solid #eaecf0;
                      border-radius: 8px;
                      padding: 8px 35px 8px 8px;
                      margin-bottom: 0;
                      .content-wrapper {
                        p {
                          margin-bottom: 0;
                          padding: 5px 0 0;
                        }
                      }
                    }
                  }
                }
                &.mounting {
                  .form-wrapper {
                    .form-group {
                      display: inline-block;
                      position: relative;
                      margin-right: 30px;
                      [type="radio"]:checked + label:before,
                      [type="radio"]:not(:checked) + label:before {
                        left: 12px;
                        top: 18px;
                        right: unset;
                      }
                      [type="radio"]:checked + label:after,
                      [type="radio"]:not(:checked) + label:after {
                        top: 21px;
                        left: 15px;
                        right: unset;
                      }
                      .account-card {
                        display: inline-block;
                        background: $white;
                        border: 1px solid #eaecf0;
                        border-radius: 8px;
                        text-align: right;
                        padding: 11px 15px;
                        margin: 0 0 20px;
                        font-weight: 400;
                        &.disabled {
                          background: #f5f5f5;
                          color: #c7c8c7;
                        }
                        &:after,
                        &:before {
                          display: none !important;
                        }
                      }
                      [type="radio"]:checked + .account-card {
                        border-color: #32d583;
                      }
                    }
                  }
                }
                &.backup {
                  .form-wrapper {
                    .form-group {
                      position: relative;
                      display: inline-block;
                      margin-right: 15px;
                      &:last-of-type {
                        margin-right: 0;
                      }
                      [type="radio"]:checked + label:before,
                      [type="radio"]:not(:checked) + label:before {
                        left: 12px;
                        top: 18px;
                        right: unset;
                        display: none !important;
                      }
                      [type="radio"]:checked + label:after,
                      [type="radio"]:not(:checked) + label:after {
                        top: 21px;
                        left: 15px;
                        right: unset;
                        display: none !important;
                      }
                      [type="radio"]:checked + label {
                        background: $primary01;
                        color: #fff;
                      }
                      .account-card {
                        display: inline-block;
                        background: $white;
                        border: 1px solid #eaecf0;
                        border-radius: 8px;
                        text-align: right;
                        padding: 16px 16px 16px 16px;
                        margin: 0 0 20px;
                        font-weight: 400;
                        &.disabled {
                          background: #f5f5f5;
                          color: #c7c8c7;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .left-content {
            margin-right: 255px;
            @media screen and (max-width: $screen-xs) {
              margin-right: 0;
            }
            &.brand-grid-4 {
              .drive-brands {
                .drive-brands-wrapper {
                  @media screen and (max-width: 1199px) and (min-width: 992px) {
                    padding: 210px 0 20px;
                  }
                  @media screen and (max-height: 760px) and (min-height: 601px) {
                    padding: 250px 0 20px;
                  }
                  .free-storage {
                    @media screen and (max-width: 1199px) and (min-width: 992px) {
                      right: unset;
                      left: 21px;
                      top: 69px;
                    }
                  }
                }
              }
            }
            &.brand-grid-5 {
              margin-right: 0;
              .drive-brands {
                .drive-brands-wrapper {
                  .brands {
                    grid-template-columns: repeat(5, calc(20% - 13px));
                    @media screen and (max-width: 991px) and (min-width: 768px) {
                      grid-template-columns: repeat(4, calc(26% - 22px));
                      justify-content: center;
                    }
                    @media screen and (max-width: $screen-xs) {
                      grid-template-columns: repeat(2, calc(51% - 15px));
                      justify-content: center;
                    }
                  }
                }
              }
            }
            .drive-steps {
              border: 1px solid $border-primary01;
              box-shadow: $box-shadow01;
              border-radius: 5px;
              background: #f9fafb;
              padding: 15px 15px;
              margin-bottom: 32px;
              h3 {
                margin-bottom: 0;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
              }
              h3.h3 {
                font-weight: 600;
                margin-bottom: 4px;
                font-size: 20px;
                line-height: 26px;
              }
              &.cloud-backup-instance {
                h3 {
                  margin-bottom: 0 !important;
                }
              }
            }
            .connected-accounts {
              border: 1px solid $border-primary02;
              box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1),
              0 1px 2px rgba(16, 24, 40, 0.06);
              border-radius: 12px;
              margin-bottom: 32px;
              .connected-accounts-header {
                padding: 12px 24px;
                border-top-right-radius: 12px;
                border-top-left-radius: 12px;
                background: $white;
                h3 {
                  margin-bottom: 0;
                }
              }
              .connected-accounts-body {
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
                .table-header {
                  padding: 13px 24px;
                  background: #f9fafb;
                  border-bottom: 1px solid $border-primary02;
                  p, div {
                    font-size: 12px;
                    margin-bottom: 0;
                    color: #4E6174;
                  }
                }
                .table-body {
                  .table-body-card {
                    background: $white;
                    //border-bottom: 1px solid $border-primary02;
                    &:last-of-type {
                      border-bottom-left-radius: 12px;
                      border-bottom-right-radius: 12px;
                    }
                    p {
                      color: $text-primary03;
                      margin-bottom: 0;
                    }
                    .account-detail {
                      display: flex;
                      align-items: center;
                      gap: 12px;
                      .img-wrapper {
                        img {
                          width: 20px;
                        }
                      }
                      .content-wrapper {
                        p.medium {
                          color: $text-primary01;
                        }
                      }
                    }
                    ul.icons {
                      margin-right: 8px;
                      li {
                        &:not(:last-of-type) {
                          margin: 0 28px 0 0;
                        }
                      }
                    }
                  }
                }
              }
            }
            .drive-brands {
              &.connection-drive-brands {
                height: calc(100vh - 111px);
                display: flex;
                align-items: center;
                justify-content: center;
                @media screen and (max-height: 600px) {
                  display: block;
                  padding-bottom: 20px;
                  height: auto;
                }
                @media screen and (max-width: $screen-xs) {
                  height: auto;
                }
                &.not-amove-storage {
                  @media screen and (max-height: 840px) and (min-height: 791px) {
                    padding: 50px 0 0;
                  }
                  @media screen and (max-height: 760px) {
                    display: block;
                    padding-bottom: 20px;
                    height: auto;
                  }
                }
              }
              .search-form {
                max-width: 200px;
                margin: 0 0 0 auto;
              }
              .col-md-7 {
                > h3 {
                  margin-bottom: 4px;
                }
                > p {
                  margin-bottom: 0;
                }
              }
              .drive-brands-wrapper {
                //margin-top: 32px;
                //height: calc(100vh - 155px);
                //display: flex;
                //align-items: center;
                //justify-content: center;
                //overflow: auto;
                width: 100%;
                @media screen and (max-height: 850px) and (min-height: 791px) {
                  padding: 50px 0 20px;
                }
                @media screen and (max-height: 790px) and (min-height: 701px) {
                  padding: 150px 0 20px;
                  &.remove-padding {
                    padding: 20px 0 20px;
                  }
                }
                @media screen and (max-height: 700px) and (min-height: 601px) {
                  padding: 250px 0 20px;
                }
                @media screen and (max-height: 600px) {
                  padding: 70px 0 0;
                }
                @media screen and (max-width: 991px) and (min-width: 768px) {
                  width: 100%;
                  height: calc(100vh - 100px);
                  padding: 0 0 30px;
                  overflow: auto;
                }
                @media screen and (max-width: $screen-xs) {
                  padding: 70px 0 20px;
                  &.remove-padding {
                    padding: 20px 0 20px;
                  }
                }
                .connections-wrapper {
                  display: flex;
                  align-items: center;
                  gap: 20px;
                  position: absolute;
                  top: 20px;
                  @media screen and (max-width: 991px) and (min-width: 768px) {
                    margin-bottom: 30px;
                    position: relative;
                    top: 0;
                  }
                  h3 {
                    margin-bottom: 0;
                  }
                  ul.accounts {
                    li {
                      position: relative;
                      &:not(:last-of-type) {
                        margin: 0 12px 0 0;
                      }
                      span {
                        background: $primary01;
                        color: white;
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        top: -7px;
                        right: -10px;
                        font-size: 11px;
                        font-weight: 600;
                        display: none;
                        &.show {
                          display: flex;
                        }
                      }
                      img {
                        max-width: 20px;
                        width: 20px;
                        &.idrive-icon {
                          max-width: 18px;
                        }
                        &.boxIcon {
                          max-width: 24px;
                          width: 24px;
                        }
                        &.storj-icon {
                          max-width: 22px;
                          width: 22px;
                        }
                        &.wasabi-icons {
                          max-width: 18px;
                          width: 18px;
                        }
                      }
                    }
                  }
                }
                .sub-heading {
                  margin: 0;
                  line-height: 20px;
                }
                .brands-header {
                  display: flex;
                  align-items: center;
                  gap: 30px;
                  margin-bottom: 18px;
                  @media screen and (max-width: 991px) {
                    display: block;
                  }
                  &.highlighted-tour {
                    margin-bottom: 21px;
                    .form {
                      display: none;
                    }
                  }
                  .content-wrapper {
                    .sub-heading {
                      margin: 0;
                      @media screen and (max-width: 991px) {
                        margin: 0 0 12px;
                      }
                    }
                    p {
                      margin: 8px 0 0;
                    }
                  }
                  .form {
                    @media screen and (max-width: 991px) and (min-width: 768px) {
                      max-width: 300px;
                    }
                    .form-group {
                      position: relative;
                      margin-bottom: 0;
                      .form-control {
                        padding: 0 50px 0 14px;
                        font-size: 12px;
                        line-height: 22px;
                        min-width: 250px;
                        height: 34px;
                        @media screen and (max-width: 1199px) and (min-width: 992px) {
                          min-width: 200px;
                        }
                        @media screen and (max-width: 991px) {
                          min-width: 200px;
                        }
                      }
                      .icon {
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        background: #0074D4;
                        height: 100%;
                        width: 45px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 0 8px 8px 0;
                        img {
                          filter: brightness(0) saturate(100%) invert(99%) sepia(7%) saturate(938%) hue-rotate(231deg) brightness(121%) contrast(100%);
                          max-width: 17px;
                        }
                      }
                    }
                  }
                }
                > .sub-heading {
                  margin: 0 0 18px;
                }
                .free-storage {
                  display: flex;
                  align-items: center;
                  justify-content: right;
                  gap: 25px;
                  margin: 0 0 40px;
                  position: absolute;
                  top: 20px;
                  right: 279px;
                  width: 100%;
                  max-width: 535px;
                  z-index: 1;
                  @media screen and (max-width: 991px) and (min-width: 768px) {
                    top: -20px!important;
                  }
                  &.no-flex{
                    display: block;
                  }
                  .sub-heading {
                    font-weight: 400;
                  }
                  @media screen and (max-width: 991px) and (min-width: 768px) {
                    position: relative;
                    top: 0;
                    right: 0;
                    text-align: left;
                    display: block;
                  }
                  &.not-selected {
                    right: 15px;
                    @media screen and (max-width: 991px) and (min-width: 768px) {
                      right: 0;
                    }
                  }
                  @media screen and (max-width: $screen-xs) {
                    position: relative;
                    top: 0;
                    right: 0;
                  }
                  @media screen and (max-width: $screen-xs) {
                    display: block;
                    text-align: center;
                  }
                  .enable-region-wrapper{
                    .new-region-wrapper{
                      display: flex;
                      align-items: center;
                      justify-content: right;
                      gap: 25px;
                      @media screen and (max-width: $screen-xs) {
                        display: block;
                        text-align: center;
                        margin-bottom: 20px;
                      }
                      h3{
                        margin-bottom: 0;
                      }
                    }
                  }
                  .ant-select {
                    max-width: 150px;
                    height: 30px !important;
                    @media screen and (max-width: 991px) {
                      margin: 10px 0 0;
                      text-align: left;
                    }
                    input {
                      min-height: 30px !important;
                      height: 30px !important;
                      font-size: 13px !important;
                      line-height: 18px !important;
                    }
                    .ant-select-selector {
                      min-height: 30px !important;
                      height: 30px !important;
                      .ant-select-selection-item {
                        font-size: 13px;
                        line-height: 18px;
                      }
                    }
                  }
                  .brand-icon {
                    width: 120px;
                    height: 30px;
                    background: $dark-primary02;
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media screen and (max-width: 991px) and (min-width: 768px) {
                      margin: 12px auto 0 0;
                    }
                    @media screen and (max-width: $screen-xs) {
                      margin: 12px auto 0;
                    }
                    img {
                      max-width: 75px;
                    }
                    span {
                      color: $primary01 !important;
                      margin-right: 6px;
                    }
                  }
                }
                .brands-main-wrapper {
                  min-height: 564px;
                  &.not-found {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media screen and (max-width: $screen-xs) {
                      min-height: 200px;
                    }
                  }
                  .no-record {
                    //font-size: 18px;
                    //line-height: 30px;
                  }
                }
                ul.brands {
                  display: grid;
                  grid-template-columns: repeat(4, calc(25% - 12px));
                  justify-content: left;
                  grid-row-gap: 16px;
                  grid-column-gap: 16px;
                  width: 100%;
                  padding: 0 3px;
                  @media screen and (max-width: 991px) {
                    grid-template-columns: repeat(2, calc(50% - 12px));
                  }
                  li {
                    display: none;
                    &.active {
                      display: inline-block;
                    }
                    input {
                      display: none;
                    }
                    input:checked + label {
                      border: 1px solid #ea4903;
                    }
                    label, a {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                      user-select: none;
                      height: 100px;
                      padding: 16px;
                      background: $white;
                      box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1),
                      0 1px 2px rgba(16, 24, 40, 0.06);
                      border-radius: 10px;
                      border: 1px solid #fff;
                      &.text-logo {
                        gap: 10px;
                        position: relative;
                        h3 {
                          margin-bottom: 0;
                          font-weight: 700;
                          font-size: 16px;
                          line-height: 20px;
                          @media screen and (max-width: 1299px) {
                            font-size: 14px;
                            line-height: 20px;
                          }
                        }
                        p.tag {
                          margin-bottom: 0;
                          font-size: 12px;
                          line-height: 18px;
                          font-weight: 600;
                          position: absolute;
                          bottom: 6px;
                          right: 15px;
                        }
                      }
                      span {
                        color: #000 !important;
                        font-size: 18px;
                        font-weight: 600;
                      }
                      img {
                        max-width: 170px;
                        object-fit: contain;
                        width: 100%;
                        @media screen and (max-width: 991px) {
                          width: calc(100% - 20px) !important;
                        }
                        @media screen and (max-width: $screen-xs) {
                          width: 100% !important;
                        }
                      }
                    }
                    a {
                      span {
                        color: #000000 !important;
                        font-size: 18px;
                        font-weight: 600;
                      }
                    }
                    &:first-of-type {
                      label {
                        img {
                          max-width: 50px;
                        }
                      }
                    }
                    &:nth-of-type(2) {
                      label {
                        img {
                          max-width: 100px;
                        }
                      }
                    }
                    &:nth-of-type(4) {
                      label {
                        img {
                          max-width: 140px;
                        }
                      }
                    }
                    &:nth-of-type(5) {
                      label {
                        img {
                          max-width: 140px;
                          @media screen and (max-width: $screen-xs) {
                            max-width: 100px;
                          }
                        }
                      }
                    }
                  }
                }
                .red-error {
                  position: absolute;
                  left: 0;
                  top: 56px;
                  @media screen and (max-width: 991px) {
                    position: relative;
                    left: 0;
                    top: 10px;
                  }
                }
              }
            }
          }
          .right-content {
            &.no-connection {
              .cloud-right-bar {
                display: none;
              }
            }
            .cloud-right-bar {
              width: 255px;
              height: calc(100vh - 62px);
              overflow: auto;
              position: fixed;
              bottom: 0;
              right: 0;
              z-index: 2;
              display: flex;
              align-items: center;
              border-left: 1px solid #d0d5dd;
              background: $white;
              padding: 24px 8px 24px;
              overflow-x: hidden;
              overflow-y: scroll;
              scrollbar-gutter: stable both-edges;
              @media screen and (max-height: 740px) {
                display: grid;
              }
              @media screen and (max-width: $screen-xs) {
                position: relative;
                margin: 30px auto 0;
                border: none;
                height: auto;
              }
              &.cloud-right-bar {
                overflow: unset;
                justify-content: center;
              }
              &::-webkit-scrollbar {
                background-color: #fff;
                width: 16px;
              }
              &::-webkit-scrollbar-track {
                background-color: #fff;
              }
              &::-webkit-scrollbar-thumb {
                background-color: $scroll-bar-color;
                border-radius: 16px;
                border: 4px solid #fff;
              }
              &::-webkit-scrollbar-button {
                display: none;
              }
              &::-webkit-scrollbar {
                background-color: $dark-primary01;
                width: 8px;
              }
              &::-webkit-scrollbar-track {
                background-color: $dark-primary01;
              }
              &::-webkit-scrollbar-thumb {
                background-color: $scroll-bar-color;
                border-radius: 16px;
                border: 2px solid $dark-primary01;
              }
              .no-data {
                text-align: center;
                display: none;
                img {
                  max-width: 80px;
                }
                &.active {
                  display: block;
                  width: 100%;
                }
                p {
                  margin: 20px 0 0;
                }
                .btn-style {
                  margin-top: 30px;
                }
              }
              .selected-brand {
                display: none;
                &.active {
                  display: block;
                }
                .brand-header {
                  display: flex;
                  align-items: center;
                  gap: 16px;
                  margin-bottom: 24px;
                  .content-wrapper {
                    h3 {
                      margin-bottom: 0;
                    }
                  }
                }
                .brand-body {
                  > h3 {
                    font-size: 13px;
                    text-align: center;
                    margin: 12px 0 15px;
                  }
                  .brand-header-image {
                    // background: url("../images/cloud-bg-min.jpg") no-repeat center center;
                    background-size: cover;
                    padding: 0;
                    border-radius: 8px;
                    .wrapper {
                      width: 100%;
                      padding: 20px;
                      //box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
                      box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1),
                      0 1px 2px rgba(16, 24, 40, 0.06);
                      border-radius: 10px;
                      border: 1px solid #d9d9d9;
                      background: #fff;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      min-height: 100px;
                      span {
                        color: #000 !important;
                        font-size: 18px;
                        font-weight: 600;
                      }
                      h3 {
                        margin: 0 0 0 8px;
                        font-size: 14px;
                        line-height: 20px;
                        font-weight: 700;
                      }
                      img {
                        display: inline-block;
                      }
                    }
                  }
                  .form {
                    .form-group {
                      position: relative;
                      .form-control {
                        font-size: 12px;
                        width: 220px;
                        text-align: center;
                      }
                      .file-upload-btn {
                        width: 100%;
                      }
                      .info-main-wrapper {
                        display: flex;
                        align-items: self-start;
                        gap: 5px;
                        margin: 20px 0 0;
                        .ant-checkbox-wrapper {
                          gap: 8px;
                          &:after {
                            display: none;
                          }
                          span {
                            padding: 0 !important;
                          }
                          &:hover {
                            .ant-checkbox {
                              &:after {
                                border-color: $primary01;
                              }
                            }
                          }
                          .ant-checkbox {
                            &.ant-checkbox-checked {
                              .ant-checkbox-inner {
                                background: $primary01;
                                border-color: $primary01;
                              }
                            }
                            .ant-checkbox-inner {
                              background: $white;
                            }
                          }
                        }
                        .info-wrapper {
                          .info-icon {
                            max-width: 13px;
                            margin: 0 0 0 auto;
                          }
                        }
                      }
                      .ant-radio-group {
                        label.ant-radio-wrapper {
                          span {
                            font-size: 13px;
                          }
                        }
                      }
                      &.submit {
                        text-align: center;
                        margin: 0;
                      }
                      .btn-style {
                        background: $primary01;
                        border-color: $primary01;
                        span {
                          color: $white;
                        }
                      }
                      .icon {
                        position: absolute;
                        right: 15px;
                        top: 50%;
                        transform: translateY(-50%);
                      }
                    }
                    .red-error {
                      white-space: pre-wrap;
                      text-align: center;
                      display: block;
                      max-width: 220px;
                      margin: auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.home {
        min-height: calc(100vh - 120px);
        .user-account-option {
          margin: 15px 0 15px;
          .user-account-card {
            background: $white;
            border: 1px solid $border-primary01;
            box-shadow: 0 1px 3px rgb(16 24 40 / 10%),
            0 1px 2px rgb(16 24 40 / 6%);
            border-radius: 8px;
            padding: 18px 17px 16px;
            display: flex;
            align-items: center;
            grid-gap: 12px;
            padding-right: 50px;
            position: relative;
            @media screen and (max-width: $screen-xs) {
              margin-bottom: 20px;
            }
            &:after {
              content: "+";
              position: absolute;
              top: 0;
              right: 15px;
              font-size: 25px;
              line-height: 25px;
              color: $text-primary01;
              bottom: 0;
              margin: auto;
              height: 27px;
            }
            .img-wrapper {
              img {
                width: 25px;
                height: 25px;
              }
            }
            .content-wrapper {
              h3 {
                margin-bottom: 0;
                font-size: 15px;
                line-height: 24px;
              }
            }
          }
        }
      }
    }
  }
}
#left-sidebar-tooltip-wraper {
  position: fixed;
  bottom: 100px;
  left: 27px;
  z-index: 999;
  &.active {
    left: 137px;
  }
  .ant-tooltip {
    z-index: 999;
    max-width: 360px;
    width: 360px;
    top: -205px !important;
    left: 52px !important;
    @media screen and (max-width: $screen-xs) {
      max-width: 320px;
    }
    .ant-tooltip-arrow {
      display: none;
    }
    .ant-tooltip-content {
      .ant-tooltip-inner {
        border-radius: 8px;
        background: #FFF;
        border: none;
        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
        padding: 15px 0 0;
        strong {
          font-size: 15px;
          line-height: 22px;
          font-weight: 600;
          color: rgb(29, 28, 29);
          &.title {
            padding-left: 15px;
          }
        }
        ul.view-list {
          margin: 15px 0 0;
          li {
            display: block;
            &:not(:last-of-type) {
              margin-bottom: 0;
            }
            a {
              text-align: left;
              display: grid;
              grid-template-columns: 40px auto;
              align-items: center;
              column-gap: 10px;
              transition: all .3s ease-in-out;
              padding: 10px 15px 7px;
              &:hover {
                background: #F4F4F4;
              }
              .icon {
                width: 40px;
                height: 40px;
                border-radius: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(151deg) brightness(102%) contrast(101%) !important;
                }
                &.purple {
                  background: $primary01;
                }
                &.green {
                  background: $blue;
                }
                &.blue {
                  background: $purple;
                }
                &.gray {
                  background: $lime;
                  img {
                    filter: unset !important;
                  }
                }
                img {
                  max-width: 18px;
                }
              }
              .content {
                p {
                  margin: 0;
                  font-size: 13px;
                  line-height: 20px;
                  color: rgb(51, 49, 51);
                }
              }
            }
            /*&:last-of-type {
              a {
                border-top: 1px solid #E2E2E2;
                margin-top: 15px;
                img {
                  filter: unset !important;
                }
              }
            }*/
          }
        }
      }
    }
  }
}
// *******************************************   Subscription Page   *******************************************
.subscription {
  height: 100vh;
  background: #FFFFFF;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  &.subscription-page-wrapper {
    position: relative;
    display: block;
    padding: 150px 0 40px;
    @media screen and (max-width: $screen-xs) {
      padding: 100px 0 20px;
    }
    .logo {
      text-align: center;
      margin-bottom: 50px;
    }
    .message-wrapper {
      text-align: center;
      margin-bottom: 30px;
    }
  }
  .subscription-wrapper {
    padding: 30px 0;
    margin: auto;
    position: relative;
    height: 425px;
    width: 100% !important;
    @media screen and (max-width: $screen-xs) {
      //padding: 100px 0 30px;
      height: auto;
    }
    .logo {
      margin-bottom: 100px;
      @media screen and (max-width: $screen-xs) {
        margin-bottom: 50px;
      }
    }
    .sub-link {
      color: #0074D4 !important;
      text-decoration: underline;
    }
    .sub-link:hover {
      color: #0074D4 !important;
      text-decoration: none;
    }
    .tabs-wrapper {
      //max-width: 300px;
      max-width: 1200px;
      margin: auto;
      .tabs-pricing-wrapper {
        position: relative;
        display: grid;
        grid-template-columns: repeat(1, 350px);
        justify-content: space-evenly;
        column-gap: 80px;
        @media screen and (max-width: $screen-xs) {
          column-gap: 0;
          row-gap: 50px;
          grid-template-columns: repeat(1, auto);
        }
        // &:before {
        //   content: "";
        //   background: rgba(26, 26, 26, 0.3);
        //   height: 250px;
        //   width: 1px;
        //   position: absolute;
        //   left: 50%;
        //   top: 50%;
        //   transform: translateX(-50%) translateY(-50%);
        //   @media screen and (max-width: $screen-xs) {
        //     height: 1px;
        //     width: 80px;
        //     top: calc(50% - 7px);
        //     transform: translateX(-50%) translateY(-50%);
        //   }
        // }
        .pricing-wrapper {
          > h3 {
            margin-bottom: 15px;
            text-align: center;
            font-size: 22px;
            line-height: 32px;
          }
        }
        .ant-tabs-content-holder {
          min-height: auto;
        }
      }
    }
    .ant-tabs {
      .ant-tabs-nav {
        margin: 0 0 30px;
        align-self: center;
        .ant-tabs-nav-wrap {
          .ant-tabs-nav-list {
            border: 2px solid #0074d4;
            border-radius: 10px;
            background: #FDFAFA;
            .ant-tabs-ink-bar {
              display: none;
            }
            .ant-tabs-tab {
              background: no-repeat;
              border: none;
              outline: none;
              box-shadow: none;
              padding: 0 0;
              .ant-tabs-tab-btn {
                position: relative;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                min-width: 172px;
                height: 44px;
                padding: 10px 18px 10px;
                border-radius: 8px;
                border: none;
                text-decoration: none;
                background: transparent;
                transition: all 0.3s ease-in-out;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: #0074d4;
              }
              &.ant-tabs-tab-active {
                outline: 4px solid rgb(0 116 212 / 20%);
                border-radius: 8px;
                .ant-tabs-tab-btn {
                  background: #0074d4;
                  color: #fff !important;
                }
              }
            }
          }
        }
      }
      .ant-tabs-content-holder {
        min-height: 360px;
        .ant-tabs-content {
          .subscription-body {
            span.tag {
              background: #531A01;
              color: white;
              padding: 3px 10px 2px;
              border-radius: 5px;
              text-transform: uppercase;
              font-size: 12px;
              line-height: 20px;
            }
            > h3 {
              margin: 0 0 60px;
            }
            .logo {
              display: flex;
              justify-content: center;
              margin: 40px 0 40px;
            }
            .price {
              display: flex;
              align-items: center;
              flex-direction: column;
              justify-content: center;
              margin: 0 0 15px;
              strong {
                font-size: 32px;
                line-height: 50px;
                font-weight: 600;
                color: black;
                margin-right: 5px;
              }
              > span, > div > span {
                font-size: 11px;
                line-height: 15px;
              }
            }
            .btn-style {
              cursor: pointer !important;
            }
          }
        }
      }
    }
  }
}
// *******************************************   Subscription Page   *******************************************
// *******************************************   Keyframes   *******************************************
@keyframes heartbit {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  25% {
    transform: scale(0.1);
    opacity: 0.1;
  }
  50% {
    transform: scale(0.5);
    opacity: 0.3;
  }
  75% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
// *******************************************   Global Scss   *******************************************
.login-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  background: $white;
  padding: 25px 32px;
  p {
    margin-bottom: 0;
  }
  .mail {
    position: relative;
    &:before {
      content: "\f0e0";
      font-family: "Font Awesome 6 Free";
      display: inline-block;
      font-size: 14px;
      font-weight: 900;
      color: $text-primary03;
      position: absolute;
      left: -22px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .col-md-6 {
    &:first-of-type {
      text-align: left;
    }
    &:last-of-type {
      text-align: right;
    }
  }
}
.search-form {
  margin: 0;
  padding-left: 80px;
  max-width: 300px;
  .form-group {
    margin-bottom: 0;
    position: relative;
    i {
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 15px;
      color: $text-primary04;
    }
    .form-control {
      padding: 0 0 0 42px;
      height: 34px;
    }
  }
}
.icon-circle {
  width: 46px;
  height: 46px;
  background: #fec1a7;
  border: 6px solid #fee0d3;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  &.small {
    width: 35px;
    height: 35px;
    img {
      max-width: 13px;
    }
  }
}
.help-circle {
  position: relative;
  .form-control {
    padding-right: 40px;
    border: 1px solid #32d583 !important;
    &:focus {
      border: 1px solid #32d583 !important;
    }
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05) !important;
  }
  &:before {
    content: "\f00c";
    font-family: "Font Awesome 6 Free";
    display: none;
    font-size: 15px;
    font-weight: 900;
    color: #32d583;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }
}
.next-step {
  padding: 20px 16px;
  margin-top: 50px;
}
.radio-button {
  .form-group {
    [type="radio"] {
      display: none;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      content: "";
      position: absolute;
      right: 8px;
      top: 8px;
      width: 18px;
      height: 18px;
      border: 1px solid #ddd;
      border-radius: 100%;
      background: #fff;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      content: "";
      width: 12px;
      height: 12px;
      background: #fc6524;
      position: absolute;
      top: 11px;
      right: 11px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      transform: scale(0);
    }
    [type="radio"]:checked + label:after {
      opacity: 1;
      transform: scale(1);
    }
  }
}
.tour-overlay {
  background: rgba(50, 58, 72, .85);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.tour-parent {
  position: relative !important;
  display: inline-flex !important;
  .form-control {
    width: 100%;
  }
}
.tour-tooltip {
  border: 1px solid #6798CD;
  padding: 15px 25px 15px 20px;
  border-radius: 6px;
  background: #F1FAFE;
  //width: 100%;
  width: 430px;
  position: absolute;
  z-index: 3;
  display: none;
  &.active {
    display: block;
  }
  &.tour-create-drive {
    width: 500px;
    @media screen and (max-width: $screen-xs) {
      width: calc(100% - 20px);
      max-width: 500px;
    }
    .tour-tooltip-wrapper {
      .launch-amove {
        p {
          margin: 0 !important;
          &.bold {
            margin: 20px 0 0 !important;
          }
        }
        .btn-wrapper {
          margin: 20px 0 0;
          .btn-tour {
            padding: 6px 28px 6px;
            height: 36px;
          }
        }
      }
      .amove-connectivity {
        p {
          margin: 0 !important;
          &.bold {
            margin: 0 0 20px !important;
          }
        }
      }
    }
  }
  &.tour-step-1 {
    left: 154px;
    top: -18px;
    width: 420px;
    padding: 15px 25px 15px 20px;
    @media screen and (max-width: $screen-xs) {
      left: 0;
      top: 52px;
      width: 317px;
    }
    &:before {
      @media screen and (max-width: $screen-xs) {
        top: -6px;
        left: 45px;
        transform: rotate(45deg);
      }
    }
    .tour-tooltip-wrapper {
      p {
        @media screen and (max-width: $screen-xs) {
          br {
            display: none;
          }
        }
      }
    }
  }
  &.tour-step-2 {
    right: 22px;
    top: 56px;
    padding: 15px 25px 15px 20px;
    /*@media screen and (max-height: 850px) {
      bottom: 56px;
      top: unset;
    }*/
    &:before {
      top: -8px;
      right: 44px;
      left: unset;
      transform: rotate(45deg);
    }
  }
  &.tour-add-user {
    left: 65px;
    bottom: 73px;
    top: unset;
    z-index: 99999;
    position: fixed;
    padding: 15px 25px 15px 20px;
    transition: all 0.3s ease-in-out;
    &.sidebar-opened {
      left: 150px;
    }
    @media screen and (max-width: $screen-xs) {
      max-width: 300px;
      bottom: 54px;
    }
    &:before {
      top: 15px;
      transform: rotate(315deg);
      left: -8px;
      right: auto;
      bottom: 30px;
    }
    &.desktop-app {
      bottom: 15px;
      &:before {
        bottom: 20px;
      }
    }
  }
  &.tour-show-drive {
    left: 115px;
    top: 6px;
    z-index: 2;
    padding: 15px 25px 15px 20px;
    &:before {
      top: auto;
      right: auto;
      left: 15px;
      bottom: -8px;
      transform: rotate(-134deg);
    }
  }
  &.tour-show-drive-2 {
    left: 20px;
    top: 90px;
    z-index: 2;
    padding: 15px 25px 15px 20px;
    width: 485px;
    &:before {
      bottom: auto;
      right: auto;
      left: 30px;
      top: -8px;
      transform: rotate(45deg);
    }
  }
  &.tour-step-3 {
    right: 110px;
    top: 51px;
    padding: 15px 25px 15px 20px;
    @media screen and (max-width: 1199px) and (min-width: 992px) {
      right: 80px;
    }
    /*  @media screen and (max-height: 850px) {
        bottom: 56px;
        top: unset;
      }*/
    &:before {
      top: -8px;
      right: 44px;
      left: unset;
      transform: rotate(45deg);
    }
  }
  &.tour-step-4 {
    right: -12px;
    top: 51px;
    padding: 15px 25px 15px 20px;
    /*@media screen and (max-height: 850px) {
      bottom: 56px;
      top: unset;
    }*/
    &:before {
      top: -8px;
      right: 44px;
      left: unset;
      transform: rotate(45deg);
    }
  }
  &.tour-step-5 {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    @media screen and (max-width: $screen-xs) {
    }
    &:before {
      display: none;
    }
  }
  // Connection page
  &.tour-connection-step-1 {
    right: 237px;
    top: 0;
    &:before {
      transform: rotate(135deg);
      left: unset;
      right: -8px;
    }
  }
  &.tour-connection-step-2 {
    right: 237px;
    top: -26px;
    &:before {
      transform: rotate(135deg);
      left: unset;
      right: -8px;
    }
  }
  // Sync Page
  &.tour-sync-step-1 {
    top: 0;
    left: 220px;
  }
  &.tour-sync-step-2 {
    left: 105px;
    top: 75px;
    &:before {
      top: -8px;
      right: 44px;
      left: unset;
      transform: rotate(45deg);
    }
  }
  &.tour-sync-step-3 {
    left: 105px;
    top: 75px;
    &:before {
      top: -7px;
      right: 44px;
      left: unset;
      transform: rotate(45deg);
    }
  }
  &.tour-sync-step-4 {
    left: 105px;
    bottom: 75px;
    &:before {
      top: unset;
      bottom: -7px;
      right: 44px;
      left: unset;
      transform: rotate(225deg);
    }
  }
  &.tour-sync-step-5 {
    left: 30px;
    bottom: 60px;
    &:before {
      top: unset;
      bottom: -7px;
      left: 44px;
      transform: rotate(225deg);
    }
  }
  &:before {
    content: "";
    position: absolute;
    top: 39px;
    left: -8px;
    width: 15px;
    height: 15px;
    border-style: solid;
    border-width: 1px !important;
    border-color: #6798CD transparent transparent #6798CD;
    transform: rotate(315deg);
    background: #F1FAFE;
  }
  .tour-tooltip-wrapper {
    a.cross {
      position: absolute;
      right: 9px;
      top: 9px;
      img {
        max-width: 13px;
        filter: brightness(0) saturate(100%) invert(0%) sepia(85%) saturate(7500%) hue-rotate(281deg) brightness(87%) contrast(115%) !important;
      }
    }
    h3 {
      margin-bottom: 0;
      color: $black !important;
      font-size: 18px;
      line-height: normal;
      font-weight: 700;
      text-align: left;
    }
    ul.steps {
      display: grid;
      grid-template-columns: repeat(3, 33%);
      column-gap: 2.3px;
      margin: 8px 0 0;
      max-width: 361px;
      &.full {
        display: flex;
        li {
          width: 100%;
        }
      }
      li {
        &.active {
          span {
            background: #0074D4;
          }
        }
        span {
          display: block;
          width: 100%;
          background: #C3DCF0;
          height: 4px;
        }
      }
    }
    p {
      margin: 8px 0 19px !important;
      color: $black !important;
      text-align: left !important;
      white-space: wrap !important;
      padding: 0 !important;
      font-size: 14px;
      line-height: 18px;
      font-weight: 500 !important;
      &.p {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500 !important;
        color: #50565F !important;
        margin: 8px 0 0 !important;
        a {
          font-size: 14px;
          line-height: 18px;
          font-weight: 500 !important;
          color: #50565F !important;
          text-decoration: underline;
        }
      }
      &.mb-0 {
        margin-bottom: 0 !important;
      }
      &.black {
        color: $black !important;
      }
      &.bold {
        font-weight: 700 !important;
      }
    }
    .btn-wrapper {
      display: flex;
      align-items: center;
      gap: 12px;
      .btn-tour {
        background: #0074D4;
        color: $white !important;
        border: 1px solid #0074D4;
        height: 30px;
        font-weight: 400;
        border-radius: 3px;
        padding: 5px 10px 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.fix-width {
          padding: 6px 7px 6px !important;
          width: 155px !important;
        }
        &:hover {
          background: #09487d;
        }
        &.transparent {
          background: transparent;
          color: #0074D4 !important;
        }
        &.orange {
          background: #EA4903 !important;
          border-color: #EA4903 !important;
        }
      }
    }
  }
}
.standard-user-tour {
  .tour-overlay {
    z-index: 999;
  }
  .tour-tooltip {
    z-index: 1000;
  }
}
.highlighted-tour {
  position: relative;
  z-index: 2;
  .choose-cloud-card {
    border-color: #ACACAC !important;
    .content-wrapper {
      border-color: #ACACAC !important;
    }
  }
}
.connected-accounts {
  border: 1px solid $border-primary02;
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  margin: 0 0 20px;
  user-select: none;
  overflow: hidden;
  position: relative;
  @media screen and (max-width: $screen-xs) {
    margin: 20px 0 20px;
  }
  &.tour-area {
    position: relative;
    z-index: 2;
    overflow: unset;
    background: #fff;
    .buckets-list-wrapper {
      .buckets-list {
        .buckets-wrapper {
          min-height: 300px !important;
          .row {
            border-bottom: 1px solid #BCC7D2;
          }
        }
      }
    }
  }
  &:first-of-type {
    margin-top: 0px;
    @media screen and (max-width: $screen-xs) {
      margin-top: 10px;
    }
  }
  &.hide-arrow-icon {
    .ant-collapse-expand-icon {
      display: none !important;
    }
  }
  .ant-collapse {
    &.connected-accounts-body-collapse {
      .ant-collapse-item {
        .ant-collapse-content {
          @media screen and (max-width: 991px) {
            overflow: auto;
          }
          .connected-accounts-body {
            @media screen and (max-width: 991px) {
              min-width: 830px;
            }
          }
        }
      }
    }
    .ant-collapse-item {
      &.ant-collapse-item-active {
        .ant-collapse-header {
          border-radius: 12px;
          .ant-collapse-expand-icon {
            span {
              svg {
                transform: rotate(-90deg) !important;
              }
            }
          }
        }
      }
      .ant-collapse-header {
        background: white;
        padding: 0;
        line-height: 0;
        border: none;
        border-radius: 12px;
        .ant-collapse-expand-icon {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          span {
            svg {
              transform: rotate(90deg);
              width: 17px;
              height: 17px;
            }
          }
        }
      }
      .ant-collapse-content {
        border: none;
        .ant-collapse-content-box {
          padding: 0;
          border: none;
        }
      }
    }
  }
  .connected-accounts-header {
    padding: 12px 24px;
    border-radius: 12px;
    background: $white;
    display: flex;
    align-items: center;
    ul.login-accounts {
      display: flex;
      align-items: center;
      margin-left: 60px;
      transition: .5s;
      @media screen and (max-width: 991px) {
        display: none;
      }
      .backblaze-icon {
        width: 18px;
        height: 24px;
      }
      .frameio-icon {
        height: 18px;
      }
      li {
        position: relative;
        height: 30px;
        align-items: center;
        display: none;
        &.show {
          display: inline-flex;
        }
        &:not(:last-of-type) {
          margin: 0 20px 0 0;
        }
        span {
          background: $primary01;
          color: white;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: -7px;
          right: -10px;
          font-size: 12px;
          font-weight: 600;
          display: none;
          &.show {
            display: flex;
          }
        }
        img {
          max-width: 20px;
          width: 20px;
          &.dropbox {
            width: 24px;
            height: 24px;
            max-width: 24px;
          }
          &.onedriveIcon {
            width: 24px;
            height: 24px;
            max-width: 24px;
          }
          &.larger {
            max-width: 25px;
          }
          &.boxIcon {
            max-width: 24px;
            width: 24px;
          }
          &.storj-icon {
            max-width: 22px;
            width: 22px;
          }
          &.googledriveIcon {
            max-width: 22px;
            width: 22px;
          }
        }
      }
    }
    h3 {
      margin-bottom: 0;
      min-width: 240px;
      img {
        max-width: 30px;
        border-radius: 50%;
      }
    }
  }
  .ant-collapse-content-box {
    @media screen and (max-width: 991px) {
      overflow: auto;
    }
  }
  .open-drive-btn-title {
    margin-left: 44px !important;
  }
  .connected-accounts-body {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    @media screen and (max-width: 991px) {
      min-width: 680px;
    }
    .table-header {
      padding: 13px 24px;
      background: #f9fafb;
      border-bottom: 1px solid $border-primary02;
      p, div {
        font-size: 12px;
        margin-bottom: 0;
        color: #4E6174;
      }
    }
    .table-body {
      .table-body-card {
        padding: 0 24px;
        background: $white;
        border-bottom: 1px solid $border-primary02;
        @media screen and (max-width: $screen-xs) {
          padding: 0 12px;
        }
        > .row {
          padding: 16px 0;
        }
        .open-drive-btn {
          margin-left: 0 !important;
          display: inline-block;
          line-height: 1;
          text-align: center;
        }
        &:last-of-type {
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
          border-bottom: 0;
        }
        .ant-popover-placement-topLeft, .ant-popover-placement-top, .ant-popover-placement-topRight {
          padding-bottom: 8px;
        }
        .ant-btn-primary {
          box-shadow: none;
        }
        p, p.medium {
          color: #4E6174;
          margin-bottom: 0;
          font-weight: 400;
          white-space: nowrap;
          .btn-style {
            height: 22px;
            padding: 12px 14px 12px 14px;
            font-size: 12px;
            background: $blue !important;
            border-color: $blue !important;
            color: #fff !important;
            &:hover {
              background: $blue !important;
              border-color: $blue !important;
              color: #fff !important;
            }
            &.active {
              border-color: $blue !important;
              &:hover {
                border-color: $blue !important;
              }
            }
          }
        }
        .form-group {
          justify-content: center;
          position: relative;
          margin-bottom: 0;
          padding: 5px 0;
          display: flex;
          gap: 16px;
          .form-control {
            padding: 0 14px 0 14px;
            height: 26px;
            width: calc(100% - 90px);
            font-size: 12px;
            line-height: 12px;
          }
          .icon {
            position: relative;
            background: #0074D4;
            width: 34px;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            color: #fff;
            &.disabled-s {
              visibility: hidden;
            }
            img {
              filter: brightness(0) saturate(100%) invert(99%) sepia(7%) saturate(938%) hue-rotate(231deg) brightness(121%) contrast(100%);
              max-width: 17px;
            }
          }
          .red-error {
            position: absolute;
            bottom: -30px;
            display: block;
            top: auto;
            background: #fff;
            color: red !important;
            padding: 5px 10px;
            border-radius: 4px;
            white-space: nowrap;
            right: auto;
            left: auto;
            @media screen and (max-width: 1400px) {
              right: 0;
              z-index: 9;
            }
          }
        }
        .account-detail {
          display: flex;
          align-items: center;
          gap: 10px;
          position: relative;
          &.centered {
            justify-content: center;
          }
          @media screen and (max-width: $screen-xs) {
            gap: 8px;
          }
          .img-wrapper {
            width: 16px;
            align-items: start;
            display: flex;
            @media screen and (max-width: $screen-xs) {
              //width: 22px;
            }
            img {
              width: 16px;
              &.backblaze-icon {
                width: 15px !important;
                position: relative;
                top: -2px;
                left: 1px;
              }
              &.dropbox {
                width: 22px;
                height: 16px;
              }
              @media screen and (max-width: $screen-xs) {
                //width: 22px;
              }
              &.larger {
                width: 20px;
              }
              &.boxIcon, &.storj-icon {
                max-width: 20px;
                width: 20px;
              }
            }
          }
          .content-wrapper {
            p.medium {
              color: #271E1B;
              position: relative;
              @media screen and (max-width: $screen-xs) {
                font-size: 13px;
                line-height: 20px;
              }
            }
            span.counter {
              background: $primary01;
              color: $white;
              width: 15px;
              height: 15px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: -7px;
              right: -10px;
              font-size: 12px;
              font-weight: 600;
              border: 0;
            }
          }
        }
        ul.icons {
          text-align: right;
          margin-right: 8px;
          li {
            &:not(:last-of-type) {
              margin: 0 28px 0 0;
            }
            &.delete-wrapper {
              img {
                max-width: 16px;
              }
            }
            .ant-switch {
              height: 15px;
              min-width: 35px;
              &.ant-switch-checked {
                .ant-switch-handle {
                  height: 11px;
                  width: 11px;
                  left: unset;
                  right: 9px;
                  &:before {
                    height: 11px;
                    width: 11px;
                    left: unset;
                    right: -6px;
                  }
                }
              }
              .ant-switch-handle {
                height: 11px;
                width: 11px;
                left: -3px;
                &:before {
                  height: 11px;
                  width: 11px;
                  left: unset;
                  right: -6px;
                }
              }
            }
          }
        }
        .ant-popover-arrow {
          display: none;
        }
        .refresh-bucket {
          .ant-tooltip-inner {
            width: 228px;
          }
          img {
            width: 15px;
            cursor: pointer;
            transform: scalex(-1);
            user-select: none;
          }
        }
      }
    }
  }
}
.step-counter {
  margin: 0 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $border-primary02;
  box-shadow: inset 0 1px 2px #dbdbdb;
  border-radius: 500px;
  max-width: 150px;
  padding: 6px 8px;
  li {
    width: 25px;
    height: 25px;
    background: #98a2b3;
    box-shadow: 0 0 0 4px #f2f4f7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &.check {
      position: relative;
      background: #039855;
      box-shadow: 0 0 0 4px rgba(3, 152, 85, 0.2);
      p {
        display: none;
      }
      &:before {
        content: "\f00c";
        font-family: "Font Awesome 6 Free";
        display: inline-block;
        font-size: 15px;
        font-weight: 900;
        color: #ffffff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
      }
    }
    &.active {
      background: #fc6524;
      box-shadow: 0 0 0 4px #fff0e9;
    }
    p {
      margin-bottom: 0;
    }
  }
}
.counter {
  width: 40px;
  height: 40px;
  border: 1px solid #eaecf0;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: $screen-xs) {
    width: 35px;
    height: 35px;
  }
  span {
    color: $text-primary02;
  }
  &.active {
    position: relative;
    background: $green;
    span {
      display: none;
    }
    &:before {
      content: "\f00c";
      font-family: "Font Awesome 6 Free";
      display: inline-block;
      font-size: 15px;
      font-weight: 900;
      color: $white;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }
}
.ant-notification-notice
.ant-notification-notice-with-icon
.ant-notification-notice-message,
.ant-notification-notice
.ant-notification-notice-with-icon
.ant-notification-notice-description {
  margin-inline-start: 60px;
}
.ant-notification {
  .ant-notification-notice {
    padding: 18px 18px;
    .ant-notification-notice-with-icon {
      .ant-notification-notice-icon {
        line-height: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 30px;
        img {
        }
      }
      .ant-notification-notice-message {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $text-primary01;
        margin-bottom: 4px;
      }
      .ant-notification-notice-description {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #475467;
      }
    }
    .ant-notification-notice-close {
      top: 2px;
      outline: none;
      width: 26px;
      height: 26px;
      right: 2px;
      background: none !important;
      span.ant-notification-close-x {
        display: flex;
        align-items: center;
        svg {
          color: #000 !important;
        }
      }
    }
  }
}
.disabled {
  user-select: none;
  cursor: not-allowed;
  &.opacity {
    opacity: 0.5;
  }
}
.ant-collapse
> .ant-collapse-item {
  border-bottom: 0 !important;
}
.ant-notification {
  @media screen and (max-width: 991px) {
    z-index: 99999;
  }
  img {
    max-width: 32px;
    display: block;
    width: 32px;
  }
}
.file-upload-btn {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  padding: 8px 14px;
  background: $white;
  border: 1px solid $border-primary01;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
}
.ant-modal-root {
  .ant-modal-mask {
    // Background Layer
    background: rgba(52, 64, 84, 0.7);
    backdrop-filter: blur(8px);
    //z-index: 10001;
  }
  .ant-modal-wrap {
    // z-index: 10002;
    .ant-modal {
      &.file-path-modal {
        max-width: 640px;
        width: 100%;
        .ant-modal-content {
          padding: 0 0;
          background: #ffffff;
          box-shadow: 0 20px 24px -4px rgba(16, 24, 40, 0.08),
          0px 8px 8px -4px rgba(16, 24, 40, 0.03);
          border-radius: 12px;
          button.ant-modal-close {
            display: none;
          }
          .ant-modal-header {
            display: none;
          }
          .ant-modal-body {
            padding: 0;
            .form {
              width: 100%;
              margin: auto;
              .windows-dir-wrapper {
                padding-top: 40px;
              }
              .form-group {
                .form-control {
                  border-radius: 0;
                  border: none;
                  border-bottom: 1px solid #eaecf0;
                  border-top-right-radius: 12px;
                  border-top-left-radius: 12px;
                  height: 56px;
                  box-shadow: none;
                  font-size: 14px;
                }
              }
            }
            .modal-body-wrapper {
              padding: 15px;
              background: #e3e3e3;
              padding-top: 0;
              .select-drive-wrapper {
                padding: 16px 18px;
                position: relative;
                min-height: 380px;
                background: #fff;
                border: 1px solid $light-black;
                margin-bottom: 70px;
                &.mac-os {
                  padding: 0 18px 0;
                }
                &:before {
                  content: "";
                  background: #eaecf0;
                  width: 80px;
                  height: 100%;
                  position: absolute;
                  left: 0;
                  top: 0;
                  z-index: 1;
                }
                .col-md-3 {
                  width: 80px;
                  position: relative;
                  z-index: 2;
                  padding-left: 5px;
                  padding-right: 15px;
                  .form {
                    height: 425px;
                    overflow: auto;
                    &::-webkit-scrollbar {
                      background-color: #eaecf0;
                      width: 14px;
                    }
                    &::-webkit-scrollbar-track {
                      background-color: #eaecf0;
                    }
                    &::-webkit-scrollbar-thumb {
                      background-color: $scroll-bar-color;
                      border-radius: 16px;
                      border: 6px solid #eaecf0;
                    }
                    &::-webkit-scrollbar-button {
                      display: none;
                    }
                  }
                }
                .col-md-5,
                .col-md-3 {
                  > p {
                    margin-bottom: 16px;
                  }
                  .form {
                    .form-group {
                      margin-bottom: 0px;
                      input {
                        display: none;
                      }
                      input:checked + label {
                        background: rgba(0, 0, 0, .2);
                        /* p {
                           color: $primary01;
                         }
                         svg {
                           path {
                             stroke: $primary01;
                           }
                         }*/
                      }
                      label {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        cursor: pointer;
                        user-select: none;
                        padding: 5px;
                        border-radius: 4px;
                        svg {
                          width: 18px;
                        }
                        p {
                          margin-bottom: 0;
                          color: #343434;
                        }
                      }
                    }
                  }
                }
                .col-md-7,
                .col-md-9 {
                  width: calc(100% - 80px);
                  padding-right: 0;
                  .account-detail {
                    padding-top: 40px;
                    width: calc(100% + 6px);
                  }
                  .form {
                    .form-group {
                      position: relative;
                      margin-bottom: 4px;
                      &.active {
                        label {
                          color: $primary01;
                        }
                      }
                      [type="radio"]:checked + label:before,
                      [type="radio"]:not(:checked) + label:before {
                        left: 0;
                        top: 3px;
                        right: unset;
                      }
                      [type="radio"]:checked + label:after,
                      [type="radio"]:not(:checked) + label:after {
                        top: 6px;
                        left: 3px;
                        right: unset;
                      }
                      label {
                        padding: 0 0 0 24px;
                        cursor: pointer;
                        p {
                          margin-bottom: 0;
                        }
                      }
                    }
                  }
                }
                &.mac-os {
                  &:before {
                    display: none;
                  }
                  .col-md-7,
                  .col-md-9 {
                    width: 100%;
                  }
                  .account-detail {
                    padding-top: 15px !important;
                  }
                  .selected-folder-wrapper, .folder-path-wrapper {
                    width: 100% !important;
                  }
                }
              }
            }
            .folder-names {
              position: absolute;
              top: 10px;
              left: 17px;
              font-weight: 600;
              &.windows-folder-name {
                top: 16px;
                left: 145px;
              }
            }
            .folder-path-wrapper {
              width: calc(100% + 36px);
              position: absolute;
              overflow: hidden;
              bottom: -40px;
              left: 0;
              z-index: 2;
              &.windows-folder-wrapper {
                width: 489px;
                background: #e3e3e3;
                border: 0;
              }
              .folder-name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-user-select: none;
                user-select: none;
                color: #000;
              }
              .col-3 {
                width: 90px;
                color: #000;
              }
              .col-9 {
                width: calc(100% - 90px);
                padding-left: 5px;
              }
              .col-3,
              .col-9 {
                padding-top: 3px;
                padding-bottom: 3px;
                background: #fff;
                font-size: 12px;
              }
              .col-3 {
                background: #e3e3e3;
                padding-left: 12px;
              }
            }
            .selected-folder-wrapper {
              width: calc(100% + 36px);
              position: absolute;
              overflow: hidden;
              left: 0;
              bottom: -78px;
              z-index: 2;
              &.windows-folder-wrapper {
                width: 489px;
                background: #e3e3e3;
                border: 0;
              }
              .folder-name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-user-select: none;
                user-select: none;
                color: #000;
              }
              .col-3 {
                width: 90px;
                color: #000;
              }
              .col-9 {
                width: calc(100% - 90px);
                padding-left: 5px;
              }
              .col-3,
              .col-9 {
                padding-top: 3px;
                padding-bottom: 3px;
                background: #fff;
                font-size: 12px;
              }
              .col-3 {
                background: #e3e3e3;
                padding-left: 12px;
              }
            }
          }
          .ant-modal-footer {
            padding: 10px;
            margin: 0;
            background: #e3e3e3;
            border-radius: 0 0 12px 12px;
            padding-bottom: 15px;
            .btn-style {
              min-width: 100px;
              height: 25px;
              padding: 5px 8px;
              border: 1px solid $border-primary01;
              background: #fff;
              font-weight: 500;
              font-size: 12px;
              line-height: 20px;
              color: #000;
              &:hover,
              &:focus {
                color: #000 !important;
                background: #fff !important;
                border: 1px solid $border-primary01 !important;
              }
            }
            .margin-left {
              margin-left: 15px;
            }
          }
        }
      }
      &.search-popup {
        max-width: 700px;
        width: 100% !important;
        .ant-modal-content {
          padding: 20px 25px 20px;
          background: #ffffff;
          box-shadow: 0 20px 24px -4px rgba(16, 24, 40, 0.08),
          0px 8px 8px -4px rgba(16, 24, 40, 0.03);
          border-radius: 4px;
          button.ant-modal-close {
            display: none;
          }
          .ant-modal-header {
            display: none;
          }
          .ant-modal-body {
            padding: 0;
          }
          .ant-modal-footer {
            display: none;
          }
        }
      }
    }
  }
}
.names-wrapper {
  .col-6 {
    &:first-of-type {
      padding-right: 7.5px;
    }
    &:last-of-type {
      padding-left: 7.5px;
    }
  }
}
.search-body-wrapper {
  h3 {
    margin-bottom: 10px;
    text-align: center;
  }
  .source-account {
    margin-bottom: 20px;
    .form-group {
      @media screen and (max-width: $screen-xs) {
        margin-bottom: 20px;
      }
      label {
        display: inline-block;
        margin-bottom: 8px;
      }
    }
    .prefix-list {
      display: grid;
      grid-template-columns: 3% 73% 21%;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin: 20px 0 0;
      @media screen and (max-width: $screen-xs) {
        grid-template-columns: 3% 94%;
        gap: 12px;
        margin: 0;
      }
      .ant-checkbox-wrapper {
        &:after {
          display: none;
        }
        .ant-checkbox {
          position: relative;
          top: 7px;
        }
      }
      a.btn-style {
        height: 38px;
        border-radius: 5px !important;
        font-size: 13px;
      }
      .form-group {
        @media screen and (max-width: $screen-xs) {
          margin: 0;
        }
      }
    }
  }
  .sync-objects-wrapper {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    .objects-card-wrapper {
      padding: 10px 0;
      min-height: 300px;
      height: calc(100vh - 390px);
      overflow: auto;
      position: relative;
      &::-webkit-scrollbar {
        background-color: #fff;
        width: 16px;
      }
      &::-webkit-scrollbar-track {
        background-color: #fff;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $scroll-bar-color;
        border-radius: 16px;
        border: 4px solid #fff;
      }
      &::-webkit-scrollbar-button {
        display: none;
      }
      .objects-card {
        .row-data {
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
        }
        display: flex;
        position: relative;
        align-items: center;
        gap: 8px;
        padding: 10px 10px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 3px;
        margin-bottom: 10px;
        .title {
          flex: 2;
        }
        .img-wrapper {
          width: 23px;
          img {
            height: 20px;
          }
        }
        p {
          margin-bottom: 0;
          color: black;
          font-size: 14px;
        }
      }
    }
  }
}
.sync-back {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 5px;
  background: #f6f6f6;
  padding: 10px 10px;
  min-height: 53px;
  .btn-style {
    width: 30px;
    min-width: 0;
    background: transparent;
    border: 0;
    box-shadow: none;
    padding-left: 0;
    &:hover {
      color: $primary01;
      background: transparent !important;
    }
  }
  img {
    width: 20px;
  }
  p {
    font-weight: 600;
    color: black;
    margin-bottom: 0;
  }
  .sync-img {
    text-align: right;
    padding: 5px 0 8px;
    flex: 2;
    img {
      width: 15px;
      cursor: pointer;
    }
  }
}
.folder-row {
  position: relative;
  .row-data {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
#resendEmail {
  position: relative;
  .ant-spin {
    position: absolute;
    right: -40px;
  }
  &.active {
    pointer-events: none;
    cursor: not-allowed;
  }
}
.schedule-preview-wrapper {
  max-width: 360px;
  margin: 20px auto 0 0;
  max-height: 300px;
  overflow: auto;
  &::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $scroll-bar-color;
    border-radius: 16px;
    border: 4px solid #fff;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  .schedule-preview-card {
    display: grid;
    grid-template-columns: 50px auto auto;
    align-items: center;
    justify-content: space-between;
    grid-column-gap: 20px;
    padding: 9px 12px 8px;
    border-radius: 5px;
    &:nth-of-type(odd) {
      background: #eee;
    }
    p {
      margin-bottom: 0;
    }
  }
}
.ant-select-dropdown
.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
.ant-select-item-option-state {
  color: $primary01;
}
.ant-select-dropdown
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: rgba(234, 73, 3, 0.2);
  //border-radius: 0 !important;
}
.ant-select-multiple-wrapper {
  .ant-select-dropdown {
    .ant-select-item {
      margin-bottom: 3px !important;
      &:last-of-type {
        margin-bottom: 0 !important;
      }
    }
  }
  .anticon.anticon-close {
    position: relative;
    top: -3px;
  }
}
.ant-select-dropdown {
  z-index: 9999;
  .ant-select-item-option-content {
    .role-card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        margin-bottom: 0;
        color: rgba(0, 0, 0, 0.88);
      }
      .info-icon {
        max-width: 15px;
      }
    }
  }
}
.schedule-backup-date {
  max-width: calc(100% - 15px);
  padding: 0;
  border-radius: 10px;
  background: white;
  z-index: 3;
  position: relative;
  .form {
    .row {
      margin-bottom: 0 !important;
    }
    .anticon {
      position: relative;
      top: -3px;
    }
    label {
      margin-bottom: 6px;
    }
    .form-group {
      display: block !important;
      margin-bottom: 20px !important;
      .form-control {
      }
      .days-name-wrapper {
        display: grid;
        grid-template-columns: repeat(7, 50px);
        align-items: center;
        justify-content: space-between;
        grid-column-gap: 10px;
        .days-name {
          input {
            display: none;
          }
          input[type="checkbox"]:checked + label {
            background: $primary01;
            border-color: $primary01;
            color: $white;
          }
          label {
            color: $text-primary04;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;
            border: 1px solid $text-primary04;
            border-radius: 7px;
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }
  }
}
.disabled {
  opacity: 0.6;
  user-select: none;
  pointer-events: none;
}
.disable {
  user-select: none;
  pointer-events: none;
}
.disabled-new {
  opacity: 0.6;
  user-select: none;
}
.time-wrapper {
  .form-group {
    &:first-of-type {
      width: 135px;
      .ant-select .ant-select-selector {
        height: 54px !important;
        border-color: #eaecf0 !important;
      }
    }
  }
}
.notification-wrapper {
  margin-left: 20px !important;
  .bell-wrapper {
    position: relative;
    span {
      background: $primary01;
      color: white;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -7px;
      right: -10px;
      font-size: 12px;
      font-weight: 600;
      display: flex;
    }
  }
  .ant-tooltip-inner {
    padding: 0;
  }
  .notifications {
    max-height: 300px;
    width: 248px;
    overflow: auto;
    &::-webkit-scrollbar {
      background-color: #fff;
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $scroll-bar-color;
      border-radius: 16px;
      border: 2px solid #fff;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
    .no-notifications {
      text-align: center;
      padding: 25px 20px;
      cursor: auto;
      img {
        display: block;
        margin: 0 auto 10px;
        width: 30px;
      }
    }
    li {
      display: block;
      border-bottom: 1px solid $border-primary01;
      margin: 0 !important;
      position: relative;
      border-radius: 0 !important;
      cursor: pointer;
      .notification-text {
        display: block;
        padding: 5px 20px;
      }
      .fa-xmark {
        position: absolute;
        top: 5px;
        right: 1px;
        padding: 8px;
      }
      &.unread {
        &:after {
          content: "";
          height: 8px;
          width: 8px;
          background: red;
          border-radius: 50%;
          position: absolute;
          top: 12px;
          left: 5px;
        }
      }
      &:last-of-type {
        border-bottom: 0;
      }
      &.remove-all-notifications {
        position: absolute;
        right: 10px;
        font-size: 12px;
        font-weight: 600;
        color: #fff !important;
        cursor: pointer;
        width: 100%;
        text-align: center;
        bottom: -26px;
        top: auto;
        background: #ea4903 !important;
        left: 0;
        border-radius: 0 0 6px 6px !important;
        padding: 0 !important;
        a {
          display: block;
          padding: 5px 20px 5px;
          &:hover {
            background: #ea4903 !important;
          }
        }
      }
    }
  }
}
.custom-table {
  position: sticky;
  top: 60px;
  z-index: 2;
  .custom-table-header {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    background: #eee;
    .table-header-item {
      font-weight: bold;
      padding: 15px 10px;
      color: #000;
    }
  }
}
.custom-table-wrapper {
  border-bottom: 1px solid $border-primary01;
  &.active {
    background: #f9f9f9;
    border-bottom: 0;
  }
  table {
    margin: 0;
  }
  .activity-inner-table:not(.active) {
    display: none;
  }
  .activity-inner-table {
    height: auto;
    max-height: 517px;
    overflow: auto;
    @include scrollbar();
    tr {
      th,
      td {
        vertical-align: middle;
        height: 47px;
        @media screen and (max-width: $screen-xs) {
          min-width: 200px;
        }
        &:first-of-type {
          width: 400px;
          word-break: break-all;
        }
        &:nth-of-type(2) {
          width: 100px;
          text-align: center;
          svg {
            font-size: 15px;
          }
        }
        &:nth-of-type(3) {
          width: 100px;
          text-align: center;
        }
        &:nth-of-type(4) {
          width: 120px;
          text-align: center;
        }
        &:nth-of-type(5) {
          width: 200px;
          text-align: center;
        }
      }
    }
  }
  .custom-table-row {
    display: grid;
    cursor: pointer;
    user-select: none;
    grid-template-columns: 50% 50%;
    color: #000;
    position: relative;
    @media screen and (max-width: $screen-xs) {
      grid-template-columns: 40% 60%;
    }
    &:after {
      content: "";
      border: solid black;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 30px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      width: 0;
      height: 0;
      transition: 0.5s;
      transform-origin: center;
      @media screen and (max-width: $screen-xs) {
        right: 10px;
      }
    }
    &.active {
      background: #f6f6f6;
      font-weight: bold;
      &:after {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        transform-origin: center;
      }
    }
    .table-item {
      padding: 15px 10px;
      font-size: 15px;
    }
  }
  &.active {
    .activity-inner-table {
      display: block;
      height: 518px;
      overflow: auto;
      &::-webkit-scrollbar {
        background-color: #fff;
        width: 16px;
      }
      &::-webkit-scrollbar-track {
        background-color: #fff;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $scroll-bar-color;
        border-radius: 16px;
        border: 4px solid #fff;
      }
      &::-webkit-scrollbar-button {
        display: none;
      }
    }
  }
}
.activity-page {
  h2.small-title {
    font-size: 16px;
    line-height: 26px;
    position: relative;
    top: -10px;
    a {
      display: block;
      min-width: 0;
      text-align: center;
    }
  }
}
.directory-list {
  height: 425px;
  overflow: auto;
  &.windows-dir-list-wrapper {
    height: 425px;
  }
  &::-webkit-scrollbar {
    background-color: #fff;
    width: 14px;
  }
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $scroll-bar-color;
    border-radius: 16px;
    border: 6px solid #fff;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  h3 {
    font-weight: 400;
    font-size: 15px;
    line-height: 26px;
  }
  label {
    position: relative;
    padding-left: 22px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-weight: 400;
    margin-bottom: 0 !important;
    font-size: 13px;
    line-height: 17px;
    &:before {
      content: "";
      height: 16px;
      width: 16px;
      background: url("../images/icons/folder-icon.svg") no-repeat;
      background-size: 100%;
      position: absolute;
      left: 0;
      top: 1px;
    }
    &.file-icon {
      &:before {
        content: "";
        height: 16px;
        width: 12px;
        background: url("../images/icons/files-orange.svg") no-repeat;
        background-size: 100%;
        position: absolute;
        left: 0;
        top: 1px;
      }
    }
  }
}
.ant-notification-notice {
  .ant-notification-notice-close {
    //display: none !important;
    color: #000 !important;
  }
  .ant-notification-notice-message,
  .ant-notification-notice-description {
    font-weight: 600 !important;
    color: #000 !important;
    margin-inline-start: 45px !important;
  }
}
.ant-notification-notice {
  width: auto;
}
.dir-path-wrapper {
  .btn-style {
    border-radius: 8px 0 0 0;
    min-width: 0;
    background: #fff !important;
    color: $primary01 !important;
    border: 0;
    i {
      color: #667085;
      font-size: 16px;
    }
    &:focus,
    &:active,
    &:hover {
      background: #fff !important;
      color: $primary01 !important;
    }
  }
  .form-group {
    margin-bottom: 0;
  }
  .form-control {
    border: 0 !important;
    margin-left: 0 !important;
    padding: 10px 15px 10px 0 !important;
    height: 44px !important;
    border-radius: 0 8px 0 0 !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
  }
}
.selected-select {
  .ant-select-selector {
    border-color: #32d583 !important;
  }
  .ant-select {
    width: 100%;
    &.ant-select-focused {
      .ant-select-selector {
        border-color: #32d583 !important;
        box-shadow: 0 0 0 4px rgba(50, 213, 131, 0.12) !important;
      }
    }
  }
}
.checkbox-wrapper {
  max-width: 532px;
}
.red,
.red-error {
  font-weight: 600;
  color: red;
  position: absolute;
  bottom: -5px;
  left: 0;
}
.green-error {
  font-weight: 600;
  color: green;
  white-space: nowrap;
}
.red-error {
  margin-top: 0 !important;
  position: relative;
  bottom: auto;
  top: -5px;
}
.has-error {
  .form-control {
    border-color: red !important;
  }
  .ant-select-selector {
    border-color: red !important;
  }
  .ant-select {
    width: 100%;
    &.ant-select-focused {
      .ant-select-selector {
        border-color: red !important;
        box-shadow: 0 0 0 4px rgba(255, 0, 0, 0.12) !important;
      }
    }
  }
}
.s3logo {
  height: 60px;
}
.ant-select-single
.ant-select-selector {
  border-radius: 8px;
}
.ant-select:not(
    .ant-select-customize-input
  )
.ant-select-selector {
  border: 1px solid #d0d5dd;
  box-shadow: 0 1px 2px rgb(16 24 40 / 5%);
  color: $text-primary04;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    color: $text-primary04;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
}
.loading-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  height: 40px;
  width: 4px;
}
.loading-error-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  height: 50px;
  text-align: center;
  padding: 0 15px;
  span {
    font-size: 16px;
    line-height: 26px;
    font-weight: bold;
    position: relative;
    top: 12px;
  }
  &.no-files {
    @media screen and (max-width: $screen-xs) {
      position: relative;
    }
    .drag-and-drop-wrapper {
      margin: 0;
      position: relative;
      top: -35px;
      @media screen and (max-width: $screen-xs) {
        top: 0;
        left: 0;
        width: 300px;
        position: absolute;
        margin: 20px 0 0;
      }
      span {
        display: block;
      }
      .drag-and-drop {
        padding: 100px;
        @media screen and (max-width: $screen-xs) {
          padding: 20px;
        }
        img {
          display: block;
          margin: 20px auto;
        }
      }
    }
  }
}
.ant-spin {
  color: $primary01;
}
.add-cloud-account-page {
  .connected-accounts {
    margin-top: 0 !important;
    margin-bottom: 30px !important;
    @media screen and (max-width: $screen-xs) {
      margin-bottom: 20px !important;
    }
  }
}
.mb-41 {
  margin-bottom: 40px;
  @media screen and (max-width: $screen-xs) {
    margin-bottom: 20px;
  }
}
#add-cloud-fields {
  position: relative;
  input {
    &.active {
      border: 1px solid #32d583;
    }
  }
}
#cloud-account-icon {
  position: relative;
  svg {
    &:last-of-type {
      position: absolute;
      left: 5px;
    }
  }
}
.Oracle {
  height: 17px;
}
.Backblaze {
  height: 37px;
}
.DigitalOcean {
  height: 27px;
}
.VultrLogo {
  height: 27px;
}
.storjLogo {
  height: 37px;
}
.dashboardNewIcon {
  width: 17px;
  max-width: 20px !important;
  position: relative;
  left: 10px !important;
}
.search-icon-left {
  position: relative;
  left: 3px;
}
.activity-icon {
  position: relative;
  left: 3px;
}
.backupIcon {
  width: 18px;
  position: relative;
  left: 2px;
}
.migrationIcon {
  width: 20px;
  position: relative;
  left: 1px;
}
.buckets-list-wrapper {
  @media screen and (max-width: $screen-xs) {
    overflow: auto;
  }
}
.buckets-list {
  margin-bottom: 20px;
  @media screen and (max-width: $screen-xs) {
    min-width: 400px;
  }
  p {
    padding: 10px 20px;
  }
}
.buckets-list-new {
  margin-top: 20px;
  p {
    padding: 10px 20px;
    border: 1px solid $border-primary01;
    border-radius: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    @media screen and (max-width: $screen-xs) {
      padding: 5px 10px;
      margin-right: 6px;
      margin-bottom: 6px;
    }
  }
  input {
    position: absolute;
    opacity: 0;
  }
}
.buckets-header {
  background: #f6f6f6;
  font-weight: bold;
  border: 1px solid $border-primary02;
  padding: 5px 0;
  white-space: nowrap;
  padding-right: 16px;
  padding-left: 15px;
  @media screen and (max-width: $screen-xs) {
    padding: 0 6px;
  }
  .col-md-5, .col-md-3 {
    p {
      padding: 10px 20px;
      @media screen and (max-width: $screen-xs) {
        padding: 10px 0;
      }
    }
  }
  p {
    @media screen and (max-width: $screen-xs) {
      word-wrap: break-word;
      font-size: 12px;
      line-height: 16px;
    }
    &.last-item {
      text-align: center;
      right: 30px;
      position: relative;
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
.buckets-wrapper {
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 1px solid $border-primary02;
  border-top: 0;
  scrollbar-gutter: stable both-edges;
  .row:not(:last-of-type) {
    border-bottom: 1px solid $border-primary02;
  }
  &::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $scroll-bar-color;
    border-radius: 16px;
    border: 4px solid #fff;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  .col-md-5 {
    p {
      padding: 10px 0;
    }
  }
  p {
    @media screen and (max-width: $screen-xs) {
      font-size: 12px;
      line-height: 16px;
      padding: 0;
      word-wrap: break-word !important;
    }
  }
}
.cache-radio-wrapper {
  padding-top: 8px;
}
.last-item-padding {
  padding-right: 23px;
}
.info-wrapper {
  img {
    width: 16px;
    margin-left: 15px;
    cursor: pointer;
  }
}
.delete-wrapper {
  .ant-popover {
    .ant-popover-content {
      .ant-popover-inner {
        .ant-popover-inner-content {
          .ant-popconfirm-inner-content {
            .ant-popconfirm-message {
              .ant-popconfirm-message-title {
                @media screen and (max-width: $screen-xs) {
                  br {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .form-group {
    margin-bottom: 0 !important;
    font-weight: 500;
    label {
      .ant-spin {
        margin-left: 20px;
      }
    }
  }
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
  }
}
.delete-wrapper,
.action-wrapper {
  .ant-popconfirm
  .ant-popconfirm-message
  > .ant-popconfirm-message-icon
  .anticon {
    color: $primary01;
  }
  .ant-btn-primary {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    span {
      color: #000 !important;
    }
    &:hover {
      background: #fff;
    }
  }
  .ant-btn-default {
    background-color: #fff;
    border-color: $primary01;
    color: $primary01;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
    span {
      color: $primary01 !important;
    }
  }
  .ant-btn:not(
      :disabled
    ):focus-visible {
    outline: 4px solid rgba(234, 73, 3, 0.2);
  }
  .ant-btn-primary:not(
      :disabled
    ):hover {
    background-color: #fff;
    box-shadow: none;
  }
  .ant-btn-default:not(
      :disabled
    ):hover {
    border-color: $primary01;
  }
}
.file-path-modal-wrapper {
  .ant-popconfirm
  .ant-popconfirm-message
  > .ant-popconfirm-message-icon
  .anticon {
    color: $primary01;
  }
  .ant-btn-default {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    display: none !important;
    span {
      color: #000 !important;
    }
    &:hover {
      background: #fff;
    }
    &:hover {
      color: #000 !important;
      background: #fff;
      border: 1px solid #d9d9d9 !important;
      span {
        color: #000 !important;
      }
    }
  }
  .ant-btn-primary {
    background-color: #fff;
    border-color: $primary01;
    color: $primary01;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
    span {
      color: $primary01 !important;
    }
    &:hover {
      background-color: #fff;
      span {
        color: $primary01 !important;
      }
    }
  }
  .ant-btn:not(
      :disabled
    ):focus-visible {
    outline: 4px solid rgba(234, 73, 3, 0.2);
  }
  .ant-btn-default:not(
      :disabled
    ):hover {
    background-color: #fff;
    box-shadow: none;
  }
  .ant-btn-default:not(
      :disabled
    ):hover {
    border-color: $primary01;
  }
}
[class^="ant-popover"],
[class*=" ant-popover"] {
  max-width: 465px;
}
.allow-delete-wrapper {
  .form-group {
    margin-bottom: 8px;
    font-weight: 500;
  }
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
  }
  .ant-popconfirm
  .ant-popconfirm-message
  > .ant-popconfirm-message-icon
  .anticon {
    color: $primary01;
  }
  .ant-btn-primary {
    background-color: $primary01;
    border-color: $primary01;
    box-shadow: none;
    span {
      color: #fff !important;
    }
    &:hover {
      background: $primary01;
    }
  }
  .ant-btn:not(
      :disabled
    ):focus-visible {
    outline: 4px solid rgba(234, 73, 3, 0.2);
  }
  .ant-btn-primary:not(
      :disabled
    ):hover {
    background-color: $primary01;
  }
  .ant-btn-default:not(
      :disabled
    ):hover {
    border-color: #d9d9d9;
  }
  .ant-popconfirm-message-icon {
    display: none;
  }
  .ant-popconfirm-inner-content,
  .ant-popconfirm-buttons {
    text-align: center;
  }
  [class^="ant-popover"],
  [class*=" ant-popover"] {
    max-width: 450px;
  }
  .switch-wrapper {
    &.shared-drive-switch {
      .ant-popover {
        .ant-popover-content {
          .ant-popover-arrow {
            &:before {
              background: $white !important;
            }
          }
          .ant-popover-inner {
            .ant-popover-inner-content {
              .ant-popconfirm-inner-content {
                .ant-popconfirm-buttons {
                  .ant-btn-default {
                    border-color: $black !important;
                    span {
                      color: $black !important;
                    }
                  }
                  .ant-btn-primary {
                    span {
                      color: $white !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    > div {
      .ant-popover {
        width: 410px;
      }
    }
    .ant-switch {
      height: 15px;
      min-width: 35px;
      &.ant-switch-checked {
        .ant-switch-handle {
          height: 11px;
          width: 11px;
          left: unset;
          right: 9px;
          &:before {
            height: 11px;
            width: 11px;
            left: unset;
            right: -6px;
          }
        }
      }
      .ant-switch-handle {
        height: 11px;
        width: 11px;
        left: -3px;
        &:before {
          height: 11px;
          width: 11px;
          left: unset;
          right: -6px;
        }
      }
    }
  }
}
.cache-size-wrapper {
  input {
    font-size: 13px;
    height: 38px;
  }
  .row {
    margin-bottom: 0 !important;
  }
  .range-value {
    white-space: nowrap;
    span {
      color: $primary01;
    }
  }
  .form {
    .form-group {
      display: flex;
      align-items: center;
      gap: 20px;
      margin: 0;
    }
  }
}
.range-value {
  padding-left: 20px;
  font-weight: 600;
}
.ant-slider
.ant-slider-handle::after {
  box-shadow: 0 0 0 2px black;
}
.ant-slider
.ant-slider-handle:hover::after,
.ant-slider
.ant-slider-handle:active::after,
.ant-slider
.ant-slider-handle:focus::after {
  box-shadow: 0 0 0 4px black;
}
.ant-slider:hover
.ant-slider-track {
  background-color: black;
}
.ant-slider .ant-slider-track {
  background-color: black;
}
.ant-slider:hover
.ant-slider-handle::after {
  box-shadow: 0 0 0 2px black;
}
.searchPath {
  margin-left: -3px;
  font-weight: 400;
  font-size: 14px;
  width: calc(100% - 80px);
  span {
    padding: 5px;
    background: #fff;
    display: inline-block;
    margin-left: 5px;
    max-width: 76.5%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: middle;
  }
}
.settings-icon {
  img {
    width: 15px;
  }
}
.ant-switch.ant-switch-checked,
:where(
    .css-dev-only-do-not-override-1i9hnpv
  ).ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: $blue;
}
.ant-switch:focus-visible,
.ant-switch:focus-visible,
.ant-switch.ant-switch-checked,
:where(
    .css-dev-only-do-not-override-1i9hnpv
  ).ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  // outline: 4px solid rgba(234, 73, 3, 0.2);
  outline: 4px solid rgba(0, 116, 212, 0.2);
}
.ant-input-number-input {
  padding-left: 0 !important;
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $text-primary04 !important;
    opacity: 1; /* Firefox */
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $text-primary04 !important;
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $text-primary04 !important;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.ant-modal-root {
  .ant-modal-wrap {
    .ant-modal {
      &.cloud-drive-modal {
        width: 800px !important;
        .ant-modal-content {
          padding: 0;
          .ant-modal-close {
            display: none;
          }
          .ant-modal-header {
            display: none;
          }
          .ant-modal-body {
            .cloud-drive-modal-wrapper {
              padding: 15px 0 15px;
              .cloud-account-popup-detail {
                ul {
                  li {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    margin-bottom: 3px;
                    min-height: 30px;
                    &:last-of-type {
                      margin-bottom: 3px;
                    }
                    &.radio-btn {
                      .cache-radio-wrapper {
                        padding-top: 0;
                      }
                    }
                    strong {
                      font-size: 14px;
                      color: $black;
                      width: 160px;
                    }
                    p {
                      margin-bottom: 0;
                    }
                    &.path-text {
                      align-items: start;
                      p {
                        width: 235px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                      }
                    }
                  }
                }
              }
              .not-assigned {
                max-width: 480px;
                ul {
                  li strong {
                    width: 230px;
                  }
                }
              }
              .ant-tabs .ant-tabs-tab:hover {
                color: $primary01;
              }
              > h3 {
                text-align: center;
                margin-bottom: 15px;
                border-bottom: 1px solid rgba(5, 5, 5, 0.06);
                padding: 0 50px 10px;
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
              }
              .form-footer {
                padding: 0 25px;
                .btn-style {
                  background: $primary01;
                  color: $white;
                }
              }
              .ant-tabs-tabpane {
                .ant-popover .ant-popover-inner {
                  background: rgb(255, 255, 255);
                  color: #667085;
                  border: 1px solid #D0D5DD;
                  font-weight: 500;
                }
                .ant-popconfirm .ant-popconfirm-message-title {
                  color: #667085 !important;
                  font-weight: 500;
                  > div {
                    color: #667085 !important;
                  }
                }
              }
              .form {
                .form-group {
                  margin-bottom: 10px;
                  .ant-popover-arrow {
                    display: none;
                  }
                  .ant-btn-primary {
                    background-color: #fff;
                    border: 1px solid #d9d9d9;
                    box-shadow: none;
                    span {
                      color: #000 !important;
                    }
                    &:hover {
                      background: #fff;
                    }
                  }
                  .ant-popover-placement-topLeft, .ant-popover-placement-top, .ant-popover-placement-topRight {
                    padding-bottom: 8px;
                  }
                  .ant-btn-default {
                    background-color: #fff;
                    border-color: $primary01;
                    color: $primary01;
                    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
                    &.bkp-del-btn {
                      background: transparent;
                      border-color: $primary01;
                      &:hover {
                        border-color: $primary01 !important;
                      }
                      span {
                        color: $primary01 !important;
                      }
                    }
                    &.bkp-cancel-btn {
                      background: transparent;
                      border-color: $primary01;
                      &:hover {
                        background: transparent;
                        border-color: $primary01 !important;
                      }
                      span {
                        color: $primary01 !important;
                      }
                    }
                    &.bkp-save-btn {
                      background: transparent;
                      border-color: $primary01;
                      span {
                        color: $primary01 !important;
                      }
                    }
                    span {
                      color: $primary01 !important;
                    }
                  }
                  &.hide-input-arrows {
                    .ant-input-number-handler-wrap {
                      display: none;
                    }
                  }
                  &.slider {
                    display: grid;
                    grid-template-columns: 97px auto;
                    align-items: center;
                    column-gap: 20px;
                    strong {
                      font-size: 14px;
                    }
                    > strong {
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 20px;
                      color: #344054;
                      padding: 0 0 2px;
                    }
                  }
                  &.switch-btn {
                    .switch {
                      width: 24rem;
                      position: relative;
                      input {
                        position: absolute;
                        top: 0;
                        z-index: 2;
                        opacity: 0;
                        cursor: pointer;
                      }
                      input:checked {
                        z-index: 1;
                      }
                      .toggle-outside {
                        height: 100%;
                        border-radius: 2rem;
                        padding: 0.25rem;
                        overflow: hidden;
                        transition: 0.25s ease all;
                        background: #ea4903;
                      }
                      .toggle-inside {
                        border-radius: 5rem;
                        background: #fff;
                        position: absolute;
                        transition: 0.25s ease all;
                      }
                      &.switch--horizontal {
                        width: 60px;
                        height: 30px;
                        margin: 0;
                        font-size: 0;
                        margin-bottom: 1rem;
                        input {
                          width: 60px;
                          height: 30px;
                          left: 0;
                          margin: 0;
                        }
                        .toggle-outside {
                          position: absolute;
                          width: 60px;
                          left: 0;
                        }
                        .toggle-inside {
                          height: 22px;
                          width: 22px;
                        }
                        input:checked ~ .toggle-outside .toggle-inside {
                          left: 0.25rem;
                        }
                        input ~ input:checked ~ .toggle-outside .toggle-inside {
                          left: 34px;
                        }
                      }
                    }
                  }
                  &.submit {
                    margin-top: 15px;
                    text-align: right;
                    margin-bottom: 0;
                    min-height: 32px;
                    @media screen and (max-width: $screen-xs) {
                      min-height: 0;
                    }
                    button {
                      @media screen and (max-width: $screen-xs) {
                        width: 100% !important;
                        &.cancel-btn {
                          margin-bottom: 15px;
                        }
                      }
                    }
                  }
                  label:empty {
                    display: none !important;
                  }
                  .ant-input-number {
                    height: 25px !important;
                    position: relative;
                    display: flex;
                    align-items: center;
                    .ant-input-number-input-wrap {
                      min-height: 25px !important;
                      input {
                        font-size: 12px;
                        line-height: 18px;
                        text-align: center;
                        padding: 0;
                      }
                    }
                  }
                  .ant-select {
                    height: 25px !important;
                    .ant-select-selector {
                      min-height: 25px !important;
                      height: 25px !important;
                      .ant-select-selection-item {
                        font-size: 12px;
                        line-height: 18px;
                        color: #000;
                        text-align: center;
                      }
                    }
                  }
                }
              }
              .ant-tabs {
                .ant-tabs-nav {
                  margin: 0;
                  &:before {
                    display: none;
                  }
                  .ant-tabs-nav-wrap {
                    justify-content: center;
                    .ant-tabs-nav-list {
                      .ant-tabs-tab {
                        padding: 5px 15px;
                        background: #fff;
                        border-color: #fff;
                        &.ant-tabs-tab-active {
                          border-color: #f0f0f0;
                          background: #f0f0f0;
                          .ant-tabs-tab-btn {
                            color: $primary01;
                          }
                        }
                      }
                    }
                  }
                }
                .ant-tabs-content-holder {
                  padding: 0 20px;
                  .ant-tabs-content {
                    background: #f0f0f0;
                    padding: 15px 40px 5px;
                    border-radius: 10px;
                    min-height: 240px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media screen and (max-width: $screen-xs) {
                      padding: 15px 10px 5px;
                    }
                  }
                }
              }
              &.connections-modal {
                .ant-tabs {
                  .ant-tabs-content-holder {
                    .ant-tabs-content {
                      padding: 15px 40px 12px;
                      min-height: auto;
                    }
                  }
                }
              }
            }
          }
          .ant-modal-footer {
            display: none;
          }
        }
      }
      &.download-client-popup {
        width: 920px !important;
        &.once-agent {
          width: 810px !important;
        }
        .ant-modal-content {
          padding: 0;
          .left-side {
            padding: 20px;
            padding-right: 0;
          }
          .col-md-4 {
            width: 370px;
            padding-right: 0;
            @media screen and (max-width: $screen-xs) {
              width: 100%;
              padding-right: 15px;
            }
          }
          .col-md-8 {
            width: calc(100% - 370px);
            @media screen and (max-width: $screen-xs) {
              width: 100%;
              display: none;
            }
          }
          .right-side {
            height: 100%;
            img {
              height: 100%;
              object-fit: cover;
              width: calc(100% + 2px);
              max-width: initial;
              border-radius: 0 8px 8px 0;
            }
          }
        }
        img {
          max-width: 100%;
        }
        .ant-modal-close {
          display: none;
        }
        .logo-img-wrapper {
          text-align: center;
          padding-bottom: 20px;
          width: calc(100% + 48px);
          position: relative;
          left: -24px;
        }
        .download-wrapper {
          width: 100%;
          font-size: 14px;
          line-height: 24px;
          padding-left: 40px;
          margin: 10px 0 28px;
          position: relative;
          font-weight: 500;
          .download-icon {
            color: $primary01;
            position: absolute;
            left: 0;
            top: 13px;
            i {
              font-size: 22px;
            }
          }
          strong, a {
            font-weight: 600;
            font-size: 14px;
          }
        }
        .content-wrapper {
          padding-left: 40px;
        }
        h3 {
          color: $primary01;
          font-size: 14px;
          line-height: 24px;
          font-weight: 700;
          position: relative;
          margin-bottom: 20px;
          a {
            font-weight: 700;
            text-decoration: underline;
            &.download-icon {
              position: absolute;
              left: -40px;
              top: 13px;
              i {
                font-size: 22px;
              }
            }
          }
        }
        p {
          font-weight: 400;
        }
        .btn-style {
          margin-top: 20px;
          margin-bottom: 10px;
        }
        .launch-amove-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          &.three {
            width: 342px;
            position: relative;
            left: -5px;
          }
          .btn-style {
            padding: 5px 10px;
            font-size: 13px;
            min-width: auto;
            &.large-btn {
              width: 150px;
            }
          }
        }
        .launch-amove-btn-wrapper {
          h3 {
            margin-top: 10px;
            margin-bottom: 0;
            font-weight: 600;
          }
          .launch-amove-btn .btn-style {
            margin-top: 10px;
          }
        }
        a.cancel {
          color: #000;
          font-weight: 500;
        }
      }
    }
  }
}
.smaller-container {
  max-width: 1000px;
  margin: auto;
}
#chunk-size-popup-wrapper {
  position: relative;
  .ant-tooltip {
    .ant-tooltip-content {
      width: 215px;
    }
  }
}
.ant-tooltip {
  .ant-tooltip-content {
    white-space: break-spaces;
    .ant-tooltip-inner {
      background: rgb(255, 255, 255);
      color: $text-primary04;
      border: 1px solid $border-primary02;
      font-weight: 500;
    }
    .ant-tooltip-arrow {
      display: none;
      &:before {
        background: #fff;
      }
    }
  }
}
.amove-migration-wrapper {
  &.edit-profile-wrapper {
    padding-top: 100px;
    max-width: 400px;
    margin: auto;
    &.new-page {
      padding-top: 30px;
      max-width: 400px;
    }
    @media screen and (max-width: $screen-xs) {
      padding-top: 30px;
    }
    h3 {
      margin-bottom: 50px;
      text-align: center;
      @media screen and (max-width: $screen-xs) {
        margin-bottom: 30px;
      }
    }
  }
  .form {
    .form-group {
      &.submit {
        margin-top: 50px;
        text-align: center;
        margin-bottom: 30px;
      }
      .text-below {
        font-size: 14px;
      }
    }
  }
}
.concurrent-threads-slider {
  position: relative;
  .ant-slider {
  }
}
.choose-your-backup-title {
  h3 {
    margin-bottom: 0;
    text-align: center;
    background: #e3e3e3;
    padding: 20px 0;
    border-radius: 12px 12px 0 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    .btn-style {
      border-radius: 8px 0 0 0;
      min-width: 0;
      background: transparent !important;
      color: $primary01 !important;
      border: 0;
      position: absolute;
      left: 15px;
      top: 10px;
      box-shadow: none;
      i {
        color: #667085;
        font-size: 22px;
      }
      &:focus,
      &:active,
      &:hover {
        background: transparent !important;
        color: $primary01 !important;
      }
    }
  }
}
.ant-select {
  &.ant-select-focused {
    .ant-select-selector {
      border-color: #000 !important;
      box-shadow: none !important;
    }
  }
}
.selected-select .ant-select.ant-select-focused .ant-select-selector {
  border-color: #32d583 !important;
  box-shadow: none !important;
}
.selected-select .ant-select.ant-select-focused .ant-select-selector {
  border-color: #32d583 !important;
  box-shadow: none !important;
}
.storj-icon,
.ocean-icon,
.ibm-icon {
  width: 30px;
  height: 30px;
  object-fit: contain;
}
.backblaze-icon {
  width: 30px;
  height: 24px;
  object-fit: contain;
  position: relative;
  left: 2px;
}
.error-verification-code {
  position: relative;
  top: -10px;
}
.error-after-metter {
  margin-top: 0;
  margin-bottom: 0 !important;
  padding: 5px 10px;
  > span {
    margin-bottom: 10px;
    display: block;
  }
  .errors-list {
    li {
      display: block;
      margin-bottom: 5px;
      position: relative;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      .anticon {
        margin-right: 7px;
        position: relative;
        top: -3px;
        opacity: 0;
      }
      &:before {
        content: "";
        height: 12px;
        width: 12px;
        background: #fff;
        border: 1px solid #4E6174;
        display: block;
        position: absolute;
        left: 0;
        top: 3px;
        border-radius: 50%;
        z-index: 9;
      }
      &.active {
        color: #36cf70;
        .anticon {
          opacity: 1;
        }
        &:before {
          display: none;
        }
      }
    }
  }
}
.bottom-p {
  margin-bottom: 30px;
  font-weight: 500;
}
.btn-style.max-width {
  max-width: 250px !important;
  min-width: 250px !important;
}
.success-message {
  font-size: 16px;
  margin-top: 30px;
  display: block;
}
.mobile-br {
  display: none !important;
  @media screen and (max-width: $screen-xs1) {
    display: initial !important;
  }
}
.banner {
  padding: 80px 0 30px;
  @media screen and (max-width: $screen-xs1) {
    padding: 30px 0 40px;
  }
  .content-wrapper {
    @media screen and (max-width: 991px) and (min-width: 768px) {
      margin: 0 0 40px;
    }
    @media screen and (max-width: $screen-xs1) {
      text-align: center;
    }
    &.content-n {
      h1 {
        margin-bottom: 0;
        position: relative;
        top: -20px;
        @media screen and (max-width: $screen-xs1) {
          br {
            display: none;
          }
        }
        font-size: 40px;
        line-height: 50px;
        font-weight: 800;
        color: $primary01;
        @media screen and (max-width: $screen-xs1) {
          font-size: 30px;
          line-height: 40px;
        }
        .orange {
          font-size: 32px;
          line-height: 50px;
          display: block;
          font-weight: 800;
          @media screen and (max-width: $screen-xs1) {
            font-size: 28px;
            line-height: 40px;
          }
        }
        span:not(.orange) {
          display: block;
          font-size: 30px;
          line-height: 45px;
          font-weight: 400;
          margin-top: 25px;
          b {
            font-weight: 400;
            text-decoration: underline;
          }
          @media screen and (max-width: $screen-xs1) {
            font-size: 20px;
            line-height: 30px;
            br {
              display: none;
            }
          }
        }
        @media screen and (max-width: 1199px) and (min-width: 992px) {
          font-size: 36px;
          line-height: 46px;
          span:not(.orange) {
            font-size: 30px;
            line-height: 40px;
            font-weight: 400;
          }
        }
      }
    }
    h1 {
      margin-bottom: 0;
      position: relative;
      top: -20px;
      @media screen and (max-width: $screen-xs1) {
        br {
          display: none;
        }
      }
      font-size: 40px;
      line-height: 50px;
      font-weight: 800;
      color: $text-primary01;
      @media screen and (max-width: $screen-xs1) {
        font-size: 30px;
        line-height: 40px;
      }
      .orange {
        font-size: 48px;
        line-height: 50px;
        display: block;
        font-weight: 800;
        @media screen and (max-width: $screen-xs1) {
          font-size: 36px;
          line-height: 40px;
        }
      }
      span:not(.orange) {
        display: block;
        font-size: 30px;
        line-height: 45px;
        font-weight: 400;
        margin-top: 25px;
        b {
          font-weight: 400;
          text-decoration: underline;
        }
        @media screen and (max-width: $screen-xs1) {
          font-size: 20px;
          line-height: 30px;
          br {
            display: none;
          }
        }
      }
      @media screen and (max-width: 1199px) and (min-width: 992px) {
        font-size: 36px;
        line-height: 46px;
        span:not(.orange) {
          font-size: 30px;
          line-height: 40px;
          font-weight: 400;
        }
      }
    }
    p {
      margin: 10px 0 30px;
      font-family: $neue;
      font-size: 20px;
      line-height: 28px;
      @media screen and (max-width: 991px) and (min-width: 768px) {
        margin: 20px 0 35px;
      }
      @media screen and (max-width: $screen-xs1) {
        font-size: 18px;
        line-height: 26px;
        margin: 15px auto 10px;
        max-width: 345px;
        br {
          display: none !important;
        }
      }
    }
    &.use-cases {
      @media screen and (max-width: $screen-xs1) {
        text-align: left;
        p {
          margin: 10px 0 30px;
          max-width: 100%;
          br {
            display: none !important;
          }
        }
      }
    }
    strong {
      font-size: 20px !important;
      line-height: 28px !important;
      @media screen and (max-width: $screen-xs1) {
        font-size: 14px !important;
        line-height: 24px !important;
      }
    }
    ul {
      padding: 0;
      margin: 20px 0 40px;
      padding-left: 0;
      list-style: none;
      font-size: 20px;
      line-height: 28px;
      @media screen and (max-width: $screen-xs1) {
        font-size: 14px !important;
        line-height: 24px !important;
      }
      li {
        font-size: 20px;
        line-height: 28px;
        position: relative;
        padding-left: 20px;
        margin-bottom: 5px;
        display: block;
        @media screen and (max-width: $screen-xs1) {
          font-size: 14px !important;
          line-height: 24px !important;
        }
        strong {
          font-size: 20px !important;
          line-height: 28px !important;
          @media screen and (max-width: $screen-xs1) {
            font-size: 14px !important;
            line-height: 24px !important;
          }
        }
        ul {
          margin: 0;
          font-size: 20px;
          line-height: 28px;
        }
        &:before {
          content: "";
          left: 0;
          top: 9px;
          background: $white;
          height: 8px;
          width: 8px;
          border-radius: 50%;
          border: 2px solid $primary01;
          display: block;
          position: absolute;
          transition: 0.1s;
        }
        &:hover {
          /*&:before {
            width: 0;
            height: 0;
            border-top: 5px solid transparent;
            border-left: 5px solid $blue;
            border-bottom: 5px solid transparent;
            border-radius: 0;
            border-right: 0;
          }*/
        }
      }
    }
    span:not(.orange) {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
    }
    a.btn-style {
      vertical-align: top;
      margin-right: 20px;
      width: 230px;
      font-size: 16px;
      height: 48px;
      @media screen and (max-width: 1199px) and (min-width: 992px) {
        margin-bottom: 20px;
      }
      &:last-of-type {
        margin: 0 0 0 0;
        @media screen and (max-width: $screen-xs1) {
          margin: 0 15px;
          min-width: 250px;
        }
      }
    }
    .google-btn {
      vertical-align: top;
      margin-right: 20px;
      width: 230px;
      font-size: 16px;
      height: 48px;
      padding: 5px 0px 7px;
      img {
        margin-left: -10px;
      }
      @media screen and (max-width: 1199px) and (min-width: 992px) {
        margin-bottom: 20px;
      }
      @media screen and (max-width: $screen-xs1) {
        margin: 0 15px 20px;
        min-width: 250px;
      }
    }
    .btn-wrapper {
      margin-top: 40px;
      margin-bottom: 20px;
    }
  }
  .img-wrapper {
    position: relative;
    @media screen and (max-width: 1300px) {
      width: 100%;
      height: auto;
    }
    // @media screen and (min-width: 1200px) {
    //   left: -50px;
    // }
    @media screen and (max-width: 991px) {
      margin-bottom: 30px;
      margin-top: 20px;
    }
    > img {
      width: 100%;
    }
    .laptop-wrapper {
      height: 300px;
      @media screen and (max-width: 1300px) {
        height: auto;
      }
      img {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 2;
        @media screen and (max-width: 1199px) {
          width: 100%;
        }
      }
      .laptop-image-wrapper {
        position: absolute;
        z-index: 1;
        width: 403px;
        height: 245px;
        background: #fff;
        top: 17px;
        padding-top: 5px;
        left: 64px;
        @media screen and (max-width: 1300px) {
          width: calc(100% - 24%);
          height: calc(100% - 24%);
          top: 23px;
          left: 0;
          right: 0;
          margin: auto;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .logos-wrapper {
    h3 {
      font-family: $neue;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      margin-top: 30px;
    }
    img {
      width: 80%;
      display: block;
      margin: auto;
    }
  }
}
.down-load-page-banner {
  .content-wrapper {
    .orange {
      color: $primary01;
      display: block;
    }
    &.content-n {
      margin-bottom: 65px;
      h1 {
        font-size: 28px;
        font-weight: 400;
        line-height: 56px;
        margin-top: 30px;
        font-family: $neue;
        @media screen and (max-width: $screen-xs1) {
          margin-top: 10px;
          font-size: 24px;
          line-height: 40px;
        }
      }
    }
    h1 {
      font-size: 48px;
      font-weight: 400;
      line-height: 56px;
      margin-top: 30px;
      font-family: $neue;
      @media screen and (max-width: $screen-xs1) {
        margin-top: 10px;
        font-size: 30px;
        line-height: 40px;
      }
    }
    p {
      line-height: 1.625;
      font-size: 16px;
      letter-spacing: 0;
    }
    .btn-wrapper {
      margin-bottom: 0;
    }
    .google-btn {
      vertical-align: top;
      margin-right: 15px;
      width: auto;
      padding: 5px 18px 7px;
      font-size: 16px;
      font-weight: 500;
      height: 48px;
      text-transform: none;
      margin-bottom: 20px;
      &:last-of-type {
        margin-right: 0;
      }
      @media screen and (max-width: $screen-xs1) {
        height: 40px;
        padding: 5px 12px 7px;
        min-width: 220px;
        pointer-events: none;
      }
    }
    .btn-wrapper {
      @media screen and (max-width: $screen-xs1) {
        margin-bottom: -15px;
      }
    }
  }
}
.download-page-cards-wrapper {
  margin-top: 40px;
  .col-md-6 {
    max-width: 290px;
  }
}
.card-wrapper {
  position: relative;
  padding-left: 60px;
  margin-bottom: 30px;
  img {
    position: absolute;
    left: 0;
    width: 45px;
    top: 0;
    bottom: 0;
    margin: auto;
    &.dashboard-icon {
      width: 36px;
      left: 6px;
    }
    &.cloud-drive-icon {
      width: 43px;
      left: 3px;
    }
  }
  h3 {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
  }
}
#bannerModal {
  .wpcf7-response-output {
    padding: 10px 60px;
  }
}
.billingStatus {
  @media screen and (max-width: $screen-xs) {
    overflow-x: hidden;
  }
  .login-wrapper {
    @media screen and (max-width: $screen-xs) {
      margin: 0 0 0 48px;
    }
  }
  .m-0 {
    &.text-right {
      text-indent: 60px;
    }
  }
  .unsubscribeButton {
    justify-content: center;
    margin-top: 30px;
    .btn-style {
      min-width: 70%;
    }
  }
}
.title-wrapper {
  h3 {
    font-weight: 400;
    //font-size: 18px;
    //line-height: 28px;
    color: #000;
    margin-bottom: 40px;
    a:not(.btn-style) {
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      text-decoration: underline;
      color: #000 !important;
    }
  }
}
.files-title-wrapper {
  display: flex;
  margin-bottom: 20px;
  h3 {
    width: 160px;
  }
  .form-control {
    max-width: 620px;
    height: 40px;
  }
}
.conditional-title {
  h3 {
    opacity: 0;
    @media screen and (min-width: 1350px) {
      .create-storage-btn-wrapper {
        float: right;
        .btn-style.create-storage-btn {
        }
      }
    }
    @media screen and (max-width: 1349px) {
      .create-storage-btn-wrapper {
        .btn-style.create-storage-btn {
          margin: 20px auto -50px;
        }
      }
    }
  }
  &.showTitle {
    @media screen and (max-width: $screen-xs) {
      text-align: center;
    }
    h3 {
      opacity: 1;
    }
    .btn-style {
      @media screen and (max-width: $screen-xs) {
        margin: 20px auto;
      }
    }
  }
}
.spin {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
.cloud-drive-modal-wrapper {
  .ant-tabs-tabpane {
    width: 100%;
  }
  &.new-on-boarding-popup {
    background: #fff !important;
    border-radius: 10px;
    .col-md-4 {
      width: 328px !important;
      @media screen and (max-width: $screen-xs) {
        width: 100% !important;
      }
    }
    .col-md-8 {
      width: calc(100% - 328px) !important;
      @media screen and (max-width: $screen-xs) {
        width: 100% !important;
        display: none;
      }
    }
    .content-wrapper {
      padding-left: 30px !important;
      padding-right: 20px !important;
      * {
        color: #000 !important;
      }
      h3.title {
        font-size: 16px;
        font-style: normal;
        font-weight: 700 !important;
        line-height: 20px;
      }
      h3 {
        font-size: 14px;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 20px;
      }
    }
    .logo-img-wrapper {
      padding-bottom: 20px !important;
      width: 100% !important;
      position: relative !important;
      left: 0 !important;
      text-align: left !important;
      padding-left: 30px !important;
      margin-bottom: 15px !important;
      margin-top: 15px !important;
    }
    .launch-amove-btn {
      display: block !important;
      text-align: left !important;
      padding-left: 30px !important;
    }
  }
  [id$='panel-1'] {
    max-width: 400px;
    margin: 0 auto 0;
    //margin-top: 27px;
    .cache-radio-wrapper {
      margin-left: 15px;
    }
  }
  [id$='panel-2'] {
    .form {
      max-width: 550px;
      margin: auto;
    }
    .form-group {
      white-space: nowrap;
      align-items: center;
    }
  }
  [id$='panel-3'] {
    .form {
      max-width: 550px;
      margin: auto;
    }
    //margin-top: 43px;
    .form-group {
      white-space: nowrap;
    }
  }
}
.no-margin-b {
  &.form-group {
    margin-bottom: 0 !important;
    label {
      margin-bottom: 0 !important;
    }
  }
}
.radio-info-wrapper {
  img {
    position: relative;
    top: 0px;
    left: -5px;
  }
}
.larger-p {
  font-size: 16px;
}
.success-message-login {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
  box-sizing: border-box;
  padding: 8px 12px;
  color: rgba(0, 0, 0, 1);
  list-style: none;
  position: relative;
  display: flex;
  align-items: center;
  word-wrap: break-word;
  border-radius: 8px;
  max-width: 260px;
  margin: 0 auto 20px;
  &.red {
    background-color: #ffeded;
    border: 1px solid #a6292e;
    color: #a6292e !important;
    span {
      color: #a6292e !important;
    }
  }
}
.backup-popup-setting {
  padding: 0 30px;
}
.ant-dropdown {
  min-width: 90px !important;
}
.stream-detail-text {
  padding: 0 10px;
  span {
    font-weight: 500;
    color: #667085;
    font-family: inherit;
  }
  ul {
    margin: 8px 0 20px;
    padding: 0 0 0 25px;
    &:last-of-type {
      margin-bottom: 5px;
    }
    li {
      padding: 0 0 0 15px;
      font-weight: 500;
      color: #667085;
      font-family: inherit;
      &:not(:last-of-type) {
        margin-bottom: 5px;
      }
      position: relative;
      &:before {
        content: "";
        // background: url("../images/icons/check-white-icon.svg") no-repeat center;
        background: #667085;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 7px;
      }
      p {
        font-weight: 500;
        color: #667085;
        font-family: inherit;
        a {
          color: $primary01;
        }
      }
    }
  }
  &.no-title {
    ul {
      margin: 8px 0 8px;
      padding: 0 0 0 10px;
    }
  }
}
.new-tooltip-wrapper {
  .ant-radio-group {
    > label:first-of-type {
      .ant-tooltip-content {
        width: 346px;
      }
    }
  }
  .ant-tooltip-content {
    width: 360px;
    .stream-detail-text {
      padding: 0 10px;
    }
  }
}
.edit-cloud-drive-name {
  position: relative;
  .red-error.cloud-name {
    position: absolute;
    width: 190px;
    left: 215px;
    font-size: 14px;
    line-height: 18px;
    top: -3px;
    &.length-error {
      top: 6px;
    }
  }
  .form-group {
    margin-bottom: 0 !important;
    .form-control {
      height: 30px;
      padding: 6px 10px 7px;
    }
  }
}
.flex-gap {
  display: flex;
  align-items: center;
  gap: 10px;
}
.edit-drive-name {
  img {
    cursor: pointer;
    max-width: 15px;
    padding-bottom: 1px;
    position: relative;
    top: -1px;
  }
}
.cloud-drive-type-wrapper {
  .ant-tooltip {
    .ant-tooltip-content {
      .ant-tooltip-inner {
        padding-top: 10px;
        padding-left: 15px;
      }
      width: 440px;
      ul {
        padding-left: 0;
        margin-bottom: 10px;
        &:last-of-type {
          margin-bottom: 6px;
        }
        li {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}
.landing-page {
  padding-top: 100px;
  .logo {
    margin-bottom: 10px;
    text-align: center;
    img {
      width: 200px;
    }
  }
  .logo-wrapper {
    margin-bottom: 50px;
    .text {
      border: 1px solid #ccc;
      display: inline-block;
      padding: 10px;
      border-radius: 50px;
    }
  }
  img {
    max-width: 100%;
  }
  h2 {
    font-size: 38px;
    line-height: 48px;
    margin-bottom: 10px;
  }
  .btn-style {
    margin-bottom: 20px;
    width: 100%;
    height: 50px;
    font-size: 24px;
  }
  p {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 20px;
    &.small {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 0;
      color: gray;
    }
  }
  .text-wrapper {
    max-width: 430px;
  }
}
.App {
  &.landing,
  &.onboarding-connect,
  &.reset-password,
  &.signup,
  &.activation,
  &.subscribe,
  &.cancel-subscribe,
  &.reset-email,
  &.sign-in,
  &.forgot-password,
  &.email-verification,
  &.verification-code,
  &.auto-sign-in {
    #dashboard-header, #left-sidebar {
      display: none !important;
    }
  }
}
.no-border {
  border: 0 !important;
}
.google-btn-new-wrapper {
  > div {
    position: absolute;
    top: 0;
    height: 48px !important;
    overflow: hidden;
    opacity: 0;
    width: 320px;
    max-width: 320px;
    iframe {
      transform: scale(1.19);
      margin: 0 !important;
      top: 2px !important;
      width: 320px;
      max-width: 320px;
    }
  }
  &:focus, &:active, &:hover {
    .btn-style.full.google-btn-new {
      background: $subscribe !important;
      color: $white !important;
      border-color: $subscribe !important;
      span {
        color: $white !important;
      }
    }
  }
  .btn-style.full.google-btn-new {
    background: $white;
    color: $black;
    border-color: $border-primary01;
    background: $subscribe !important;
    color: $white !important;
    border-color: $subscribe !important;
    position: relative;
    > svg {
      width: 25px;
      position: absolute;
      left: 15px;
    }
    span {
      color: $white !important;
    }
    &:focus, &:active, &:hover {
      background: rgba(66, 133, 244, .04) !important;
      color: $black !important;
      border-color: #d2e3fc !important;
      background: $subscribe !important;
      color: $white !important;
      border-color: $subscribe !important;
      span {
        color: $black !important;
        color: $white !important;
      }
    }
  }
  &:focus, &:active, &:hover {
    .btn-style.full.google-btn-new {
      background: rgba(66, 133, 244, .04) !important;
      color: $black !important;
      border-color: #d2e3fc !important;
      span {
        color: $black !important;
      }
    }
  }
}
.not-visible {
  visibility: hidden;
}
.select-folder-btn-wrapper {
  margin-right: 0 !important;
  label {
    margin-bottom: 0 !important;
    margin-top: 15px !important;
    margin-right: 0 !important;
  }
}
.fix-width-120 {
  width: 120px !important;
}
.fix-width-140 {
  width: 140px !important;
}
.max-width-50-percent {
  width: 266px !important;
}
.max-width-50-percent-large {
  width: 325px !important;
}
.select-folder-row-wrapper {
  margin-bottom: 0 !important;
  .col-md-4 {
    width: 145px;
  }
  .col-md-8 {
    .choose-cloud-card {
      padding-right: 30px !important;
      width: 266px !important;
    }
  }
}
.remove-ant-select-arrow {
  .ant-select-selection-placeholder {
    padding: 0 !important;
    //font-size: 14px!important;
  }
  .ant-select-arrow {
    display: none !important;
  }
}
.path-tooltip {
  .ant-tooltip {
    width: 100%;
    min-width: 100%;
    z-index: 9;
    .ant-tooltip-content {
      .ant-tooltip-inner {
        width: calc(100% - 100px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0 0 0 auto;
      }
    }
  }
}
.sign-out-btn-custom {
  position: absolute;
  top: 20px;
  right: 50px;
  font-size: 16px;
  color: #000;
  font-weight: 500;
  padding: 10px 12px 10px;
  border-radius: 5px;
  &:hover {
    background: #ebedf1 !important;
    color: #000;
  }
}
.dashboard-options {
  text-align: right;
  .ant-dropdown-arrow {
    display: none;
  }
  .ant-dropdown-placement-bottomRight {
    padding-top: 8px !important;
  }
  .ant-dropdown-trigger {
    position: relative;
    top: -1px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px 12px 5px;
    border-radius: 5px;
    &:hover, &.ant-dropdown-open {
      background: $border-primary03;
    }
    svg {
      transform: rotate(90deg);
    }
  }
  li {
    vertical-align: middle;
    border-radius: 4px !important;
    &:hover {
      background: unset !important;
    }
    &:last-of-type {
      padding: 5px 12px 5px;
      margin-left: 0;
    }
    &.notification-wrapper {
      margin-left: 50px !important;
    }
    &:not(:last-of-type) {
      margin: 0 10px 0 0;
      border-radius: 5px;
      a {
        color: #000;
      }
      &:hover, &.ant-dropdown-open {
        background: $border-primary03;
        a {
          color: #000;
        }
      }
    }
    .user-avatar {
      border-radius: 50%;
    }
    &:last-of-type span {
      cursor: pointer;
    }
    a.btn-style {
      height: 28px;
      /*   padding: 16px 16px 16px 36px;
         font-size: 14px;
         color: white !important;
         font-weight: 600;
         line-height: 20px;
         width: 130px;
         position: relative;*/
      padding: 14px 14px 14px 34px;
      font-size: 14px;
      color: white !important;
      font-weight: 600;
      line-height: 20px;
      width: auto;
      position: relative;
      text-align: left;
      min-width: 108px;
      img {
        position: absolute;
        display: block;
        left: 8px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      &.move-left-15 img {
        left: 15px;
      }
      &.move-left-16 img {
        left: 16px;
      }
      &.orange {
        background: $primary01;
        color: white !important;
        &:hover {
          background: $primary01;
          color: white !important;
        }
      }
      &.blue {
        background: #0074D4;
        color: white !important;
        &:hover {
          background: #0074D4 !important;
          color: white !important;
        }
      }
      &.green {
        background: $green;
        color: white !important;
        &:hover {
          background: $green !important;
          color: white !important;
        }
      }
    }
  }
  .ant-dropdown-menu-item {
    width: 100%;
    margin: auto;
    text-align: left;
  }
}
.dashboard-left-menu {
  padding-left: 80px;
  transition: all .3s ease-in-out;
  @media screen and (max-width: 991px) {
    display: none;
  }
  li {
    vertical-align: middle;
    border-radius: 4px !important;
    &:not(:last-of-type) {
      margin: 0 10px 0 0;
    }
    &:hover {
      background: unset !important;
    }
    a.btn-style {
      height: 28px;
      /*   padding: 16px 16px 16px 16px;
         font-size: 14px;
         color: white;
         font-weight: 600;
         line-height: 20px;
         position: relative;
         width: 130px;
   */
      padding: 14px 14px 14px 14px;
      font-size: 14px;
      color: white !important;
      font-weight: 600;
      line-height: 20px;
      width: auto;
      position: relative;
      text-align: left;
      min-width: 108px;
      &.orange {
        background: $primary01;
        &:hover {
          background: $primary01;
          color: white !important;
        }
      }
      &.white {
        background: $white !important;
        border-color: #0074D4 !important;
        color: #0074D4 !important;
        &:hover {
          background: $white !important;
          color: #0074D4 !important;
        }
        &.active {
          background: #0074D4 !important;
          color: white !important;
        }
      }
      &.brown {
        /*background: #531A01 !important;
        border-color: #531A01 !important;
        color: #fff !important;*/
        background: #dcfe46 !important;
        border-color: #dcfe46 !important;
        color: #000 !important;
      }
      &.blue {
        background: #0074D4;
        &:hover {
          background: #0074D4 !important;
          color: white !important;
        }
      }
      &.green {
        background: $green;
        &:hover {
          background: $green !important;
          color: white !important;
        }
      }
    }
  }
}
.delete-btns-wrapper {
  margin-top: 20px;
  li {
    vertical-align: middle;
    border-radius: 4px !important;
    &:not(:last-of-type) {
      margin: 0 10px 0 0;
    }
    &:hover {
      background: unset !important;
    }
    a.btn-style {
      height: 32px;
      padding: 16px 16px 16px 16px;
      font-size: 14px;
      color: white;
      font-weight: 600;
      line-height: 20px;
      width: 100px;
      position: relative;
      &.orange {
        background: $primary01;
        &:hover {
          background: $primary01;
          color: white !important;
        }
      }
      &.blue {
        background: #0074D4;
        &:hover {
          background: #0074D4 !important;
          color: white !important;
        }
      }
      &.green {
        background: $green;
        &:hover {
          background: $green !important;
          color: white !important;
        }
      }
    }
  }
}
.dashboard-options.subscribe-page {
  position: absolute;
  top: 20px;
  right: 50px;
}
.back-btn-custom {
  position: absolute;
  top: 20px;
  left: 50px;
  font-size: 16px;
  color: #000;
  font-weight: 500;
  padding: 10px 12px 10px;
  border-radius: 5px;
  &:hover {
    background: #ebedf1 !important;
    color: #000;
  }
}
.file-path-modal-wrapper .ant-modal-footer .ant-popover.ant-popconfirm {
  padding-bottom: 10px;
  width: 300px;
  margin-left: -9px;
  .ant-popover-content {
    margin-left: -9px;
  }
  .ant-popover-arrow {
    display: none;
  }
  .ant-popconfirm-message-title {
    margin: 0;
  }
  .ant-popconfirm-description {
    margin: 0;
    margin-bottom: 12px;
    input {
    }
  }
}
.mac-os {
  .cloud-drive-modal-wrapper [id$=panel-1] {
    margin-top: 30px !important;
    @media screen and (max-width: $screen-xs) {
      margin-top: 0 !important;
    }
  }
  .cloud-drive-modal-wrapper [id$=panel-2] {
    margin-top: 2px !important;
  }
}
.stream-file-popup-wrapper {
  .ant-tooltip {
    p {
      margin-bottom: 0 !important;
    }
    .ant-tooltip-content {
      width: 390px;
    }
  }
}
.form-spacing-fixed {
  .form-group {
    margin-bottom: 0px !important;
    @media screen and (max-width: $screen-xs) {
      margin-bottom: 10px !important;
    }
  }
  .cloud-drive-type-wrapper {
    padding-top: 0;
  }
  .form-group label {
    margin-bottom: 0px !important;
  }
  > .row {
    margin-bottom: 8px;
  }
}
.subscription-header {
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  padding: 20px 40px;
  @media screen and (max-width: $screen-xs) {
    padding: 10px 15px;
  }
  .dashboard-options.subscribe-page {
    position: relative;
    right: 0;
    top: 0;
    li {
      @media screen and (max-width: $screen-xs) {
        margin: 0;
        padding: 0;
      }
      .user-account-dropdown {
        li {
          display: block !important;
        }
        .user-account {
          @media screen and (max-width: $screen-xs) {
            padding: 0;
          }
          span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @media screen and (max-width: 1366px) and (min-width: 1200px) {
              max-width: 230px;
            }
            @media screen and (max-width: 1199px) and (min-width: 992px) {
              max-width: 125px;
            }
            @media screen and (max-width: $screen-xs) {
              max-width: 155px;
            }
          }
        }
      }
    }
  }
  .subscription-back {
    a {
      display: flex;
      align-items: center;
      gap: 10px;
      position: relative;
      span {
        @media screen and (max-width: $screen-xs) {
          font-size: 13px;
        }
      }
      &:hover {
        i {
          color: rgba(26, 26, 26, 1);
        }
        .visible {
          opacity: 0;
        }
        .hover {
          opacity: 1;
          left: 25px;
        }
      }
      i {
        color: rgba(26, 26, 26, .4);
        font-size: 16px;
        transition: all .2s ease-in-out;
      }
      .visible {
        font-weight: 600;
        color: rgba(26, 26, 26, .8);
        position: absolute;
        left: 25px;
        opacity: 1;
        transition: all .2s ease-in-out;
      }
      .hover {
        font-weight: 600;
        color: rgba(26, 26, 26, 1);
        position: absolute;
        left: 30px;
        opacity: 0;
        transition: all .2s ease-in-out;
      }
    }
  }
}
.new-cache-size-wrapper {
  .ant-input-number {
    max-width: 90px;
  }
  .ant-input-number-handler-wrap {
    display: none !important;
  }
  .col-md-4 {
    width: 270px;
    .ant-input-number {
      max-width: 90px;
    }
  }
}
.advance-settings-wrapper {
  position: relative;
  a {
    position: absolute;
    white-space: nowrap;
    left: 0;
    top: 15px;
    font-size: 12px;
  }
}
.edit-profile-wrapper {
  .errors-msg {
    max-width: 100%;
    text-align: center;
  }
}
.reload-cloud-accounts {
  position: absolute;
  right: 65px;
  top: 17px;
  width: 20px;
  z-index: 1;
  cursor: pointer;
  transform: scalex(-1);
}
.radio-small-text {
  position: absolute;
  left: 23px;
  top: 17px;
  font-size: 12px;
}
.ml-2 {
  margin-left: 7px;
}
.refresh-spin {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg) scalex(-1);
  }
  to {
    transform: rotate(360deg) scalex(-1);
  }
}
.ellipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.login.about-page-wrapper .login-wrapper {
  border: 1px solid #BCC7D2;
  padding: 50px;
  .logo {
    margin-bottom: 20px;
    &.light-logo {
      display: block;
    }
    &.dark-logo {
      display: none;
    }
  }
  h3 {
    margin-bottom: 40px;
  }
  p {
    margin-bottom: 10px;
  }
  .btn-style {
    margin-top: 20px;
    min-width: 250px;
    &:hover, &:focus {
      background: $primary01 !important;
      border-color: $primary01 !important;
    }
    &.mt-80 {
      margin-top: 80px;
    }
  }
}
.mt-100 {
  margin-top: 100px;
}
.dashboard-new-page {
  .drive-main-wrapper {
    &:first-of-type {
      padding-top: 30px;
    }
    &:not(:last-of-type) {
      margin-bottom: 50px;
    }
    > h2 {
      font-size: 24px;
      line-height: 34px;
      font-weight: 700;
      margin-bottom: 16px;
    }
    .drive-wrapper {
      display: grid;
      grid-template-columns: repeat(5, 312px);
      align-items: center;
      gap: 24px;
      @media screen and (max-width: 1899px) and (min-width: 1750px) {
        grid-template-columns: repeat(5, 312px);
      }
      @media screen and (max-width: 1749px) and (min-width: 1414px) {
        grid-template-columns: repeat(4, 312px);
      }
      @media screen and (max-width: 1415px) and (min-width: 1200px) {
        grid-template-columns: repeat(3, 312px);
      }
      @media screen and (max-width: 1199px) and (min-width: 992px) {
        grid-template-columns: repeat(3, 286px);
      }
      @media screen and (max-width: 991px) and (min-width: 768px) {
        grid-template-columns: repeat(2, 312px);
      }
      @media screen and (max-width: $screen-xs) {
        display: block;
      }
      .drive-card {
        border-radius: 8px;
        border: 1px solid #BCC7D2;
        background: $white;
        box-shadow: 0 10px 28px 0 rgba(43, 64, 83, 0.14);
        padding: 18px 18px 18px 18px;
        position: relative;
        min-height: 178px;
        @media screen and (max-width: $screen-xs) {
          margin: 0 0 15px;
        }
        &.add-drive-card {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border: 2px dashed #bcc7d2;
          user-select: none;
          .add-wrapper {
            text-align: center;
            img {
              max-width: 40px;
              filter: grayscale(1);
              transition: .5s;
            }
            p {
              margin: 8px 0 0;
              font-size: 18px;
              font-weight: 600;
              line-height: 20px;
            }
          }
          &:hover {
            img {
              filter: grayscale(0);
            }
          }
        }
        .header {
          margin-bottom: 45px;
          span.heading {
            color: $text-primary01;
            display: block;
            font-size: 12px;
          }
          .title {
            display: flex;
            align-items: center;
            gap: 12px;
            img {
              max-width: 20px;
            }
            p {
              margin-bottom: 0;
              font-size: 14px;
              line-height: 24px;
              font-weight: 600;
              color: #4E6174;
            }
          }
          .menu-dots {
            img {
              cursor: pointer;
            }
            .ant-tooltip.ant-tooltip-placement-bottomRight {
              z-index: 999;
              .ant-tooltip-content {
                .ant-tooltip-inner {
                  padding: 0;
                  border: none;
                  outline: none;
                  box-shadow: none;
                  background: transparent;
                }
              }
            }
            .ant-tooltip.ant-tooltip-placement-topLeft {
              .ant-tooltip-content {
                .ant-tooltip-inner {
                  background: rgb(255, 255, 255);
                  color: #667085;
                  border: 1px solid #BCC7D2;
                  padding: 6px 8px;
                  outline: none;
                  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
                }
              }
            }
            .menu-wrapper {
              border-radius: 6px;
              background: #FFF;
              box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
              padding: 8px;
              width: 224px;
              ul.menu {
                text-align: left;
                li {
                  display: block;
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 16px;
                  color: #4E6174;
                  .anticon svg {
                    fill: $primary01;
                  }
                  &:not(:last-of-type) {
                    margin-bottom: 5px;
                  }
                  &:hover {
                    a {
                      background: #ebedf1;
                    }
                  }
                  &:first-of-type {
                    cursor: auto;
                    a {
                      cursor: auto;
                    }
                    &:hover {
                      a {
                        background: #fff;
                      }
                    }
                  }
                  &.switch {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 8px;
                    margin-bottom: 8px;
                    .ant-popover {
                      .ant-popover-inner {
                        width: 300px;
                        .ant-popconfirm-buttons {
                          button {
                            &:first-of-type {
                              border-color: $primary01;
                              span {
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 16px;
                                color: $primary01;
                              }
                            }
                            &:last-of-type {
                              background: transparent;
                              border: 1px solid #d9d9d9;
                            }
                          }
                        }
                      }
                    }
                  }
                  a {
                    padding: 5px 8px;
                    display: block;
                    border-radius: 5px;
                    transition: all .3s ease-in-out;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 16px;
                    color: #4E6174;
                  }
                }
              }
            }
          }
        }
        .body {
          display: grid;
          grid-template-columns: auto 80px;
          column-gap: 10px;
          align-items: self-end;
          .content {
            h3 {
              font-size: 20px;
              line-height: 30px;
              font-weight: 600;
              margin-bottom: 8px;
              color: $text-primary05;
            }
            span {
              display: block;
              font-size: 14px;
              line-height: 24px;
              font-weight: 500;
              color: #4E6174;
              &.cd-sync {
                cursor: pointer;
                &.heading {
                  margin-bottom: 0;
                }
              }
              &.heading {
                color: $text-primary01 !important;
                display: block;
                font-size: 12px !important;
                margin-bottom: -8px;
                font-weight: 400;
              }
            }
            P {
              font-size: 16px;
              line-height: 24px;
              font-weight: 400;
              margin-bottom: 0;
              color: #4E6174;
              &.smaller {
                font-size: 12px;
                line-height: 12px;
                cursor: pointer;
                white-space: nowrap;
                width: 165px;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
          .open {
            a.btn-style {
              height: 27px;
              min-width: 80px;
              font-size: 12px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
.visually-hidden-new {
  opacity: 0;
}
.width-150 {
  width: 150px;
}
.less-width-150 {
  width: calc(33.33333333% - 150px);
}
.ant-collapse-item-active {
  .login-accounts {
    opacity: 0 !important;
  }
}
.not-bold {
  font-weight: 400 !important;
}
.dashboard-amove-storage {
  .red-error {
    display: block;
    margin-top: 30px !important;
  }
  .new-title {
    text-align: left;
    position: relative;
    top: -20px;
    margin-bottom: 0 !important;
    margin-left: 35px;
  }
  &.create-team {
    max-width: 462px;
    background: #fff;
    padding: 38px 60px 30px;
    position: fixed;
    top: 62px;
    bottom: 0;
    height: 360px;
    margin: auto;
    border: 1px solid #BCC7D2;
    border-radius: 3px !important;
    @media screen and (max-width: $screen-xs) {
      z-index: 999;
      width: calc(100% - 30px) !important;
      left: 50%;
      transform: translateX(-50%);
      height: 430px !important;
      max-width: 400px !important;
    }
    &.amove-region-popup {
      height: 240px !important;
      @media screen and (max-width: $screen-xs) {
      }
      .ant-select {
        //border-radius: 3px;
        .ant-select-selector {
          border: 1px solid #BCC7D2 !important;
        }
        .ant-select-dropdown {
          background: $white;
        }
      }
      .two-buttons {
        @media screen and (max-width: $screen-xs) {
          display: flex;
        }
      }
    }
    &.wider {
      max-width: 600px;
      height: 255px;
      padding: 38px 40px 30px;
    }
    h3.title {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 10px;
      margin-top: 10px;
    }
    &.step-n-1 {
      max-width: 638px;
      padding: 38px 40px 30px;
      height: 322px;
      width: 638px;
      .ant-radio-group {
        width: 610px;
        @media screen and (max-width: $screen-xs) {
          width: 100%;
          display: grid;
          grid-row-gap: 10px;
        }
      }
      .btn-style {
        margin-top: 30px;
      }
      .ant-radio-wrapper {
        span.ant-radio + * {
          padding-inline-start: 0;
        }
        /*  display: block;
          height: 30px;
          margin: 0;*/
        /* > span:not(.ant-radio) {
           height: 62px;
           display: block;
           padding: 0;
         }*/
        .ant-radio {
          opacity: 0;
          visibility: hidden;
          position: absolute;
          z-index: -1;
        }
      }
      .ant-radio-wrapper-checked {
        .box {
          border: 1px solid $blue;
        }
      }
      h3.title {
        margin-top: 0;
      }
      h2 {
        margin-bottom: 30px;
      }
    }
    .box {
      border: 1px solid $border-primary01;
      border-radius: 3px;
      padding: 10px;
      position: relative;
      top: 0;
      p {
        margin-bottom: 0;
      }
    }
    * {
      color: #000 !important;
    }
    h2 {
      color: #000 !important;
      font-weight: 400;
      font-size: 22px;
      line-height: 26px;
    }
    img {
      height: 24px;
      display: block;
      margin-bottom: 15px;
      position: relative;
      top: -6px;
    }
    .existing-cloud {
      display: block;
      margin-top: 15px;
      color: #000 !important;
    }
    .form {
      margin-bottom: 15px !important;
      .form-group .form-control {
        height: 40px;
        padding-top: 3px;
        color: #000 !important;
        background: #fff !important;
        border-radius: 3px !important;
        border-color: #0074d4 !important;
        &::placeholder {
          color: #000 !important;
        }
      }
    }
    .btn-style.blue, .btn-style.blue.disabled {
      background: #0074d4 !important;
      color: #fff !important;
      border-color: #0074d4 !important;
      height: 34px;
      font-size: 14px;
      font-weight: 600;
      border-radius: 3px !important;
      padding: 10px 10px 10px;
      &.orange {
        background: $primary01 !important;
        border-color: $primary01 !important;
      }
      span {
        color: #fff !important;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
  .small-text {
    visibility: hidden;
    &.show {
      visibility: visible;
    }
  }
  .mr-1 {
    margin-right: 10px;
  }
}
.create-storage-popup {
  .small-text {
    visibility: hidden;
    &.show {
      visibility: visible;
    }
  }
}
.sec-migration-status {
  p {
    color: #595959;
    font-size: 13px;
    line-height: 20px;
    strong {
      font-size: 13px;
      line-height: 20px;
      line-break: anywhere;
    }
  }
  h2 {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 34px;
    @media screen and (max-width: $screen-xs) {
      margin-bottom: 20px;
    }
  }
  .migration-wrapper {
    margin-bottom: 40px;
    @media screen and (max-width: $screen-xs) {
      margin-bottom: 30px;
    }
    .migration-card {
      box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
      padding: 20px 20px 15px;
      @media screen and (max-width: $screen-xs) {
        margin-bottom: 15px;
      }
      .status-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 10px;
        .icon-wrapper {
          img {
            max-width: 20px;
          }
          .anticon {
            font-size: 18px;
          }
        }
        .content {
          text-align: right;
          p {
            margin-bottom: 0;
            color: #595959;
          }
          strong {
            font-size: 30px;
            line-height: 40px;
            color: #595959;
            @media screen and (max-width: 991px) {
              font-size: 20px;
              line-height: 30px;
            }
          }
        }
      }
      .update-status {
        border-top: 1px solid #A9A9A9;
        padding: 15px 0 0;
        span, a {
          display: flex;
          align-items: center;
          justify-content: left;
          gap: 8px;
          p {
            margin-bottom: 0;
            color: #A9A9A9;
          }
          img {
            max-width: 20px;
            filter: brightness(0) saturate(100%) invert(71%) sepia(0%) saturate(1359%) hue-rotate(178deg) brightness(97%) contrast(86%);
          }
        }
      }
    }
  }
  .speed-wrapper {
    margin-bottom: 30px;
    > h3 {
      margin-bottom: 20px;
    }
    .speed-card {
      box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
      padding: 20px 20px 15px;
      &:not(:last-of-type) {
        margin-bottom: 10px;
        @media screen and (max-width: $screen-xs) {
          margin-bottom: 15px;
        }
      }
      .status-wrapper {
        margin-bottom: 4px;
        @media screen and (max-width: $screen-xs) {
          margin-bottom: 8px;
        }
        .path {
          p {
            font-weight: 600;
            margin-bottom: 0;
            @media screen and (max-width: $screen-xs) {
              margin-bottom: 8px;
            }
          }
        }
        .status {
          text-align: right;
          @media screen and (max-width: $screen-xs) {
            text-align: left;
          }
          p {
            margin-bottom: 0;
            font-weight: 500;
            span {
              font-weight: 500;
              color: #595959;
            }
          }
        }
      }
      .status-detail {
        p {
          margin-bottom: 4px;
          @media screen and (max-width: $screen-xs) {
            margin-bottom: 8px;
          }
          strong {
            color: #595959;
            font-weight: 700;
            &.green {
              color: #12AA5D;
            }
            &.orange {
              color: #F1AF6C;
            }
          }
        }
      }
    }
  }
}
.dropzone {
  min-height: calc(100vh - 360px);
}
.invite-user-link-bottom {
  grid-column-gap: 10px;
  align-items: center;
  column-gap: 8px;
  display: grid;
  grid-template-columns: 25px auto;
  padding: 10px 15px 7px;
  text-align: left;
  transition: all .3s ease-in-out;
  position: fixed;
  right: 7px;
  bottom: 0px;
  img {
    filter: unset !important;
  }
  &:hover {
    background: #F4F4F4;
  }
  .icon {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(151deg) brightness(102%) contrast(101%) !important;
    }
    &.purple {
      background: $primary01;
    }
    &.green {
      background: $blue;
    }
    &.blue {
      background: $purple;
    }
    &.gray {
      background: $lime;
      img {
        filter: unset !important;
      }
    }
    img {
      max-width: 18px;
    }
  }
  .content {
    strong {
      font-size: 15px !important;
      line-height: 22px !important;
      font-weight: 600 !important;
    }
  }
}
.invie-people-icon-left-sidebar {
  a.active {
    background: transparent !important;
  }
}
.no-menu-orange-hover {
  a.active, &:hover {
    background: transparent !important;
    a {
      background: transparent !important;
    }
  }
}
.encryption-tooltip {
  .ant-tooltip-content {
    width: 280px;
    .ant-tooltip-inner {
      padding: 15px;
    }
  }
}
.hide-arrow-icon-panel {
  .ant-collapse-expand-icon {
    display: none !important;
  }
}
.security-meter {
  width: 100%;
  position: absolute;
  bottom: 6px;
  span {
    display: inline-block;
    width: calc(20% - 4px);
    height: 5px;
    background: #ccc;
    margin-right: 5px;
    &.active {
      background: #36cf70;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
}
.form-group.sign-up-page {
  margin-top: 30px;
  margin-bottom: 40px !important;
}
.not-clickable {
  pointer-events: none;
  cursor: default;
}
.cloud-icon-50 {
  width: 50px;
}
.setting-connected-accounts {
  .connected-accounts-body {
    .table-body {
      .ant-collapse {
        .ant-collapse-header {
          .ant-collapse-expand-icon {
            @media screen and (max-width: $screen-xs) {
              right: -5px !important;
            }
          }
        }
        .ant-collapse-content {
          .ant-collapse-content-box {
            @media screen and (max-width: $screen-xs) {
              padding: 16px 0;
            }
            .table-body-card {
              .cloud-drive-modal-wrapper {
                .ant-tabs {
                  .ant-tabs-content-holder {
                    @media screen and (max-width: $screen-xs) {
                      padding: 0 !important;
                    }
                    .ant-tabs-content {
                      @media screen and (max-width: $screen-xs) {
                        padding: 15px 25px 5px !important;
                      }
                      .form {
                        .form-group {
                          @media screen and (max-width: $screen-xs) {
                            margin-bottom: 8px !important;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.onboarding-back {
  position: absolute;
  z-index: 1;
  top: 20px;
  span, a {
    color: #fff;
  }
  i {
    margin-right: 10px;
  }
}
.errors-wrapper {
  span {
    display: block;
  }
}
.storage-dashboard-main {
  &.bucket-dashboard-main {
    .storage-region {
      column-gap: 50px;
      grid-template-rows:1fr;
      grid-template-columns: auto 157px  1fr auto;
      grid-template-areas: "a b c d";
      @media screen and (max-width: $screen-xs) {
        display: block;
      }
      .btn-style {
        height: 28px;
        background: #0074D4 !important;
        border-color: #0074D4 !important;
        color: $white;
        border-radius: 8px;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        @media screen and (max-width: $screen-xs) {
          margin: 12px 0;
        }
        &.bucket-btn {
          max-width: 131px;
        }
      }
      .search-wrapper {
        grid-area: d;
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        column-gap: 20px;
      }
    }
    .enable-region-wrapper {
      .table-wrapper {
        .table-header {
          @media screen and (max-width: 1260px) {
            width: 1150px;
          }
        }
        .table-body {
          min-height: 300px;
          @media screen and (max-width: 1260px) {
            width: 1150px;
          }
        }
      }
    }
  }
  .storage-region {
    display: grid;
    grid-template-columns: auto 233px 233px;
    column-gap: 15px;
    align-items: center;
    justify-content: left;
    margin-bottom: 29px;
    @media screen and (max-width: $screen-xs) {
      grid-template-columns: auto;
      row-gap: 15px;
    }
    h3 {
      font-size: 24px;
      line-height: 34px;
      font-weight: 600;
      margin-bottom: 0;
    }
    .ant-select {
      height: 44px !important;
      @media screen and (max-width: $screen-xs) {
        text-align: left;
        width: 233px;
      }
      input {
        min-height: 44px !important;
        height: 44px !important;
        font-size: 13px !important;
        line-height: 18px !important;
      }
      .ant-select-selector {
        min-height: 44px !important;
        height: 44px !important;
        .ant-select-selection-item {
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
  }
  .dashboard-object-main {
    margin-bottom: 42px;
    > p {
      margin: 19px 0 0;
      font-size: 12px;
      line-height: 18px;
    }
    .object-wrapper {
      display: grid;
      grid-template-columns: repeat(2, calc(33% - 29px));
      align-items: center;
      column-gap: 49px;
      @media screen and (max-width: 991px) and (min-width: 768px) {
        grid-template-columns: repeat(2, calc(50% - 8px));
        column-gap: 15px;
      }
      @media screen and (max-width: $screen-xs) {
        grid-template-columns: auto;
        row-gap: 20px;
      }
      .object-card {
        border-radius: 12px;
        padding: 19px 18px 24px 35px;
        border: 1px solid #BCC7D2;
        height: 100%;
        @media screen and (max-width: 991px) and (min-width: 768px) {
          padding: 19px 18px 24px 20px;
        }
        .header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 4px;
          h3 {
            font-size: 20px;
            line-height: 30px;
            font-weight: 600;
            margin-bottom: 0;
            @media screen and (max-width: 991px) and (min-width: 768px) {
              font-size: 18px;
              line-height: 26px;
            }
          }
          .icon-wrapper {
            width: 45px;
            height: 45px;
            border-radius: 50%;
            background: rgba(234, 73, 3, .25);
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              max-width: 24px;
            }
          }
        }
        .body {
          min-height: 45px;
          strong {
            font-size: 45px;
            line-height: 55px;
            font-weight: 600;
            margin-bottom: 0;
            color: $black;
            @media screen and (max-width: 1199px) and (min-width: 992px) {
              font-size: 35px;
              line-height: 45px;
            }
            @media screen and (max-width: 991px) and (min-width: 768px) {
              font-size: 25px;
              line-height: 35px;
            }
            @media screen and (max-width: $screen-xs) {
              font-size: 35px;
              line-height: 45px;
            }
          }
        }
        .footer {
          text-align: right;
          .btn-style {
            height: 26px;
            background: #0074D4 !important;
            border-color: #0074D4 !important;
            color: $white;
            border-radius: 8px;
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }
  }
  .enable-region-wrapper {
    > h3 {
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
      margin-bottom: 16px;
    }
    .btn-style {
      height: 26px;
      background: #0074D4 !important;
      border-color: #0074D4 !important;
      color: $white;
      border-radius: 8px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
    }
    .table-wrapper {
      margin-bottom: 15px;
      overflow-x: auto;
      @include scrollbarSmall;
      .grid-3 {
        display: grid;
        align-items: center;
        column-gap: 10px;
        grid-template-columns: 200px 300px auto;
      }
      .grid-4 {
        display: grid;
        align-items: center;
        column-gap: 10px;
        grid-template-columns: 200px 300px 300px auto;
      }
      .grid-7 {
        display: grid;
        align-items: center;
        column-gap: 10px;
        grid-template-columns: 250px 170px 160px 200px 80px 150px;
        justify-content: space-between;
      }
      .grid-6 {
        display: grid;
        align-items: center;
        column-gap: 10px;
        grid-template-columns: 250px 270px 190px 100px 190px 80px;
        justify-content: space-between;
      }
      .table-header {
        //background: #383838;
        background: #EEEEEE;
        padding: 13px 9px 14px;
        @media screen and (max-width: $screen-xs) {
          width: 600px;
        }
        p {
          margin-bottom: 0;
          font-size: 12px;
          line-height: 18px;
          display: inline-flex;
          align-items: center;
          gap: 8px;
          &:first-of-type {
            padding-left: 21px;
          }
        }
      }
      .table-body {
        @media screen and (max-width: $screen-xs) {
          width: 600px;
        }
        p {
          margin-bottom: 0;
          font-size: 13px;
          line-height: 18px;
        }
        .table-row {
          padding: 12px 9px;
          border-bottom: 1px solid #3A3A3A;
          transition: all .3s ease-in-out;
          &:hover {
            background: #F6F6F6;
            //background: #474545;
          }
          .table-item {
            .name-wrapper {
              display: flex;
              align-items: center;
              gap: 6px;
              img {
                max-width: 14px;
              }
              p {
              }
            }
            .copy-wrapper {
              display: flex;
              align-items: center;
              gap: 8px;
              img {
                filter: brightness(0) saturate(100%) invert(0%) sepia(96%) saturate(0%) hue-rotate(103deg) brightness(100%) contrast(103%);
              }
              p {
              }
            }
            ul.options {
              li {
                display: inline-block;
                &:not(:last-of-type) {
                  margin: 0 8px 0 0;
                }
                a {
                  img {
                    max-width: 15px;
                    filter: brightness(0) saturate(100%) invert(0%) sepia(96%) saturate(0%) hue-rotate(103deg) brightness(100%) contrast(103%);
                  }
                }
              }
            }
          }
        }
      }
    }
    .new-region-wrapper {
      display: flex;
      align-items: center;
      justify-content: left;
      gap: 25px;
      @media screen and (max-width: $screen-xs) {
        display: block;
      }
      .ant-select {
        max-width: 150px;
        height: 30px !important;
        @media screen and (max-width: 991px) {
          margin: 10px 0 0 20px;
          text-align: left;
        }
        input {
          min-height: 30px !important;
          height: 30px !important;
          font-size: 13px !important;
          line-height: 18px !important;
        }
        .ant-select-selector {
          min-height: 30px !important;
          height: 30px !important;
          .ant-select-selection-item {
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
      &.long-select {
        .ant-select {
          max-width: 215px;
        }
      }
      .brand-icon {
        width: 120px;
        height: 30px;
        background: $dark-primary02;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 991px) and (min-width: 768px) {
          margin: 12px auto 0 0;
        }
        @media screen and (max-width: $screen-xs) {
          margin: 12px auto 0;
        }
        img {
          max-width: 75px;
        }
        span {
          color: $primary01 !important;
          margin-right: 6px;
        }
      }
    }
  }
}
.bucket-main-modal {
  max-width: 712px;
  width: 100% !important;
  .ant-modal-content {
    border-radius: 6px;
    padding: 0;
    @media screen and (max-width: $screen-xs) {
      margin: 0 15px;
    }
    .ant-modal-close {
      display: none;
    }
    .ant-modal-header, .ant-modal-footer {
      display: none;
    }
    .ant-modal-body {
      .bucket-modal-header {
        padding: 21px 43px 15px;
        margin-bottom: 44px;
        border-bottom: 1px solid #444444;
        @media screen and (max-width: $screen-xs) {
          margin-bottom: 30px;
          padding: 21px 25px 15px;
        }
        .name-wrapper {
          display: flex;
          align-items: center;
          gap: 11px;
          img {
            max-width: 20px;
            filter: brightness(0) saturate(100%) invert(0%) sepia(97%) saturate(7358%) hue-rotate(181deg) brightness(82%) contrast(101%);
          }
          h3 {
            font-size: 18px;
            line-height: 28px;
            font-weight: 600;
            margin-bottom: 0;
          }
        }
        .cross {
          text-align: right;
          img {
            cursor: pointer;
          }
        }
      }
      .bucket-modal-body {
        padding: 0 40px;
        @media screen and (max-width: $screen-xs) {
          padding: 0 25px;
        }
        .form {
          max-width: 595px;
          margin: auto;
          .col-md-6 {
            &:nth-of-type(2n+1) {
              padding-left: 14px;
              padding-right: 20px;
            }
            &:nth-of-type(2n+2) {
              padding-left: 20px;
              padding-right: 14px;
            }
          }
          ul.bucket-options {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            margin: 0 0 45px;
            li {
              display: inline-block;
              .bucket-name {
                display: grid;
                grid-template-columns: 77px auto;
                column-gap: 9px;
                align-items: center;
                .icon-wrapper {
                  width: 76px;
                  height: 76px;
                  border-radius: 50%;
                  background: rgba(234, 73, 3, .25);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  img {
                    width: 38px;
                    height: 38px;
                  }
                }
                .content-wrapper {
                  p {
                    font-size: 10px;
                    line-height: 18px;
                    margin-bottom: 0;
                  }
                  h3 {
                    font-size: 16px;
                    line-height: 25px;
                    font-weight: 600;
                    margin: 0;
                  }
                  a.copy {
                    font-size: 12px;
                    line-height: 18px;
                    display: flex;
                    align-items: center;
                    gap: 6px;
                  }
                }
              }
              strong {
                font-size: 12px;
                line-height: 18px;
                color: #A1A1A1;
                display: block;
                P {
                  font-size: 12px;
                  line-height: 18px;
                  margin-bottom: 0;
                  display: inline-block;
                }
              }
            }
          }
          .form-group {
            label {
              display: block;
              font-size: 10px;
              line-height: 16px;
              font-weight: 400;
              margin-bottom: 4px;
            }
            p.label {
              font-size: 14px;
              line-height: 20px;
              margin: 0 0 6px;
            }
            p.small {
              margin: 15px 0 0;
              font-size: 10px;
              line-height: 16px;
            }
            .form-control {
              height: 26px;
              border-radius: 6px;
              font-size: 12px;
              line-height: 18px;
              font-weight: 400;
              //color: #646C7D!important;
              &::placeholder {
                color: #646C7D !important;
              }
              &.disabled {
                background: #4C4B4B !important;
                border-color: #4C4B4B;
              }
            }
            .ant-select {
              height: 26px !important;
              @media screen and (max-width: $screen-xs) {
                text-align: left;
                width: 233px;
              }
              input {
                min-height: 26px !important;
                height: 26px !important;
                font-size: 12px !important;
                line-height: 18px !important;
              }
              .ant-select-selector {
                min-height: 26px !important;
                height: 26px !important;
                .ant-select-selection-item {
                  font-size: 12px;
                  line-height: 18px;
                  //color: #646C7D!important;
                }
                .ant-select-selection-overflow {
                  height: 20px;
                  position: relative;
                  top: -7px;
                  overflow: hidden;
                  .ant-select-selection-overflow-item {
                    display: none !important;
                    &:nth-of-type(1), &:nth-of-type(2) {
                      display: inline-flex !important;
                    }
                  }
                }
              }
              .ant-select-selection-placeholder {
                font-size: 12px;
                line-height: 18px;
              }
            }
            .ant-radio-group {
              display: flex;
              align-items: center;
              gap: 8px;
              .ant-radio-wrapper {
                .ant-radio {
                  margin: 0 0 -3px;
                }
              }
            }
            .switch-card {
              .ant-switch {
                height: 18px;
                .ant-switch-handle {
                  height: 16px;
                  &:before {
                    height: 15px;
                    width: 15px;
                    right: -8px;
                    //top: -0.5px;
                  }
                }
              }
              .content {
                h3 {
                  font-size: 14px;
                  line-height: 20px;
                  font-weight: 400;
                  margin-bottom: 0;
                }
                p {
                  margin: 15px 0 0;
                  font-size: 10px;
                  line-height: 16px;
                }
              }
            }
            .checkbox-wrapper {
              display: flex;
              align-items: center;
              gap: 6px;
              margin-bottom: 8px;
              label {
                font-size: 12px;
                line-height: 18px;
                margin-bottom: 0;
              }
            }
          }
        }
        .trash-wrapper {
          margin-bottom: 50px;
          p {
            margin-bottom: 0;
          }
        }
        .new-key-wrapper {
          p {
            margin-bottom: 15px;
          }
          ul.options {
            margin-bottom: 40px;
            li {
              display: grid;
              grid-template-columns: 180px auto;
              &:not(:last-of-type) {
                margin-bottom: 16px;
              }
              strong {
                font-size: 14px;
                line-height: 20px;
                font-weight: 600;
                color: $black;
              }
              p {
                font-size: 13px;
                line-height: 19px;
                margin-bottom: 0;
                display: flex;
                align-items: center;
                gap: 8px;
                img {
                  filter: brightness(0) saturate(100%)
                }
              }
            }
          }
        }
      }
      .bucket-modal-footer {
        padding: 0 53px 27px;
        @media screen and (max-width: $screen-xs) {
          padding: 20px 25px 20px;
        }
        ul {
          text-align: right;
          li {
            &:not(:last-of-type) {
              margin: 0 11px 0 0;
            }
            .btn-style {
              height: 28px;
              background: #0074D4 !important;
              border-color: #0074D4 !important;
              color: $white;
              border-radius: 8px;
              font-size: 14px;
              line-height: 18px;
              font-weight: 600;
              .ant-btn-loading-icon {
                position: relative;
                top: -3px;
              }
              span {
                font-size: 14px;
                color: $white;
              }
              &.trash {
                background: #D71010 !important;
                border-color: #D71010 !important;
                span {
                  color: $white;
                }
              }
              &.transparent {
                background: transparent !important;
                color: #271e1b !important;
                border-color: #271e1b !important;
                span {
                  color: #271e1b;
                }
                &:hover {
                  color: #271e1b !important;
                  span {
                    color: #271e1b !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.min-height-182 {
  min-height: 182px;
}
.c2c-migration-page {
  .container-fluid {
    > h2 {
      @media screen and (max-width: $screen-xs) {
        font-size: 18px;
        line-height: 26px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .migration-page-wrapper {
    @media screen and (max-width: $screen-xs) {
      row-gap: 80px;
    }
    &:before {
      @media screen and (max-width: $screen-xs) {
        height: 1px;
        width: calc(100% - 100px);
        min-height: auto;
        top: 50%;
        transform: translateY(-50%);
        display: none;
      }
    }
    .col-md-6 {
      &:nth-of-type(1) {
        @media screen and (max-width: $screen-xs) {
          padding-right: 14px;
        }
      }
      &:nth-of-type(2) {
        @media screen and (max-width: $screen-xs) {
          padding-left: 14px;
        }
      }
    }
    .amove-migration-wrapper {
      position: relative;
      &:before {
        content: "";
        background: #BCC7D2;
        height: 1px;
        width: calc(100% - 100px);
        position: absolute;
        bottom: -30px;
        left: 50%;
        transform: translateX(-50%);
        display: none;
        @media screen and (max-width: $screen-xs) {
          display: block;
        }
      }
    }
  }
}
.progress-wrapper-transfer {
  padding-right: 50px;
  position: relative;
  .ant-progress {
    margin: 0 !important;
  }
  .close-icon {
    position: absolute;
    right: 15px;
    top: 0;
  }
  .ant-popover {
    padding-bottom: 0 !important;
    width: 327px !important;
    .ant-popover-arrow {
      display: none !important;
    }
  }
}
