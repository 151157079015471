@import "../fonts/fk-grotesk-neue/stylesheet.css";
@import "../fonts/reservation_wide/stylesheet.css";
$screen-xs: 767px;
$screen-xs1: 991px;
$neue: "FK Grotesk Neue Trial";
$res: "Reservation Wide";
$primary01: #EA4903;
$primary01: #FC6524FF;
$subscribe: #0074d4;
$primary02: #531A01;
$blue: #0074d4;
$purple: #9747FB;
$lime: #dcfe46;
//$text-primary01: #101828;
//$text-primary02: #101828;
$text-primary01: #000000;
$text-primary02: #000000;
$text-primary05: #262626;
$text-primary09: #3E3E4E;
$text-primary06: #373843;
$text-primary07: #8C8FA4;
$text-primary08: #3E3E4E;
$blue: #0074d4;
$darkPurple: #2b1c50;
//$text-primary03: #101828;
$text-primary03: #000000;
$text-primary04: #667085;
$scroll-bar-color: #667085;
$border-primary01: #BCC7D2;
$border-primary02: #BCC7D2;
$border-primary03: #ebedf1;
$dark-primary01: #1a1a1f;
$dark-primary02: #383838;
$dark-border01: #444444;
//$border-primary02: #EAECF0;
$green: #32d583;
$white: #ffffff;
$off-white: #F5F5F7;
$light-white: rgba(255, 255, 255, .5);
$black: #000000;
$light-black: rgba(0, 0, 0, .5);
$box-shadow01: 0 1px 2px rgba(16, 24, 40, 0.05);
$transition: all .4s ease-in-out;
//// New UI Variables
/* Variables  */
$white-mode-1: #FFFFFFFF;
$black-mode-1: #131316FF;
$orange-50-mode-1: #FFF5EDFF;
$orange-100-mode-1: #FFE8D4FF;
$orange-200-mode-1: #FFCEA9FF;
$orange-300-mode-1: #FFAB72FF;
$orange-400-mode-1: #FD7D3AFF;
$orange-500-mode-1: #FC6524FF;
$orange-600-mode-1: #ED3E09FF;
$orange-700-mode-1: #C42C0AFF;
$orange-800-mode-1: #9C2410FF;
$orange-900-mode-1: #7D2011FF;
$orange-950-mode-1: #440D06FF;
$shark-25-mode-1: #FBFBFCFF;
$shark-50-mode-1: #F7F7F8FF;
$shark-100-mode-1: #EDEDF1FF;
$shark-150-mode-1: #E2E3E9FF;
$shark-200-mode-1: #D7D8E0FF;
$shark-300-mode-1: #B5B6C4FF;
$shark-400-mode-1: #8C8FA4FF;
$shark-500-mode-1: #6E7189FF;
$shark-600-mode-1: #585971FF;
$shark-700-mode-1: #48495CFF;
$shark-800-mode-1: #3E3E4EFF;
$shark-900-mode-1: #373843FF;
$shark-950-mode-1: #202027FF;
$chartreuseyellow-50-mode-1: #FDFFE5FF;
$chartreuseyellow-100-mode-1: #F7FFC7FF;
$chartreuseyellow-200-mode-1: #EEFF95FF;
$chartreuseyellow-300-mode-1: #DCFE46FF;
$chartreuseyellow-400-mode-1: #CDF526FF;
$chartreuseyellow-500-mode-1: #AEDC06FF;
$chartreuseyellow-600-mode-1: #87B000FF;
$chartreuseyellow-700-mode-1: #658506FF;
$chartreuseyellow-800-mode-1: #51690BFF;
$chartreuseyellow-900-mode-1: #43580FFF;
$chartreuseyellow-950-mode-1: #233102FF;
$brownnightrider-50-mode-1: #F5F4F1FF;
$brownnightrider-100-mode-1: #E7E3DAFF;
$brownnightrider-200-mode-1: #D1C7B7FF;
$brownnightrider-300-mode-1: #B6A58EFF;
$brownnightrider-400-mode-1: #A0896FFF;
$brownnightrider-500-mode-1: #927960FF;
$brownnightrider-600-mode-1: #7D6351FF;
$brownnightrider-700-mode-1: #654E43FF;
$brownnightrider-800-mode-1: #56433DFF;
$brownnightrider-900-mode-1: #4C3B37FF;
$brownnightrider-950-mode-1: #271D1BFF;
$error-50-mode-1: #FFF2F1FF;
$error-100-mode-1: #FFE1DFFF;
$error-200-mode-1: #FFC9C5FF;
$error-300-mode-1: #FFA39DFF;
$error-400-mode-1: #FF6E64FF;
$error-500-mode-1: #FF4033FF;
$error-600-mode-1: #EF3225FF;
$error-700-mode-1: #C8190DFF;
$error-800-mode-1: #A5190FFF;
$error-900-mode-1: #891B13FF;
$error-950-mode-1: #4B0904FF;
$succes-50-mode-1: #EDFFF3FF;
$succes-100-mode-1: #D5FFE5FF;
$succes-200-mode-1: #AEFFCCFF;
$succes-300-mode-1: #6FFFA6FF;
$succes-400-mode-1: #33FF7EFF;
$succes-500-mode-1: #00E955FF;
$succes-600-mode-1: #00C243FF;
$succes-700-mode-1: #009837FF;
$succes-800-mode-1: #047730FF;
$succes-900-mode-1: #06612AFF;
$succes-950-mode-1: #003714FF;
$lightningyellow-50-mode-1: #FFFBEBFF;
$lightningyellow-100-mode-1: #FFF4C6FF;
$lightningyellow-200-mode-1: #FFE788FF;
$lightningyellow-300-mode-1: #FFD54AFF;
$lightningyellow-400-mode-1: #FFC327FF;
$lightningyellow-500-mode-1: #F99F07FF;
$lightningyellow-600-mode-1: #DD7702FF;
$lightningyellow-700-mode-1: #B75306FF;
$lightningyellow-800-mode-1: #943F0CFF;
$lightningyellow-900-mode-1: #7A340DFF;
$lightningyellow-950-mode-1: #461A02FF;
$electricviolet-50-mode-1: #FAF4FFFF;
$electricviolet-100-mode-1: #F2E6FFFF;
$electricviolet-200-mode-1: #E8D1FFFF;
$electricviolet-300-mode-1: #D6ADFFFF;
$electricviolet-400-mode-1: #BD78FFFF;
$electricviolet-500-mode-1: #A345FFFF;
$electricviolet-600-mode-1: #9024FCFF;
$electricviolet-700-mode-1: #7A11DFFF;
$electricviolet-800-mode-1: #6814B5FF;
$electricviolet-900-mode-1: #561192FF;
$electricviolet-950-mode-1: #39006DFF;
$blueribbon-50-mode-1: #EEF5FFFF;
$blueribbon-100-mode-1: #D8E9FFFF;
$blueribbon-200-mode-1: #BAD8FFFF;
$blueribbon-300-mode-1: #8AC0FFFF;
$blueribbon-400-mode-1: #539EFFFF;
$blueribbon-500-mode-1: #2B77FFFF;
$blueribbon-600-mode-1: #2460FCFF;
$blueribbon-700-mode-1: #0D3EE8FF;
$blueribbon-800-mode-1: #1233BBFF;
$blueribbon-900-mode-1: #153293FF;
$blueribbon-950-mode-1: #121F59FF;
html {
  scroll-behavior: smooth;
}


///
body {
  font-family: $neue;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: $text-primary03;
  &::-webkit-scrollbar {
    background-color: $white;
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    background-color: $white;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $scroll-bar-color;
    border-radius: 16px;
    border: 4px solid $white;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &.dark-theme-active {
    background: $dark-primary01;
    h1, h2, h3, h4, h5, h6, p, span, strong, a, div, td, label, .form-control, input {
      color: $white !important;
    }
    .title-wrapper h3 a:not(.btn-style) {
      color: $white !important;
    }
    &::-webkit-scrollbar {
      background-color: $dark-primary01;
      width: 16px;
    }
    &::-webkit-scrollbar-track {
      background-color: $dark-primary01;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $scroll-bar-color;
      border-radius: 16px;
      border: 4px solid $dark-primary01;
    }
    // Ai Search Start
    .ai-search-form {
      background: #373843;
      .files-wrapper {
        position: relative;
        max-width: 143px;
        width: 100%;
        &:before {
          background: #585971;
        }
        .file-btn {
          img {
            filter: brightness(0) saturate(100%) invert(95%) sepia(50%) saturate(1808%) hue-rotate(181deg) brightness(115%) contrast(82%);
          }
          p {
            color: #E2E3E9 !important;
          }
          ul.selected-files {
            li {
              a {
                span.more {
                  background: #440D06;
                  color: $primary01 !important;
                }
              }
            }
          }
        }
        .ant-popover {
          .ant-popover-content {
            .ant-popover-inner {
              background: #2C2C35;
              &::-webkit-scrollbar {
                background-color: #2C2C35;
              }
              &::-webkit-scrollbar-track {
                background-color: #2C2C35;
              }
              &::-webkit-scrollbar-thumb {
                border: 3px solid #2C2C35;
              }
              .ant-popover-inner-content {
                .files-popup {
                  .files {
                    &:not(:last-of-type) {
                      margin-bottom: 20px;
                      &:before {
                        background-color: #585971;
                      }
                    }
                    .ant-checkbox-wrapper {
                      &.ant-checkbox-wrapper-checked {
                        background-color: #373843;
                        span {
                          &.ant-checkbox {
                            .ant-checkbox-inner {
                              background-color: $primary01 !important;
                              border-color: $primary01 !important;
                              &:after {
                                filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(176deg) brightness(99%) contrast(105%);
                              }
                            }
                          }
                          &:not(.ant-checkbox) {
                            color: $primary01 !important;
                          }
                        }
                      }
                      span {
                        &.ant-checkbox {
                          .ant-checkbox-inner {
                            background: #373843 !important;
                            border-color: #585971 !important;
                          }
                        }
                        &:not(.ant-checkbox) {
                          color: #E2E3E9 !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .form {
        .form-group {
          .form-control {
            background: #373843 !important;
            color: #8C8FA4 !important;
            &::placeholder {
              color: #8C8FA4 !important;
            }
          }
        }
      }
      .calendar-wrapper {
        a {
          img {
            filter: brightness(0) saturate(100%) invert(95%) sepia(50%) saturate(1808%) hue-rotate(181deg) brightness(115%) contrast(82%);
          }
          p {
            color: #E2E3E9 !important;
          }
        }
      }
      .meta-search {
        background: #2C2C35;
        .meta-search-wrapper {
          > p.title {
            color: #B5B6C4 !important;
          }
          ul.searched-queries {
            &::-webkit-scrollbar {
              background-color: #2C2C35;
            }
            &::-webkit-scrollbar-track {
              background-color: #2C2C35;
            }
            &::-webkit-scrollbar-thumb {
              border: 3px solid #2C2C35;
            }
            li {
              &.selected {
                a {
                  background: #373843;
                }
              }
              a {
                color: #F7F7F8 !important;
                img {
                  filter: brightness(0) saturate(100%) invert(85%) sepia(1%) saturate(75%) hue-rotate(188deg) brightness(109%) contrast(110%);
                }
              }
            }
          }
          .guide-options-wrapper{
            background: #373843;
            .universal-search{
              a.search-link{
                color: $primary01!important;
              }
            }
          }
          ul.guide-options {
            background: #373843;
            li {
              a {
                img {
                  max-width: 40px;
                }
                span {
                  color: #8C8FA4 !important;
                }
              }
            }
          }
        }
      }
      .select-date {
        background: #2C2C35;
        .select-date-wrapper {
          .btn-wrapper{
            .btn-style:not(.orange-btn){
              border-color: $primary01!important;
              color: $primary01!important;
              background: transparent!important;
            }
          }
          .rdrCalendarWrapper {
            background: #2C2C35;
            .rdrDateDisplayWrapper {
              .rdrDateDisplay {
                .rdrDateInput {
                  background: #373843;
                  input {
                    color: #8C8FA4;
                    &::placeholder {
                      color: #8C8FA4;
                    }
                  }
                }
              }
            }
            .rdrMonthAndYearWrapper {
              .rdrMonthAndYearPickers {
                span {
                  select {
                    background: #2c2c35 !important;
                    color: $white;
                  }
                }
              }
              button {
                border: 1px solid #D7D8E0;
                i {
                  filter: brightness(0) saturate(100%) invert(97%) sepia(4%) saturate(923%) hue-rotate(190deg) brightness(87%) contrast(104%);
                }
              }
            }
            .rdrMonths {
              .rdrMonth {
                .rdrWeekDays {
                  span {
                    color: $shark-500-mode-1 !important;
                  }
                }
                .rdrDays {
                  .rdrDay {
                    &.rdrDayHovered {
                      span {
                        border-color: $primary01;
                      }
                    }
                    span {
                      border-color: $primary01;
                      &.rdrInRange + .rdrDayNumber span {
                        color: #373843;
                      }
                      &.rdrInRange {
                        background: $orange-900-mode-1;
                      }

                      &.rdrEndEdge:not(.rdrStartEdge){
                        &:before{
                          background: $orange-900-mode-1;
                        }
                      }
                      &.rdrStartEdge:not(.rdrEndEdge){
                        &:before{
                          background: $orange-900-mode-1;
                        }
                      }
                      &.rdrStartEdge, &.rdrEndEdge {
                        background: $primary01;
                        border-radius: 13px;
                      }
                      &.rdrEndEdge ~ .rdrDayNumber span {
                        color: $white !important;
                      }
                      &.rdrStartEdge ~ .rdrDayNumber span {
                        color: $white !important;
                      }
                      .rdrDay.rdrStartEdge .rdrDayNumber span,
                      .rdrDay.rdrEndEdge .rdrDayNumber span {
                        color: $white !important;
                      }
                      &.rdrDayNumber {
                        span {
                          color: $white !important;
                          &:after {
                            background: $primary01;
                          }
                        }
                      }
                    }
                    &.rdrDayPassive {
                      .rdrDayNumber {
                        span {
                          color: #8C8FA4 !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .ai-search-main {
      .ai-search-wrapper {
        .ai-search-card {
          h3.title {
            strong {
              color: $primary01 !important;
            }
          }
          .filter-form {
            .filter-item {
              .filter-icon {
                img {
                  filter: brightness(0) saturate(100%) invert(95%) sepia(50%) saturate(1808%) hue-rotate(181deg) brightness(115%) contrast(82%);
                }
              }
              .filter-btn {
                .btn-style {
                  background: $primary01 !important;
                  border-color: $primary01 !important;
                }
              }
            }
          }
          ul.searched-tags {
            li {
              a {
                color: $primary01 !important;
                background: #2C2C35;
                border: 2px solid #48495C;
              }
            }
          }
        }
        .filter-cloud {
          background: #2C2C35;
          box-shadow: 1px 0 4px 2px rgba(0, 0, 0, 0.2);
          .filter-cloud-wrapper {
            .form {
              .form-group {
                .form-control {
                  background: #373843 !important;
                  color: #8C8FA4 !important;
                  &::placeholder {
                    color: #8C8FA4 !important;
                  }
                }
              }
            }
            .cloud-card {
              &:not(:last-of-type) {
                border-bottom: 1px solid #48495C;
              }
              > p.title {
                color: #B5B6C4 !important;
              }
              .ant-checkbox-wrapper {
                &.ant-checkbox-wrapper-checked {
                  background-color: #373843;
                  span {
                    &.ant-checkbox {
                      .ant-checkbox-inner {
                        background-color: $primary01 !important;
                        border-color: $primary01 !important;
                        &:after {
                          filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(176deg) brightness(99%) contrast(105%);
                        }
                      }
                    }
                    &:not(.ant-checkbox) {
                      color: $primary01 !important;
                    }
                  }
                }
                span {
                  &.ant-checkbox {
                    .ant-checkbox-inner {
                      background: #373843 !important;
                      border-color: #585971 !important;
                    }
                  }
                  &:not(.ant-checkbox) {
                    color: #E2E3E9 !important;
                  }
                }
              }
            }
            .btn-wrapper{
              .btn-style:not(.orange-btn){
                border-color: $primary01!important;
                color: $primary01!important;
                background: transparent!important;
              }
            }
          }
        }
      }
    }
    .search-result-main {
      .search-result-wrapper {
        .search-result-header {
          ul.options {
            li {
              .select-card {
                .select-wrapper {
                  .icon {
                  }
                  .ant-select {
                    .ant-select-selector {
                      background: #373843;
                    }
                  }
                }
              }
              a {
                img {
                  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(209deg) brightness(121%) contrast(113%);
                }
              }
            }
          }
        }
        .search-result-body {
          .result-body-card {
            .result-card-wrapper {
              .result-card-main {
                input[type=checkbox]:checked + label.result-card {
                  border-color: $primary01;
                  &:before {
                    display: block;
                  }
                  .img-wrapper {
                    background: #373843;
                  }
                  .content-wrapper {
                    background: $text-primary09;
                  }
                }
                .result-card {
                  background: #373843;
                  .content-wrapper {
                    background: $text-primary09;
                  }
                }
              }
            }
          }
          .result-table-main {
            .table-wrapper {
              .table-header {
                background: transparent;
                p {
                  color: #8C8FA4 !important;
                }
              }
              .table-body {
                .table-row {
                  border-bottom: 1px solid #373843;
                  &:hover {
                    background: #373843;
                  }
                  .table-item {
                  }
                }
              }
            }
          }
        }
      }
    }
    .ai-tooltip-v1 {
      > a {
        img {
          filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(209deg) brightness(121%) contrast(113%) !important;
        }
      }
      .ant-tooltip {
        .ant-tooltip-content {
          .ant-tooltip-inner {
            background: #373843 !important;
          }
        }
      }
    }
    .file-name-sidebar {
      background: #373843;
      .file-name-wrapper {
        .title-wrapper {
          img {
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7475%) hue-rotate(327deg) brightness(90%) contrast(95%);
          }
        }
        p {
          &.orange {
            color: $primary01 !important;
          }
        }
        ul.btn-list {
          li {
            .btn-style {
              &.text {
                color: #E2E3E9 !important;
                img {
                  filter: brightness(0) saturate(100%) invert(97%) sepia(2%) saturate(668%) hue-rotate(194deg) brightness(89%) contrast(105%);
                }
              }
              &:not(.text) {
                background: #FDFDFE;
                border-color: #FDFDFE;
                color: #2C2C35 !important;
                img {
                  filter: brightness(0) saturate(100%) invert(15%) sepia(8%) saturate(1032%) hue-rotate(202deg) brightness(94%) contrast(91%);
                }
              }
            }
          }
        }
        .access-wrapper {
          ul.users {
            li {
              &:first-of-type {
                &:before {
                  background: $shark-500-mode-1;
                }
              }
              a {
                span.count {
                  color: #fff;
                  background: $orange-900-mode-1;
                }
              }
            }
          }
        }
        .tags-wrapper {
          ul.tags {
            li {
              a {
                color: #B5B6C4 !important;
                background: #48495C;
              }
            }
          }
          .form {
            .form-group {
              .form-control {
                background: #373843 !important;
                color: #8C8FA4 !important;
                &::placeholder {
                  color: #8C8FA4 !important;
                }
              }
            }
          }
        }
      }
    }
    .ant-drawer {
      .ant-drawer-mask {
      }
      .ant-drawer-content-wrapper {
        .ant-drawer-content {
          .ant-drawer-wrapper-body {
            .ant-drawer-body {
              .ai-drawer-main {
                .ai-left-sidebar {
                  background: #373843;
                  .ai-sidebar-wrapper {
                    .title-wrapper {
                      .back-icon {
                        img {
                          filter: brightness(0) saturate(100%) invert(100%) sepia(98%) saturate(1%) hue-rotate(258deg) brightness(107%) contrast(100%);
                        }
                      }
                    }
                    .ai-search {
                      .form-group {
                        .form-control {
                          background: #48495C !important;
                          color: #8C8FA4 !important;
                          &::placeholder {
                            color: #8C8FA4 !important;
                          }
                        }
                        .icon {
                          filter: brightness(0) saturate(100%) invert(60%) sepia(11%) saturate(457%) hue-rotate(195deg) brightness(93%) contrast(89%);
                        }
                      }
                    }
                    .ai-tags-wrapper {
                      ul.ai-tags {
                        li {
                          a {
                            color: #B5B6C4 !important;
                            background: #48495C;
                          }
                        }
                      }
                    }
                    .ai-copy-wrapper {
                      .copy-title {
                        a.copy-icon {
                          img {
                            filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
                          }
                        }
                      }
                    }
                  }
                }
                .ai-right-sidebar {
                  background: #373843;
                  .ai-sidebar-wrapper {
                    .title-header {
                      .cross-icon {
                        img {
                          filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
                        }
                      }
                    }
                    .cutted-clipped {
                      .create-clip {
                        a {
                          color: $primary01 !important;
                        }
                      }
                      .cutted-clipped-wrapper {
                        .clipped-card {
                          .clip-detail {
                            .img-wrapper {
                            }
                            .content {
                              span.count {
                                color: #131316 !important;
                                background: #EDEDF1;
                              }
                            }
                          }
                          .clip-options {
                            a {
                              img {
                                filter: brightness(0) saturate(100%) invert(92%) sepia(2%) saturate(1366%) hue-rotate(199deg) brightness(81%) contrast(86%);
                              }
                            }
                          }
                        }
                      }
                    }
                    // not done
                    .file-name-wrapper {
                      .title-wrapper {
                        .cross-icon {
                          img {
                            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(192deg) brightness(112%) contrast(110%);
                          }
                        }
                      }
                      ul.btn-list {
                        li {
                          .btn-style {
                            &.text {
                              color: #E2E3E9 !important;
                              img {
                                filter: brightness(0) saturate(100%) invert(97%) sepia(2%) saturate(668%) hue-rotate(194deg) brightness(89%) contrast(105%);
                              }
                            }
                            &:not(.text) {
                              background: #FDFDFE;
                              border-color: #FDFDFE;
                              color: #2C2C35 !important;
                              img {
                                filter: brightness(0) saturate(100%) invert(15%) sepia(8%) saturate(1032%) hue-rotate(202deg) brightness(94%) contrast(91%);
                              }
                            }
                          }
                        }
                      }
                      .tags-wrapper {
                        ul.tags {
                          li {
                            a {
                              color: #B5B6C4 !important;
                              background: #48495C;
                            }
                          }
                        }
                        .select-wrapper {
                          .ant-select {
                            .ant-select-selector {
                              background: #48495C;
                              span {
                                color: #8C8FA4 !important;
                                &.ant-select-selection-item {
                                  color: #373843;
                                }
                              }
                            }
                          }
                        }
                        .comment-wrapper {
                          .comment-card {
                            .content {
                              ul.option {
                                li {
                                  span.count {
                                    color: #131316 !important;
                                    background: #EDEDF1;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                .ai-main-content {
                  background: #1A1A1F;
                  &::-webkit-scrollbar {
                    background-color: #1A1A1F;
                  }
                  &::-webkit-scrollbar-track {
                    background-color: #1A1A1F;
                  }
                  &::-webkit-scrollbar-thumb {
                    border: 3px solid #1A1A1F;
                  }
                  ul.tool-menu {
                    li {
                      a {
                        color: #F7F7F8 !important;
                        img {
                          filter: brightness(0) saturate(100%) invert(100%) sepia(3%) saturate(4899%) hue-rotate(332deg) brightness(110%) contrast(98%);
                        }
                      }
                    }
                  }
                  .transcript-wrapper {
                    background: #373843;
                    .transcript-header {
                      .content {
                        span {
                          color: #8C8FA4 !important;
                        }
                      }
                      a {
                        &.edit-icon {
                          img {
                            filter: brightness(0) saturate(100%) invert(100%) sepia(93%) saturate(0%) hue-rotate(201deg) brightness(106%) contrast(106%);
                          }
                        }
                        &.translate {
                          color: #585971 !important;
                          img {
                            filter: brightness(0) saturate(100%) invert(34%) sepia(5%) saturate(2250%) hue-rotate(200deg) brightness(96%) contrast(85%);
                          }
                        }
                      }
                    }
                    .transcript-body {
                      ul.transcript {
                        li {
                          p {
                            &.time-tag {
                              color: $primary01 !important;
                            }
                          }
                        }
                      }
                      ul.identified {
                        li {
                          .identified-card {
                            .cross-icon {
                              width: 17px;
                              height: 17px;
                              border-radius: 50%;
                              background: white;
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              img {
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .edit-clip-modal {
      .ant-modal-content {
        background: #2C2C35 !important;
        .ant-modal-body {
          .edit-clip-wrapper {
            .clip-header {
              .cross-icon {
                img {
                  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(192deg) brightness(112%) contrast(110%);
                }
              }
            }
            .clip-footer {
              .clip-time {
                ul.time-count {
                  li {
                    .count {
                      color: #131316 !important;
                      background: #EDEDF1;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .activate-ai-modal{
      .ant-modal-content{
        background: #2C2C35;
        .ant-modal-close{
          .ant-modal-close-x{
            .anticon{
              svg{
                path{
                  fill: $white;
                }
              }
            }
          }
        }
        .ant-modal-body{
          .ant-popover{
            .ant-popover-content{
              .ant-popover-inner{
                background: #2C2C35;
                border: 1px solid #373843;
                .ant-popover-inner-content{
                  .ai-popover{
                    .popover-footer{
                      .btn-style{
                        &.transparent{
                          &:hover{
                            background: transparent;
                          }
                          &.black{
                            &:hover{
                              background: transparent;
                              border-color: $white!important;
                              span{
                                color: $white!important;
                              }
                            }
                          }
                          &.orange{
                            border-color: $primary01!important;
                            color: $primary01!important;
                            span{
                              color: $primary01!important;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .activate-ai-wrapper{
            .form {
              .form-group {
                .form-control {
                  background: #373843!important;
                  color: #8C8FA4!important;
                  &::placeholder {
                    color: #8C8FA4!important;
                  }
                }
                .icon {
                  filter: brightness(0) saturate(100%) invert(60%) sepia(8%) saturate(599%) hue-rotate(195deg) brightness(93%) contrast(92%);
                }
              }
            }
            .activate-cloud-main{
              .activate-cloud-wrapper{
                .cloud-item-body-wrapper{
                  &::-webkit-scrollbar {
                    background-color: #2C2C35;
                  }
                  &::-webkit-scrollbar-track {
                    background-color: #2C2C35;
                  }
                  &::-webkit-scrollbar-thumb {
                    background-color: $primary01;
                    border: 4px solid #2C2C35;
                  }
                }
                .cloud-item{
                  background: #2C2C35;
                  border-bottom: 1px solid #373843;
                  &.header{
                    background: #373843;
                    p{
                      color: #6E7189;
                    }
                  }
                  p{
                    color: #48495C;
                  }
                  .switch-wrapper{
                    .ant-switch {
                      border: 1px solid #373843!important;
                    }
                  }
                }
              }
            }
            .ai-footer{
              .ai-pagination{
                li{
                  img{
                    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7321%) hue-rotate(252deg) brightness(102%) contrast(100%);
                  }
                  .count{
                    background: #2C2C35;
                  }
                }
              }
              .btn-wrapper{
                text-align: right;
              }
            }
          }
        }
      }
    }
    // Ai Search End
    // Onboarding Page
    .onboarding-screen-main {
      .onboarding-step {
        > h2 {
          color: $white;
          strong {
            color: $primary01 !important;
          }
        }
        h3 {
          color: $white;
          strong {
            color: $primary01 !important;
          }
        }
        p {
          &.gray {
            color: #8C8FA4 !important;
          }
        }
        .btn-footer {
          .btn-style {
            background: $primary01 !important;
            border-color: $primary01 !important;
            color: $white !important;
            &:hover {
              opacity: .8;
              span {
                color: $white !important;
              }
            }
            &.disabled {
              background: $text-primary09 !important;
              color: $shark-500-mode-1 !important;
              border-color: $text-primary09 !important;
              span {
                color: $shark-500-mode-1 !important;
              }
            }
            &.transparent {
              border-color: $white !important;
              color: $white !important;
              background: transparent !important;
              span {
                color: $white !important;
              }
            }
          }
        }
        .pricing-wrapper {
          .pricing-card {
            background: #373843;
            p {
              &.price {
                color: $primary01 !important;
              }
            }
            ul.feature {
              li {
                color: $white;
                &:before {
                  background: $white;
                }
              }
            }
          }
        }
        .choose-cloud-wrapper {
          .ant-radio-group {
            .ant-radio-wrapper {
              &.ant-radio-wrapper-checked {
                .cloud-card {
                  .title-wrapper {
                    .circle {
                      background: $primary01;
                      border: 4px solid $text-primary09;
                    }
                  }
                }
              }
              .cloud-card {
                background: #373843;
                .title-wrapper {
                  .circle {
                    background: $text-primary09;
                    border: 1px solid #585971;
                  }
                }
              }
            }
          }
        }
        .choose-region-wrapper {
          .region-card {
            .ant-select {
              .ant-select-selector {
                background: #373843;
                .ant-select-selection-item {
                  color: #8C8FA4 !important;
                }
              }
            }
          }
        }
      }
    }
    .page-loading {
      background: $dark-primary01;
    }
    .search-file-wrapper .upload-file-detail-wrapper .upload-body .table-wrapper .table-data .row {
      background: $dark-primary01;
    }
    img[alt='Amove'] {
      filter: brightness(0) saturate(100%) invert(37%) sepia(66%) saturate(5276%) hue-rotate(7deg) brightness(98%) contrast(98%) !important;
    }
    .searchPath {
      span {
        background: transparent !important;
      }
    }
    .storage-dashboard-main {
      .enable-region-wrapper {
        .table-wrapper {
          .table-header {
            background: #383838;
            p {
              img {
                filter: brightness(0) saturate(100%) invert(100%) sepia(76%) saturate(126%) hue-rotate(345deg) brightness(116%) contrast(104%);
              }
            }
          }
          .table-body {
            .table-row {
              &:hover {
                background: #474545;
              }
              .table-item {
                .name-wrapper {
                  .bucket-icon {
                    filter: brightness(0) saturate(100%) invert(100%) sepia(76%) saturate(126%) hue-rotate(345deg) brightness(116%) contrast(104%);
                  }
                }
                .copy-wrapper {
                  img {
                    filter: brightness(0) saturate(100%) invert(100%) sepia(76%) saturate(126%) hue-rotate(345deg) brightness(116%) contrast(104%);
                  }
                }
                ul.options {
                  li {
                    a {
                      img {
                        filter: brightness(0) saturate(100%) invert(100%) sepia(76%) saturate(126%) hue-rotate(345deg) brightness(116%) contrast(104%);
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .new-region-wrapper {
          .brand-icon {
            background: #fff;
          }
        }
      }
    }
    .bucket-main-modal {
      .ant-modal-content {
        .ant-modal-body {
          .bucket-modal-header {
            .name-wrapper {
              img {
                filter: brightness(0) saturate(100%) invert(93%) sepia(93%) saturate(0%) hue-rotate(344deg) brightness(107%) contrast(110%);
              }
            }
            .cross {
              img {
                filter: brightness(0) saturate(100%) invert(93%) sepia(93%) saturate(0%) hue-rotate(344deg) brightness(107%) contrast(110%);
              }
            }
          }
          .bucket-modal-body {
            .new-key-wrapper {
              ul.options {
                li {
                  p {
                    img {
                      filter: brightness(0) saturate(100%) invert(100%) sepia(98%) saturate(9%) hue-rotate(162deg) brightness(102%) contrast(100%);
                    }
                  }
                }
              }
            }
          }
          .bucket-modal-footer {
            ul {
              li {
                .btn-style {
                  &:hover {
                    &.transparent {
                      span {
                        color: #fff !important;
                      }
                    }
                  }
                  &.transparent {
                    border-color: #fff !important;
                    color: #fff !important;
                  }
                }
              }
            }
          }
        }
      }
    }
    .ant-modal-root {
      .ant-modal-wrap {
        .ant-modal.cloud-drive-modal {
          .ant-modal-content {
            .ant-modal-body {
              .cloud-drive-modal-wrapper {
                .form {
                  .form-group {
                    .ant-btn-default {
                      &.bkp-cancel-btn {
                        background: transparent;
                        border-color: $white;
                        &:hover {
                          background: transparent;
                          border-color: $white !important;
                        }
                        span {
                          color: $white !important;
                        }
                      }
                      &.bkp-save-btn {
                        background: $primary01;
                        border-color: $primary01;
                        span {
                          color: $white !important;
                        }
                      }
                      &.bkp-del-btn {
                        background: #444444 !important;
                        border-color: #444444 !important;
                        &:hover {
                          border-color: #444444 !important;
                        }
                        span {
                          color: $white !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .login.email-verification .login-wrapper .form .form-group .code-input input {
      color: #fff !important;
      border-color: #fff !important;
    }
    .cloud-brands-wrapper .right-content .cloud-right-bar .selected-brand .brand-body .form .form-group .file-upload-btn {
      background: transparent !important;
    }
    .cloud-brands-wrapper .right-content .cloud-right-bar .selected-brand .brand-body .form .form-group .icon {
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(151deg) brightness(102%) contrast(101%) !important;
    }
    .search-file-wrapper .upload-file-detail-wrapper .upload-body .table-wrapper .table-data .row:not(.disabled):hover, .search-file-wrapper .upload-file-detail-wrapper .upload-body .table-wrapper .table-data .row.active {
      background: #383838 !important;
    }
    .login, .login .login-wrapper .form {
      background: transparent;
    }
    .subscription .subscription-wrapper .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab:not(.ant-tabs-tab-active) .ant-tabs-tab-btn, .manual-user-type .manual-user-modal-wrapper .body .search-wrapper .react-multi-email span {
      color: #000 !important;
      &[data-placeholder="true"] {
        color: #fff !important;
        opacity: .5;
      }
    }
    .ant-select {
      &.ant-select-focused {
        .ant-select-selector {
          border-color: #fff !important;
          box-shadow: none !important;
        }
      }
    }
    .cloud-brands-wrapper .right-content .cloud-right-bar .selected-brand .brand-body .form .form-group .info-main-wrapper .ant-checkbox-wrapper:hover .ant-checkbox:after {
      border-color: #000 !important;
    }
    .subscription-page-wrapper {
      .subscription-wrapper {
        .tabs-wrapper {
          .tabs-pricing-wrapper {
            &:before {
              background: rgba(255, 255, 255, 0.9);
            }
          }
        }
      }
    }
    .file-path-modal-wrapper .ant-btn-primary, .delete-wrapper .ant-btn-primary, .action-wrapper .ant-btn-primary {
      background-color: $primary01;
      border-color: $primary01;
      &:focus, &:active, &:hover {
        background: $primary01;
        color: #fff;
        border-color: $primary01;
        span {
          color: #fff;
        }
      }
    }
    .login .login-wrapper .bottom-text a.bold {
      color: #EA4903 !important;
    }
    .nfs-icons.white-on-dark {
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2389%) hue-rotate(227deg) brightness(114%) contrast(101%);
    }
    .sec-migration-status {
      .migration-wrapper {
        .migration-card {
          box-shadow: unset;
          border: 1px solid #A9A9A9;
          border-radius: 4px;
          .status-wrapper {
            .icon-wrapper {
              img {
                filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2389%) hue-rotate(227deg) brightness(114%) contrast(101%);
              }
            }
          }
        }
      }
      .speed-wrapper {
        .speed-card {
          box-shadow: unset;
          border: 1px solid #A9A9A9;
          border-radius: 4px;
          .status-detail {
            p {
              strong {
                font-weight: 600;
                &.green {
                  color: #12AA5D !important;
                }
                &.orange {
                  color: #F1AF6C !important;
                }
              }
            }
          }
        }
      }
    }
    .new-pop-wrapper {
      .ant-tooltip {
        .ant-tooltip-content {
          .ant-tooltip-inner {
            background: #f1fafe !important;
            border: 1px solid #6798cd !important;
            .title {
              color: #50565f !important;
            }
            p {
              color: #000 !important;
            }
            .close-icon {
              svg {
                path {
                  fill: #000;
                }
              }
            }
          }
        }
      }
      .ant-tooltip-arrow {
        &:after {
          background: #f1fafe !important;
          border: 1px solid #6798cd !important;
        }
      }
    }
    /* .ant-select-arrow .anticon.anticon-down.ant-select-suffix svg, .ant-select .ant-select-arrow .anticon > svg, #left-sidebar-tooltip-wraper .ant-tooltip .ant-tooltip-content .ant-tooltip-inner ul.view-list li:last-of-type a img {
       filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(151deg) brightness(102%) contrast(101%) !important;
     }*/
    .ant-modal-root .ant-modal-wrap .ant-modal.cloud-drive-modal.NFS .ant-modal-content .ant-modal-body .cloud-drive-modal-wrapper .form .form-group .ant-select .ant-select-selector {
      border-color: #fff;
      background: #2C2C2C !important;
    }
    .success-message-login {
      color: rgba(0, 0, 0, 1) !important;
      span {
        color: rgba(0, 0, 0, 1) !important;
      }
      &.red {
        color: #a6292e !important;
        span {
          color: #a6292e !important;
        }
      }
    }
    .dashboard-options .ant-dropdown-trigger {
      svg {
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(151deg) brightness(102%) contrast(101%) !important;
      }
      &:hover, &.ant-dropdown-open {
        span {
          color: #fff !important;
        }
      }
    }
    .user-account-dropdown {
      ul {
        li {
          color: #000 !important;
          span, a {
            color: #000 !important;
          }
        }
      }
    }
    .change-subscription-type.btn-style.ant-btn-default {
      background-color: $blue !important;
      border-color: $blue !important;
    }
    .subscription-header .subscription-back a i {
      color: #fff;
    }
    .search-file-wrapper .upload-file-detail-wrapper .upload-body .table-wrapper .table-data .hover-wrapper {
      background: #383838;
    }
    #main-content {
      .add-new-user-wrapper .add-user-header .btn-wrapper a.btn-style {
        background-color: $primary01;
        border-color: $primary01;
        &:hover {
          background-color: $primary01;
          border-color: $primary01;
        }
      }
      .btn-style {
        border-color: #fff;
        background: transparent;
        &.orange-btn, &.invert {
          background-color: $primary01;
          border-color: $primary01;
          &.transparent {
            background-color: transparent !important;
            border-color: #fff !important;
          }
        }
        &:hover {
          background-color: $primary01;
          border-color: $primary01;
        }
        &.transparent.icon {
          background: transparent !important;
          img {
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(151deg) brightness(102%) contrast(101%) !important;
          }
          &:hover {
            background: transparent !important;
            border-color: transparent !important;
          }
        }
        &.smaller, &.blue {
          background: #0074D4 !important;
          border-color: #0074D4 !important;
          color: #fff !important;
        }
      }
      .account-detail-wrapper {
        .account-detail-body {
          .account-detail-card {
            background: transparent;
            box-shadow: none;
            border: 1px solid $dark-primary02
          }
          .setting-connected-accounts {
            .table-header {
              background: $dark-primary02;
            }
            .connected-accounts-body {
              .table-body {
                .ant-collapse {
                  background: transparent;
                  .ant-collapse-item {
                    background: transparent;
                    &.ant-collapse-item-active {
                      background: #474545 !important;
                      .ant-collapse-header {
                        border: none;
                      }
                    }
                    .ant-collapse-content {
                      background: $dark-primary01;
                      .ant-collapse-content-box {
                        .table-body-card {
                          .cloud-drive-modal-wrapper {
                            > h3 {
                              border-color: $dark-primary02;
                            }
                            .ant-tabs {
                              .ant-tabs-nav-wrap {
                                .ant-tabs-nav-list {
                                  .ant-tabs-tab {
                                    background: $dark-primary01;
                                    border-color: $dark-primary01;
                                    .ant-tabs-tab-btn {
                                      &:hover {
                                        color: $white !important;
                                      }
                                    }
                                    &.ant-tabs-tab-active {
                                      background: $dark-primary02;
                                      .ant-tabs-tab-btn {
                                        color: $white !important;
                                        &:hover {
                                          color: $white;
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              .ant-tabs-content-holder {
                                margin: -1px 0 0;
                                .ant-tabs-content {
                                  background: $dark-primary02;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                .btn-style {
                  span {
                    color: #fff !important;
                  }
                  &:focus {
                    border-color: #fff !important;
                  }
                  &:hover {
                    border-color: $primary01 !important;
                  }
                }
              }
            }
          }
        }
      }
    }
    .connected-accounts.tour-area {
      background: transparent;
    }
    .file-manager-header ul.selected li a.selected-files img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(151deg) brightness(102%) contrast(101%) !important;
    }
    .ant-modal-root .ant-modal-wrap .ant-modal.file-path-modal .ant-modal-content .ant-modal-body .modal-body-wrapper .select-drive-wrapper {
      border-color: #fff;
    }
    .btn-style.smaller.open-drive-btn, .btn-style.smaller.open-drive-btn:hover, .btn-style.smaller.open-drive-btn:focus, .btn-style.smaller.open-drive-btn:active,
    .select-user-type .select-user-modal-wrapper .body .select-wrapper a.btn-style {
      background: #0074D4 !important;
      border-color: #0074D4 !important;
      color: #fff !important;
    }
    .new-files-row .btn-wrappers .btn-style {
      background: #0074D4 !important;
      border-color: #0074D4 !important;
    }
    .form-group .btn-style.small-btn {
      background: transparent;
      border-color: #fff;
      &:hover {
        background-color: $primary01;
        border-color: $primary01;
      }
    }
    .create-team-steps-wrapper {
      .create-team {
        .plan-cards {
          .plan-card {
            .package-detail {
              .choose-plan-wrapper {
                strong {
                  color: $black !important;
                }
              }
            }
          }
        }
      }
    }
    .create-team-wrapper .create-team-steps-wrapper .create-team .btn-style.blue.disabled {
      color: #fff !important;
      span {
        color: #fff !important;
      }
    }
    .file-manager-header {
      .share-selected {
        text-align: right;
        .btn-style {
          img {
            filter: brightness(0) saturate(100%) invert(100%) sepia(4%) saturate(218%) hue-rotate(267deg) brightness(115%) contrast(100%) !important;
          }
        }
      }
      .bottom-wrapper {
        .options {
          li {
            .btn-style {
              &:focus, &:hover {
                background: #BCC7D2 !important;
                color: #000 !important;
                border-color: #BCC7D2 !important;
              }
              &.blue.icon {
                &:hover {
                  img {
                    filter: brightness(0) saturate(100%) invert(0%) sepia(87%) saturate(7475%) hue-rotate(41deg) brightness(83%) contrast(97%);
                  }
                }
                &:focus {
                  background: #BCC7D2 !important;
                  color: #000 !important;
                  img {
                    //filter: brightness(0) saturate(100%) invert(0%) sepia(87%) saturate(7475%) hue-rotate(41deg) brightness(83%) contrast(97%) !important;
                  }
                }
              }
            }
          }
        }
      }
    }
    .dashboard-options {
      .btn-style.purple {
        background: #9747FB !important;
      }
      .notification-wrapper {
        .bell-wrapper {
          img {
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(151deg) brightness(102%) contrast(101%);
          }
        }
        ul.notifications {
          li {
            &.no-notifications {
              img {
                filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(151deg) brightness(102%) contrast(101%) !important;
              }
            }
          }
        }
      }
    }
    .ant-switch {
      border: 1px solid $dark-border01;
      .ant-switch-handle {
        &:before {
          top: -1px;
        }
      }
    }
    .react-multi-email {
      background: transparent;
      border: 1px solid $dark-border01;
      &.focused {
      }
      input {
        background: transparent;
        color: $white;
      }
      > span {
      }
    }
    .search-file-wrapper .upload-file-detail-wrapper .upload-body .table-wrapper .table-data.migration-page {
      &::-webkit-scrollbar {
        background-color: #2c2c2c;
        width: 16px;
      }
      &::-webkit-scrollbar-track {
        background-color: #2c2c2c;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #2c2c2c;
        border-radius: 16px;
        transition: .5s;
        border: 4px solid #2c2c2c;
      }
      &:hover {
        &::-webkit-scrollbar-thumb {
          background-color: $scroll-bar-color;
        }
      }
      &::-webkit-scrollbar-button {
        display: none;
      }
    }
    .form-control {
      border-color: $white;
      background: $dark-primary01 !important;
      &:focus {
        border-color: $white !important;
      }
      &::placeholder {
        color: $white !important;
      }
    }
    .help-circle .form-control:focus {
      border-color: #32d583 !important;
    }
    .cloud-brands-wrapper {
      .form-control.search-icon {
        background: url("../images/icons/search-white-icon.svg") no-repeat !important;
        background-position: 10px center !important;
        background-size: 15px !important;
        &.right {
          padding-left: 12px;
          padding-right: 32px;
          background-position: right 10px center !important;
        }
      }
    }
    .ant-select {
      &.ant-select-show-search {
        .ant-select-selector {
          border-color: $white !important;
          background: transparent;
        }
      }
      .ant-select-selector {
        //border-color: $dark-border01;
        background: transparent;
      }
    }
    .ant-select-dropdown
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: #2C2C2C;
      .ant-select-item-option-content {
        color: #fff !important;
      }
    }
    .ant-select-dropdown {
      background: $dark-border01;
      .ant-select-item {
        &.ant-select-item-option-active {
          background-color: #2C2C2C !important;
          .ant-select-item-option-content {
            color: #fff !important;
          }
        }
      }
      .ant-select-item-option-content {
        .role-card {
          .info-icon {
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(5633%) hue-rotate(255deg) brightness(116%) contrast(87%);
          }
        }
      }
    }
    .ant-tooltip {
      .ant-tooltip-content {
        .ant-tooltip-inner {
          background: $dark-primary01 !important;
          border: 1px solid $dark-border01 !important;
          ul {
            li {
              a, button {
                &:hover {
                  background: $dark-primary02 !important;
                }
                img {
                  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(151deg) brightness(102%) contrast(101%) !important;
                }
              }
              &.remove-all-notifications {
                a {
                  border-radius: 0 0 6px 6px !important;
                  &:hover {
                    background: #ea4903 !important;
                    border-radius: 0 0 6px 6px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
    .notifications {
      &::-webkit-scrollbar {
        background-color: $dark-primary01;
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        background-color: $dark-primary01;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #fff;
        border-radius: 16px;
        border: 2px solid $dark-primary01;
      }
      &::-webkit-scrollbar-button {
        display: none;
      }
    }
    .edit-drive-name img, .info-wrapper img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(151deg) brightness(102%) contrast(101%) !important;
    }
    .ant-popover {
      .ant-popover-content {
        .ant-popover-inner {
          background: $dark-primary01;
          .ant-popover-inner-content {
            .ant-popconfirm-inner-content {
              .ant-popconfirm-buttons {
                .ant-btn {
                  &.ant-btn-primary {
                    span {
                      color: $white !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .ant-modal-root {
      .ant-modal-content {
        background: $dark-primary01 !important;
        .ant-modal-header {
          background: transparent;
        }
        .ant-modal-body {
          .form {
            &.dir-path-wrapper {
              .form-group {
                h3 {
                  background: transparent;
                  .btn-style {
                    i {
                      color: $white;
                    }
                  }
                }
              }
            }
          }
          .modal-body-wrapper {
            background: transparent !important;
            .select-drive-wrapper {
              background: transparent !important;
              &:before {
                background: transparent !important;
              }
              .form {
                .form-group {
                  label {
                    svg {
                      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(151deg) brightness(102%) contrast(101%);
                    }
                  }
                }
                .windows-folder-wrapper {
                  background: transparent;
                  .col-3 {
                    background: $dark-primary02;
                    padding-left: 25px;
                  }
                  .col-9 {
                    background: transparent;
                    border: 1px solid $dark-border01;
                    border-left: unset;
                  }
                }
                .account-detail {
                  .final-selected-cloud {
                    &::-webkit-scrollbar {
                      background-color: $dark-primary01;
                      width: 16px;
                    }
                    &::-webkit-scrollbar-track {
                      background-color: $dark-primary01;
                    }
                    &::-webkit-scrollbar-thumb {
                      background-color: $scroll-bar-color;
                      border-radius: 16px;
                      border: 4px solid $dark-primary01;
                    }
                  }
                }
              }
            }
          }
          .btn-style {
            &.transparent {
              border-color: $white !important;
            }
          }
          .tag-wrapper {
            .tag {
              background: $dark-primary01 !important;
              border-color: $white !important;
              img {
                filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(151deg) brightness(102%) contrast(101%);
              }
            }
          }
          .select-user-modal-wrapper {
            .body {
              .select-wrapper {
                .btn-style {
                  &:hover, &:focus, &:active {
                    background: $blue !important;
                    color: #fff !important;
                    span {
                      color: #fff !important;
                    }
                  }
                }
              }
            }
          }
          .manual-user-modal-wrapper {
            .body {
              .form {
                .form-group {
                  .added-users {
                    background: transparent;
                    ul.selected-tags {
                      li {
                      }
                    }
                  }
                }
              }
            }
          }
          .search-user-wrapper {
            .select-all {
              .search-user {
                background: transparent;
              }
            }
          }
          .cloud-drive-modal-wrapper {
            .form.form-footer {
              .form-group {
                &.submit {
                  .btn-style {
                    background: $primary01;
                    border-color: $primary01;
                    span {
                      color: $white !important;
                    }
                  }
                  .cancel-btn {
                    background: transparent;
                    border-color: $white;
                    span {
                      color: $white !important;
                    }
                    &:hover, &:active, &:focus {
                      border-color: $white !important;
                    }
                  }
                  .del-btn {
                    background: #444444;
                    border-color: #444444;
                    span {
                      color: $white !important;
                    }
                    &:hover, &:active, &:focus {
                      border-color: #444444 !important;
                    }
                  }
                }
              }
            }
            .ant-tabs {
              .ant-tabs-nav {
                .ant-tabs-nav-wrap {
                  .ant-tabs-nav-list {
                    .ant-tabs-tab {
                      background: transparent !important;
                      border: none;
                      &:hover {
                        .ant-tabs-tab-btn {
                          color: $white !important;
                        }
                      }
                      &.ant-tabs-tab-active {
                        background: $dark-border01 !important;
                        .ant-tabs-tab-btn {
                          color: $white !important;
                        }
                      }
                    }
                  }
                }
              }
              .ant-tabs-content-holder {
                .ant-tabs-content {
                  background: $dark-border01 !important;
                  .form {
                    .form-group {
                      img {
                        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(151deg) brightness(102%) contrast(101%);
                      }
                      label {
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .ant-modal-footer {
          background: transparent !important;
          .btn-style {
            background: transparent !important;
            color: $white !important;
          }
        }
      }
    }
    .drag-and-drop-wrapper {
      .drag-and-drop {
        img {
          filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(151deg) brightness(102%) contrast(101%);
        }
      }
    }
    .download-client-popup {
      .ant-modal-content {
        background: $dark-primary01;
        .ant-modal-body {
          .cloud-drive-modal-wrapper {
            .left-side {
              a.btn-style {
                &:hover {
                  background: $primary01;
                }
              }
            }
          }
        }
      }
    }
    .upload-file-popup {
      .ant-modal-content {
        background: $dark-primary01;
        .ant-modal-body {
          .upload-file-popup-wrapper {
            .body {
              .form {
                .copy-link {
                  .link {
                    img {
                      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(151deg) brightness(102%) contrast(101%);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .log-page {
      .ant-checkbox .ant-checkbox-inner {
        background: #fff;
        &:after {
          border-color: #000000;
        }
      }
      .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner, .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner, .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner, .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
        background: #fff;
      }
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background: $white !important;
      &:after {
        border-color: $black !important;
      }
    }
    .dashboard-main {
      #dashboard-header {
        background: $dark-primary01;
        border-color: $dark-border01;
        .mobile-options-navbar {
          background: $dark-primary02;
          border-color: $dark-border01;
        }
        ul.dashboard-left-menu {
          li {
            a {
              &.btn-style {
                &.white {
                  background: transparent !important;
                  border-color: #0074D4 !important;
                  color: #0074D4 !important;
                  &:hover {
                    background: transparent !important;
                    color: #0074D4 !important;
                  }
                  &.active {
                    background: #0074D4 !important;
                    color: white !important;
                  }
                }
                &.brown {
                  background: #dcfe46 !important;
                  border-color: #dcfe46 !important;
                  color: #000 !important;
                }
              }
            }
          }
        }
        ul.dashboard-options {
          li {
            .user-account-dropdown {
              .ant-dropdown {
                &.open {
                  background: red;
                }
                li {
                  a {
                    color: $black !important;
                  }
                }
              }
              .user-account {
                &.ant-dropdown-open {
                  .company-detail {
                    span {
                      color: #fff !important;
                    }
                  }
                  svg {
                    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(151deg) brightness(102%) contrast(101%) !important;
                  }
                }
                > svg {
                  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7467%) hue-rotate(182deg) brightness(108%) contrast(100%);
                }
              }
            }
          }
        }
      }
      #left-sidebar {
        background: $dark-primary01;
        border-right: 1px solid $dark-border01;
        .left-sidebar-wrapper {
          .navbar-menu {
            .drawer-wrapper {
            }
            ul.menu {
              li {
                a {
                }
              }
            }
          }
        }
      }
      #main-content {
        .main-content-body {
          &.dashboard-home {
            .cloud-brands-wrapper {
              &.choose-cloud-wrapper {
                .left-content {
                  .drive-brands {
                    .new-files-row {
                    }
                    .choose-cloud-card-wrapper {
                      .choose-cloud-card {
                        background: transparent;
                        border: 1px solid $dark-border01;
                        .img-wrapper {
                          img.white-on-dark {
                            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(151deg) brightness(102%) contrast(101%);
                          }
                        }
                        .content-wrapper {
                          background: transparent;
                          border-color: $dark-border01;
                        }
                      }
                    }
                    .choose-cloud-counter {
                      .img-wrapper {
                        .counter {
                          background: $dark-primary01;
                        }
                      }
                    }
                    .change-cloud-wrapper {
                      .choose-cloud-card {
                        background: transparent;
                        .img-wrapper {
                          img {
                            // filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(151deg) brightness(102%) contrast(101%);
                          }
                        }
                      }
                    }
                  }
                  .setup-cloud-drive {
                    .btn-style {
                      background: $primary01;
                      border-color: $primary01;
                    }
                    .file-upload-btn {
                      background: transparent;
                      .img-wrapper {
                        img {
                          filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(151deg) brightness(102%) contrast(101%);
                        }
                      }
                    }
                    .selected-path {
                      .choose-cloud-card {
                        background: transparent;
                      }
                    }
                    .ant-select {
                      &.selected {
                        .ant-select-selector {
                          border-color: #32d583 !important;
                        }
                      }
                      .ant-select-selector {
                      }
                    }
                  }
                }
              }
            }
          }
          .project-new-page {
            .project-main-wrapper {
              .project-card-wrapper {
                .project-card {
                  background: transparent;
                  border: 1px solid $subscribe;
                  .card-header {
                    border-bottom: 1px solid $subscribe;
                    .tooltip-btn {
                      a {
                        img {
                          filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7467%) hue-rotate(182deg) brightness(108%) contrast(100%);
                        }
                      }
                    }
                  }
                  .card-body {
                    .border-right {
                      border-right: 1px solid $subscribe;
                    }
                    .projects-icons {
                      img {
                        //  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7467%) hue-rotate(182deg) brightness(108%) contrast(100%);
                      }
                    }
                  }
                }
              }
            }
          }
          .connected-accounts {
            .reload-cloud-accounts {
              filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7467%) hue-rotate(182deg) brightness(108%) contrast(100%);
            }
            .ant-collapse {
              border: none;
              .ant-collapse-item {
                .ant-collapse-header {
                  background: transparent;
                  .ant-collapse-header-text {
                    .connected-accounts-header {
                      background: transparent;
                      border-bottom: 2px solid transparent;
                      border-radius: 0;
                      ul.login-accounts {
                        li {
                          img {
                            //filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7467%) hue-rotate(182deg) brightness(108%) contrast(100%);
                          }
                        }
                      }
                    }
                  }
                }
                &.ant-collapse-item-active {
                  .ant-collapse-header {
                    .ant-collapse-header-text {
                      .connected-accounts-header {
                        border-bottom: 2px solid $dark-border01;
                      }
                    }
                  }
                }
                .ant-collapse-content {
                  background: transparent;
                  .ant-collapse-content-box {
                    .connected-accounts-body {
                      .table-header {
                        background: $dark-primary02;
                      }
                      .table-body {
                        ul {
                          .table-body-card {
                            background: transparent;
                            .refresh-bucket, .settings-icon {
                              img {
                                filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7467%) hue-rotate(182deg) brightness(108%) contrast(100%);
                              }
                            }
                            .buckets-list {
                              .buckets-header {
                                background: $dark-primary02;
                              }
                              .buckets-wrapper {
                                &::-webkit-scrollbar {
                                  background-color: $dark-primary01;
                                  width: 16px;
                                }
                                &::-webkit-scrollbar-track {
                                  background-color: $dark-primary01;
                                }
                                &::-webkit-scrollbar-thumb {
                                  background-color: $scroll-bar-color;
                                  border-radius: 16px;
                                  border: 4px solid $dark-primary01;
                                }
                              }
                            }
                            .account-detail {
                              .img-wrapper {
                                img {
                                  //filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7467%) hue-rotate(182deg) brightness(108%) contrast(100%);
                                }
                              }
                            }
                            ul.icons {
                              li {
                                &.delete-wrapper {
                                  img {
                                    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7467%) hue-rotate(182deg) brightness(108%) contrast(100%);
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .cloud-brands-wrapper {
            .left-content {
              .drive-brands {
                .drive-brands-wrapper {
                  .free-storage {
                    .brand-icon {
                      background: $white;
                    }
                  }
                  ul.brands {
                    li {
                      label {
                        background: rgba(255, 255, 255, .9);
                        &.text-logo {
                          h3, p {
                            color: $black !important;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .right-content {
              .cloud-right-bar {
                background: transparent;
                border-color: $dark-border01;
                .selected-brand {
                  .brand-body {
                    .brand-header-image {
                      .wrapper {
                        > h3 {
                          color: $black !important;
                        }
                      }
                    }
                  }
                  .form {
                    .form-group {
                      .btn-style {
                        border-color: $black !important;
                      }
                      .info-main-wrapper {
                        .ant-checkbox-wrapper {
                          .ant-checkbox {
                            &.ant-checkbox-checked {
                              .ant-checkbox-inner {
                                border-color: $black !important;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .search-file-wrapper {
          .upload-file-detail-wrapper.new-files-table {
            background: transparent;
            .upload-body {
              .table-wrapper {
                .sync-back {
                  background: transparent !important;
                  .sync-img {
                    img {
                      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(151deg) brightness(102%) contrast(101%);
                    }
                  }
                }
                .table-header {
                  background: transparent !important;
                }
                .table-data {
                  .hover-row {
                    .img-wrapper {
                      .ant-checkbox-wrapper {
                        &.ant-checkbox-wrapper-checked {
                          .ant-checkbox {
                            .ant-checkbox-inner {
                              background: $white;
                              &:after {
                                border-color: $black;
                              }
                            }
                          }
                        }
                      }
                    }
                    &:hover, &.checked {
                      background: $dark-primary02;
                      .hover-wrapper {
                        background: $dark-primary02;
                      }
                    }
                    .hover-wrapper {
                      .upload-option {
                        a {
                          img {
                            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(151deg) brightness(102%) contrast(101%);
                          }
                          &.menu-open {
                            img {
                              filter: unset;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .add-new-user-wrapper {
          .add-user-header {
            ul.tabs {
              li {
                a {
                  background: transparent;
                  &.active {
                    background: #0074D4;
                  }
                }
              }
            }
          }
          .add-user-body {
            .user-table-wrapper {
              .user-table-header {
                background: transparent;
                ul.filter {
                  li {
                    img {
                      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(151deg) brightness(102%) contrast(101%);
                    }
                    .ant-popover {
                      .ant-popover-content {
                        .ant-popover-inner {
                          border: 1px solid $dark-border01;
                          background: $dark-primary01;
                        }
                      }
                    }
                  }
                }
              }
              .user-table-body {
                table {
                  thead {
                    tr {
                      th {
                        background: $dark-primary02;
                        color: $white;
                        .arrow-up, .arrow-down {
                          img {
                            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(151deg) brightness(102%) contrast(101%);
                          }
                        }
                      }
                    }
                  }
                  tbody {
                    tr {
                      td {
                        .name-wrapper {
                          .dots {
                            img {
                              filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(151deg) brightness(102%) contrast(101%);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .sigin-on-steps {
          .single-signin-wrapper {
            .single-signin-card-wrapper {
              .single-signin-area {
                .single-signin-card {
                  box-shadow: none;
                  border: 1px solid $dark-border01;
                }
              }
            }
          }
        }
        .custom-table {
          .custom-table-header-wrapper {
            .custom-table-header {
              background: $dark-primary02 !important;
            }
          }
          .custom-table-wrapper {
            .custom-table-row {
              &.active {
                background: #474545 !important;
                &:after {
                  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(151deg) brightness(102%) contrast(101%);
                }
              }
              &:after {
                filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(151deg) brightness(102%) contrast(101%);
              }
            }
            .activity-inner-table {
              &.active {
                table {
                  thead {
                    tr {
                      th {
                        color: $white;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .about-page-wrapper {
        background: transparent;
        .login-wrapper {
          .logo {
            &.light-logo {
              display: none;
            }
            &.dark-logo {
              display: block;
            }
          }
          .btn-style {
            background: transparent;
            border-color: $white;
            &:hover {
              background: $primary01 !important;
              border-color: $primary01 !important;
            }
          }
        }
      }
    }
    .login {
      .login-wrapper {
        .light-logo {
          display: none !important;
        }
        .dark-logo {
          display: inline-block !important;
          img {
            width: 195px;
          }
        }
        .form {
          .form-group {
            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
              -webkit-box-shadow: 0 0 0 30px $dark-primary01 inset !important;
            }
            input:-webkit-autofill {
              -webkit-text-fill-color: white !important;
            }
            .form-control {
            }
            &.submit {
              &.get-started {
                .btn-style {
                  background: #0074d4 !important;
                  border-color: #0074d4 !important;
                  &:hover {
                    background: #0074d4 !important;
                    border-color: #0074d4 !important;
                  }
                }
              }
              &.google-btn-new-wrapper {
                .btn-style {
                  background: #0074d4 !important;
                  color: #fff !important;
                  border-color: #0074d4 !important;
                  span {
                    background: #0074d4 !important;
                    color: #fff !important;
                    border-color: #0074d4 !important;
                  }
                  &:hover {
                    background: #0074d4 !important;
                    border-color: #0074d4 !important;
                    color: #fff !important;
                    span {
                      background: #0074d4 !important;
                      color: #fff !important;
                      border-color: #0074d4 !important;
                    }
                  }
                }
              }
              a {
                &:before {
                  filter: brightness(0) saturate(100%) invert(89%) sepia(1%) saturate(7498%) hue-rotate(233deg) brightness(116%) contrast(101%);
                }
              }
              button {
                /*background: transparent;
                &:hover {
                  background: $primary01 !important;
                  border-color: $primary01 !important;
                }*/
              }
            }
            .code-input {
              input {
                background: transparent;
              }
            }
          }
          .fields-wrapper {
            .or-wrapper {
              span {
                background: $dark-primary01;
              }
            }
          }
        }
      }
    }
    .project-main-wrapper {
      .project-card-wrapper {
        .project-card {
          background: transparent;
          .card-header {
            a.dots {
              img {
                filter: brightness(0) saturate(100%) invert(89%) sepia(1%) saturate(7498%) hue-rotate(233deg) brightness(116%) contrast(101%);
              }
            }
          }
        }
      }
    }
    .search-file-wrapper {
      .upload-file-detail-wrapper {
        //$dark-border01
        background: transparent;
        .upload-body {
          .table-wrapper {
            .sync-back {
              background: $dark-border01;
              .sync-img {
                img {
                  filter: brightness(0) saturate(100%) invert(89%) sepia(1%) saturate(7498%) hue-rotate(233deg) brightness(116%) contrast(101%);
                }
              }
            }
            .table-header {
              background: transparent;
            }
            .table-data {
              .hover-row {
                &:hover {
                  .hover-wrapper {
                    background: #383838;
                    .upload-option {
                      button {
                        &:hover {
                          border-color: $primary01 !important;
                        }
                      }
                    }
                    a:not(.menu-open) {
                      img {
                        filter: brightness(0) saturate(100%) invert(89%) sepia(1%) saturate(7498%) hue-rotate(233deg) brightness(116%) contrast(101%);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .search-material-wrapper {
      .match-wrapper {
        .table-main-wrapper {
          .material-table-wrapper {
            .table-header {
              background: $dark-primary02;
            }
          }
        }
      }
    }
    .add-material-wrapper {
      .search-file-wrapper {
        .search-wrapper {
          .search-header {
            a {
              img {
                filter: brightness(0) saturate(100%) invert(89%) sepia(1%) saturate(7498%) hue-rotate(233deg) brightness(116%) contrast(101%);
              }
            }
          }
        }
        .table-main-wrapper {
          .upload-file-detail-wrapper {
            .upload-body {
              .table-wrapper {
                .sync-back {
                  background: $dark-primary02;
                }
              }
            }
          }
        }
      }
    }
    .team-main-dashboard {
      #team-main-content {
        background: $dark-primary01;
      }
    }
    .create-options {
      ul.view-list {
        li {
          &:last-of-type {
            a {
              span.icon {
                img {
                  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(2%) hue-rotate(221deg) brightness(111%) contrast(101%) !important;
                }
              }
            }
          }
        }
      }
    }
    :where(.css-dev-only-do-not-override-1i9hnpv).ant-progress .ant-progress-inner {
      background-color: rgb(255 255 255 / 8%);
    }
    .ant-input-number .ant-input-number-handler-up-inner svg,
    .ant-input-number .ant-input-number-handler-down-inner svg {
      color: #000 !important;
    }
    .ant-modal-root .ant-modal-wrap .ant-modal.file-path-modal .ant-modal-content .ant-modal-body .modal-body-wrapper .select-drive-wrapper .col-md-7 .form .form-group.active label, .ant-modal-root .ant-modal-wrap .ant-modal.file-path-modal .ant-modal-content .ant-modal-body .modal-body-wrapper .select-drive-wrapper .col-md-9 .form .form-group.active label {
      color: #EA4903 !important;
    }
    .ant-modal-root .ant-modal-wrap .ant-modal.file-path-modal .ant-modal-content .ant-modal-body .folder-path-wrapper .col-3, .ant-modal-root .ant-modal-wrap .ant-modal.file-path-modal .ant-modal-content .ant-modal-body .selected-folder-wrapper .col-3 {
      background: #2c2c2c !important;
    }
    .ant-modal-root .ant-modal-wrap .ant-modal.file-path-modal .ant-modal-content .ant-modal-body .selected-folder-wrapper .col-3, .ant-modal-root .ant-modal-wrap .ant-modal.file-path-modal .ant-modal-content .ant-modal-body .selected-folder-wrapper .col-9, .ant-modal-root .ant-modal-wrap .ant-modal.file-path-modal .ant-modal-content .ant-modal-body .folder-path-wrapper .col-3, .ant-modal-root .ant-modal-wrap .ant-modal.file-path-modal .ant-modal-content .ant-modal-body .folder-path-wrapper .col-9 {
      background: #272727;
    }
    .welcome-popup {
      .ant-modal-content {
        background: $blue !important;
      }
    }
    .apiToken-page {
      .connected-accounts-body .table-header {
        background: #383838 !important;
      }
      .connected-accounts-body .table-body .table-body-card {
        background: transparent !important;
      }
    }
    .dashboard-options .ant-dropdown-trigger:hover, .dashboard-options .ant-dropdown-trigger.ant-dropdown-open {
      background: transparent;
    }
    .dashboard-options {
      .company-detail {
        span {
          color: #fff !important;
        }
      }
    }
    .manual-user-type.billing-popup {
      .ant-modal-content {
        background: #fff !important;
        h3, p {
          color: #000 !important;
        }
      }
    }
    #left-sidebar-tooltip-wraper .ant-tooltip .ant-tooltip-content .ant-tooltip-inner ul.view-list li:last-of-type a {
      border-color: #444444 !important;
    }
    .dashboard-main #main-content .main-content-body.dashboard-home .cloud-brands-wrapper .setup-cloud-drive .form .account-detail.mounting .form-wrapper .form-group {
      .ant-popover {
        .ant-popconfirm-buttons {
          .ant-btn-default {
            span {
              color: #000 !important;
            }
          }
        }
        .ant-popover-arrow {
          &:before {
            background: #2C2C2C !important;
          }
        }
      }
    }
    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator {
      filter: brightness(0) invert(1);
    }
    .allow-delete-wrapper {
      .switch-wrapper {
        &.shared-drive-switch {
          .ant-popover {
            .ant-popover-content {
              .ant-popover-arrow {
                &:before {
                  background: #2C2C2C !important;
                }
              }
              .ant-popover-inner {
                .ant-popover-inner-content {
                  .ant-popconfirm-inner-content {
                    .ant-popconfirm-buttons {
                      .ant-btn-default {
                        //border-color: $white!important;
                        background: $white !important;
                        span {
                          color: $black !important;
                        }
                      }
                      .ant-btn-primary {
                        span {
                          color: $white !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .switch-wrapper {
      :where(.css-dev-only-do-not-override-1i9hnpv).ant-popconfirm .ant-popconfirm-buttons button {
        span {
          color: #000 !important;
        }
      }
      :where(.css-dev-only-do-not-override-1i9hnpv).ant-popover .ant-popover-arrow:before {
        background: #2C2C2C !important;
      }
    }
  }
}
.container {
  max-width: 1235px;
}
//      ****************************  Text Element  ****************************
h1 {
  transition: color 100ms ease;
  font-size: 57px;
  line-height: 67px;
  font-weight: 700;
  color: $primary01;
  font-family: $res;
  @media screen and (max-width: $screen-xs) {
    font-size: 35px;
    line-height: 45px;
  }
}
h2, .h2 { //done
  font-family: $res;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: $text-primary01;
  position: relative;
  &.white {
    color: $white;
  }
  &.black {
    color: $black;
  }
  &.medium {
    font-weight: 500;
  }
  &.fk-font{
    font-family: $neue;
  }
}
.h2 {
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
}
.small-h2 {
  font-size: 30px;
  line-height: 40px;
}
h3, .h3 { //done
  font-family: $res;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: $text-primary01;
  @media screen and (max-width: $screen-xs) {
    font-size: 16px;
    line-height: 25px;
  }
  &.larger {
    font-size: 20px;
    line-height: 30px;
    @media screen and (max-width: $screen-xs) {
      font-size: 20px;
      line-height: 30px;
    }
  }
  strong {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: $text-primary01;
    font-family: $res;
    @media screen and (max-width: $screen-xs) {
      font-size: 16px;
      line-height: 25px;
    }
  }
  &.white {
    color: $white;
  }
  &.fk-font{
    font-family: $neue;
  }
}
.h3 { //done
  font-size: 24px;
  line-height: 32px;
}
p, .p { //done
  font-family: $neue;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  @media screen and (max-width: $screen-xs) {
    font-size: 13px;
    line-height: 19px;
  }
  &.medium {
    font-weight: 500;
  }
  &.bold {
    font-weight: 600;
  }
  &.white {
    color: $white;
  }
  &.fk-font{
    font-family: $neue;
  }
}
.p {
  font-size: 16px;
  line-height: 24px;
}
span {
  font-family: $neue;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  &.small {
    font-size: 12px;
    line-height: 18px;
  }
}
a {
  font-family: $neue;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $primary01;
  text-decoration: none;
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  &:hover {
    color: $primary01;
    text-decoration: none;
  }
  &.black {
    color: #000;
    &:hover {
      color: #000;
    }
  }
  &.bold {
    font-weight: 600;
  }
  &.medium {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $text-primary02;
  }
  &.arrow {
    position: relative;
    color: $text-primary03;
    font-weight: 600;
    &:hover {
      &:before {
        left: -16px;
      }
    }
    &.signin {
      color: $primary01;
      &::before {
        color: $primary01;
      }
    }
    &:before {
      content: "\f060";
      font-family: "Font Awesome 6 Free";
      display: inline-block;
      font-size: 15px;
      font-weight: 900;
      color: $text-primary03;
      position: relative;
      left: -12px;
      top: 1px;
      transition: $transition;
    }
  }
}
label { //done
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $text-primary02;
  user-select: none;
}
strong {
  font-family: $neue;
  font-size: 19px;
  line-height: 21px;
  font-weight: 500;
  letter-spacing: -0.15px;
  color: $primary01;
  .span {
    font-size: 17px;
    line-height: 21px;
  }
}
//      ****************************  Button Element  ****************************
.btn-style {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 122px;
  height: 44px;
  padding: 10px 18px 10px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 1px solid $primary01;
  text-decoration: none;
  transition: all .3s ease-in-out;
  font-family: $neue;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  background: #fff;
  color: $primary01;
  span {
    color: $primary01;
    font-weight: 600;
    font-size: 16px;
  }
  &:focus, &:active, &:hover {
    background: $primary01;
    color: #fff;
    border-color: $primary01;
    span {
      color: #fff;
    }
  }
  &:focus {
    background-color: #fff;
    color: $primary01;
    border-color: $primary01;
    span {
      color: $primary01;
    }
  }
  &.invert {
    background: $primary01;
    color: #fff;
    border-color: $primary01;
    span {
      color: #fff;
    }
    &:focus, &:active, &:hover {
      background: $primary01;
      color: #fff;
      border-color: $primary01;
      span {
        color: #fff;
      }
    }
  }
  &.small-btn {
    height: 32px;
    padding: 5px 10px 5px;
    span {
      line-height: 20px;
      font-size: 14px;
    }
  }
  &.smaller {
    height: 24px;
    padding: 5px 10px 5px;
    line-height: 20px;
    min-width: 0;
    font-size: 12px;
    margin-left: 10px;
    width: 118px;
    color: #fff !important;
    border-color: #0074d4 !important;
    background: #0074d4;
    span {
      color: #fff !important;
    }
    &:active, &:hover {
      background: #0074d4 !important;
      color: #fff !important;
      border-color: #0074d4 !important;
      span {
        color: #fff !important;
      }
    }
    &:focus {
      color: #fff !important;
      border-color: #0074d4 !important;
      background: #0074d4 !important;
      span {
        color: #fff !important;
      }
    }
  }
  &.ant-btn-default {
    &:focus, &:active, &:hover {
      background: transparent;
      color: $white !important;
      border-color: $primary01 !important;
      background: $primary01;
      span {
        color: $white !important;
      }
    }
    &.full {
      &:focus, &:active, &:hover {
        background: transparent !important;
        color: #0074d4 !important;
        border-color: #0074d4 !important;
        span {
          color: #0074d4 !important;
        }
      }
    }
  }
  &.transparent {
    background: transparent;
    color: $text-primary02;
    border-color: $border-primary01;
    border-width: 1px;
  }
  &.white {
    border-color: $white;
    color: $white;
  }
  &.purple {
    border-color: #9747FB;
    color: $white;
    background: #9747FB;
    &:hover {
      color: $white !important;
    }
  }
  &.orange-btn {
    border-color: $primary01;
    color: $white;
    background: $primary01;
    &:hover {
      background: $primary01;
      color: $white !important;
      span{
        color: $white!important;
      }
    }
    span{
      color: $white;
    }
  }
  &.black {
    border-color: $black;
    color: $black;
  }
  &.medium {
    min-width: 164px;
    height: 50px;
  }
  &.small {
    min-width: 90px;
    height: 40px;
    padding: 7px 5px;
    font-size: 14px;
    line-height: 20px;
  }
  &.text {
    min-width: 90px;
    height: 32px;
    padding: 7px 5px;
    font-weight: 600;
    background: transparent;
    border: none;
    box-shadow: none;
    color: $primary01;
  }
  &.icon {
    display: inline-flex;
    align-items: center;
    gap: 6px;
  }
  &:disabled {
    background: #f5f5f5;
    color: $primary01;
    cursor: not-allowed;
    border-color: $primary01;
    span {
      color: $primary01;
    }
  }
  &.full, &.blue {
    min-width: 100%;
    background-color: #0074d4;
    border-color: #0074d4;
    color: $white;
    span {
      color: $white;
    }
    &:focus, &:active, &:hover {
      background-color: #0074d4 !important;
      color: $white !important;
      border-color: #0074d4 !important;
      span {
        color: $white !important;
      }
    }
  }
  &.green {
    min-width: 100%;
    background-color: $green;
    border-color: $green;
    color: $white;
    span {
      color: $white;
    }
    &:focus, &:active, &:hover {
      background: transparent !important;
      color: $green !important;
      border-color: $green !important;
      span {
        color: $green !important;
      }
    }
  }
}
.slider-wrapper.active {
  .form-control {
    border-color: #32d583 !important;
  }
}
.google-btn {
  position: relative;
  justify-content: center;
  min-width: 169px;
  height: 57px;
  padding: 12px 30px 14px;
  border-radius: 100px;
  border: 2px solid $blue;
  text-decoration: none;
  background: $blue;
  transition: all .3s ease-in-out;
  font-size: 20px;
  line-height: 24px;
  font-family: $neue;
  color: $white;
  display: inline-flex;
  align-items: center;
  gap: 12px;
  padding-left: 10px;
  font-weight: 400;
  text-transform: capitalize;
  @media screen and (max-width: 991px) and (min-width: 768px) {
    min-width: 145px;
    height: 50px;
    padding: 11px 12px 12px;
    font-size: 17px;
  }
  @media screen and (max-width: $screen-xs) {
    min-width: 217px;
    height: 45px;
    padding: 10px 20px 12px 4px;
    font-size: 15px;
  }
  &:hover {
    background: rgb(62, 109, 218);
    color: $white;
  }
}
//      ****************************  Global Element  ****************************
ul {
  padding-left: 0;
  margin-bottom: 0;
  li {
    list-style: none;
    display: inline-block;
  }
}
.form {
  .form-group {
    margin-bottom: 16px;
    &.input-success {
      .icon {
        color: #32D583;
        font-size: 16px;
      }
      .form-control {
        color: $text-primary01;
        border: 1px solid #32D583;
      }
    }
    label {
      margin-bottom: 6px;
    }
    .form-control {
      height: 44px;
      background: $white;
      border: 1px solid $border-primary01;
      box-shadow: $box-shadow01;
      border-radius: 8px;
      color: $text-primary04;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      &#email {
        padding-right: 40px;
      }
      &::placeholder {
        color: $text-primary04;
      }
      &:focus {
        border-color: $text-primary04 !important;
        box-shadow: none !important;
      }
      &.red-border {
        border-color: red;
      }
    }
    textarea.form-control {
      height: 150px;
    }
    input[type="checkbox"] {
      border: 1px solid $border-primary01;
      border-radius: 4px;
    }
  }
}
.height-200 {
  min-height: 200px !important;
}
.cloud-brands-wrapper {
  .form-control {
    font-size: 14px;
  }
  .files-bucket-search-wrapper {
    .form-control {
      height: 38px;
    }
  }
  .form-control.search-icon {
    padding-left: 32px;
    background: url("../images/icons/search-gray-icon.svg") no-repeat;
    background-position: 10px center;
    background-size: 15px;
    &.right {
      padding-left: 12px;
      padding-right: 32px;
      background-position: right 10px center !important;
    }
  }
}
.form-control:focus {
  border-color: $text-primary04 !important;
  box-shadow: none !important;
}
.btn-style.max-width-large {
  max-width: 320px !important;
  min-width: 320px !important;
}
.ant-select {
  width: 100%;
  &.ant-select-focused {
    .ant-select-selector {
      border-color: $primary01 !important;
      box-shadow: 0 0 0 4px rgba(234, 73, 3, 0.12) !important;
    }
  }
  .ant-select-selector {
    min-height: 44px !important;
    height: auto !important;
    display: flex;
    align-items: center;
    &:hover {
      border-color: $border-primary01 !important;
    }
    input {
      background: $white;
      border: 0px solid $border-primary01;
      box-shadow: none;
      border-radius: 8px;
      color: $text-primary04;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      height: 44px !important;
    }
  }
}
.google-icon {
  background: linear-gradient(to bottom left, transparent 49%, #fbbc05 50%) 0 25%/48% 40%, linear-gradient(to top left, transparent 49%, #fbbc05 50%) 0 75%/48% 40%, linear-gradient(-40deg, transparent 53%, #ea4335 54%), linear-gradient(45deg, transparent 46%, #4285f4 48%), #34a853;
  background-repeat: no-repeat;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}
.styled-check {
  position: relative;
  input {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
  label {
    position: relative;
    padding-left: 25px;
    &:before {
      content: "";
      position: absolute;
      top: 1px;
      left: 0;
      width: 16px;
      height: 16px;
      background-color: #ffffff;
      border: 1px solid #D0D5DD;
      border-radius: 4px;
    }
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 6px;
      top: 3px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
  input:checked + label {
    &:before {
      background-color: $primary01;
      border: 1px solid $primary01;
    }
    &:after {
      display: block;
    }
  }
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner, .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner,
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner,
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  //background-color: $primary01;
  background-color: #000000;
  border-color: #000000;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner, .ant-checkbox-checked:after, .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
  border-color: #000000;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #000000;
  border-color: #000000;
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  // background-color: #000000;
  //border-color: #000000;
}
.radio-and-input {
  padding-top: 20px;
  .form-group {
    margin-top: 20px;
  }
}
.ant-tooltip-placement-topLeft, .ant-tooltip-placement-top, .ant-tooltip-placement-topRight {
  padding-bottom: 7px;
}
@mixin scrollbar() {
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $scroll-bar-color;
    border-radius: 16px;
    border: 0 solid transparent;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
}
@mixin scrollbarSmall() {
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $scroll-bar-color;
    border-radius: 16px;
    border: 0 solid transparent;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
}
:where(.css-dev-only-do-not-override-1i9hnpv).ant-notification {
  z-index: 10000;
}
.ant-switch:not(.make-default-switch) {
  height: 15px;
  min-width: 35px;
  &.ant-switch-checked {
    .ant-switch-handle {
      height: 11px;
      width: 11px;
      left: unset;
      right: 9px;
      &:before {
        height: 11px;
        width: 11px;
        left: unset;
        right: -6px;
      }
    }
  }
  .ant-switch-handle {
    height: 11px;
    width: 11px;
    left: -3px;
    &:before {
      height: 11px;
      width: 11px;
      left: unset;
      right: -6px;
    }
  }
  &.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: $blue !important;
  }
}
.form-control {
  font-size: 14px;
}
.form-control.small-fields {
  font-size: 12px;
  line-height: 18px;
  height: 34px;
}
.make-white-image {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(151deg) brightness(102%) contrast(101%) !important;
}
.position-relative {
  position: relative !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
/* HTML: <div class="loader"></div> */
.loader {
  margin-top: 35px;
  margin-bottom: 3px;
  width: 75%;
  height: 10px;
  background: #ddd;
  position: relative;
  &:before {
    content: "";
    height: 10px;
    width: 0;
    background: $primary01;
    position: absolute;
    left: 0;
    top: 0;
    animation: l1 40s infinite linear;
  }
}
.loading-text {
  font-size: 12px;
}
@keyframes l1 {
  100% {
    width: 100%
  }
}
.cancel-btn, .del-btn {
  margin-right: 15px;
}
.pl-0 {
  padding-left: 0 !important;
}
.float-right {
  float: right;
}
.bg-orange {
  background-color: $primary01;
}
.mb-32 {
  margin-bottom: 32px !important;
}
